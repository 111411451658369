@use '../../basic/variables' as v;

$componentSelector: mbs-wizard;

.#{$componentSelector} {
  &_body {
    padding: 0;
    .#{$componentSelector}_nav-tabs {
      padding: v.$wizard-nav-tabs-padding-y v.$wizard-nav-tabs-padding-x;
    }
  }
}

@import 'steps';
@import 'step';
