@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;
@use '../../basic/mixins/grid' as gr;

$componentSelector: mbs-accordion;

.#{$componentSelector} {
  display: block;

  .collapse {
    max-height: 100000vh;
    transition: v.$transition-collapse;

    //&:not(.show) {
    //  display: block;
    //  max-height: 0;
    //}
  }

  &.-without-border {
    .card {
      border: none !important;
    }

    .card-header {
      border: none !important;
    }
  }

  &.-narrow-header {
    .#{$componentSelector}_header > * {
      padding-top: 0;
      padding-bottom: 0;
    }

    .accordion > .card > .card-header {
      margin-bottom: 0;
    }
  }

  .card {
    border: v.$accordion-border-width solid v.$accordion-border-color;
    border-bottom: 0;
    @include br.border-radius(0);

    &-header {
      padding: 0;
      background: v.$accordion-header-bg;
    }

    &-body {
      @include m.padding(0);
    }

    &:first-of-type {
      @include br.border-top-radius($accordion-border-radius);
    }

    &:last-of-type {
      @include br.border-bottom-radius($accordion-border-radius);
      border-bottom: v.$accordion-border-width solid v.$accordion-border-color;
    }

    &.-expanded {
      overflow: unset;
    }
  }

  &_header {
    @include gr.make-row(0);
    flex-wrap: nowrap;

    > * {
      @include m.padding-y($accordion-header-padding-inside-y);
      flex-shrink: 0;

      &:disabled {
        cursor: v.$disabled-cursor;
      }
    }

    &-checkbox {
      flex-grow: 2;
      flex-shrink: 2;
      width: v.$accordion-header-checkbox-width;
      padding-right: v.$accordion-header-padding-inside-x;

      .mbs-checkbox {
        &_input {
          + .mbs-checkbox_label {
            &:before {
              top: v.$accordion-header-checkbox-position-top;
            }
          }
        }
      }

      .mbs-accordion_title {
        padding-left: v.$accordion-header-checkbox-title-padding-left;
      }
    }
  }

  &_toggler {
    &-btn {
      display: block;
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    &.ctrl {
      display: flex;
      position: relative;
      flex-shrink: 0;
      justify-content: center;
      width: v.$accordion-toggler-width;
      vertical-align: top;

      &:before {
        content: '';
        display: block;
        position: relative;
        z-index: 10;
        top: v.$accordion-toggler-position-top;
        width: 0;
        height: 0;

        border-top: v.$accordion-toggler-arrow-width-half solid v.$accordion-toggler-color;
        border-right: v.$accordion-toggler-arrow-height solid transparent;
        border-bottom: 0 solid transparent;
        border-left: v.$accordion-toggler-arrow-height solid transparent;
      }

      &.ctrl-primary {
        &:before {
          border-top-color: v.$accordion-toggler-color-primary;
        }
      }

      &.ctrl-primary:not(.-disabled) {
        &:hover {
          &:before {
            border-top-color: v.$accordion-toggler-color-primary-hover;
          }
        }
      }

      &.-collapsed {
        &:before {
          transform: rotate(-90deg);
        }
      }

      &.-disabled {
        opacity: v.$ctrl-disabled-opacity;
        cursor: v.$disabled-cursor;
      }

      &:not(.-disabled) {
        &:hover {
          &:before {
            border-top-color: v.$accordion-toggler-color-hover;
          }
        }
      }

      &[aria-expanded='true'] {
        &:before {
          border-top-width: 0;
          border-bottom-width: v.$accordion-toggler-arrow-height;
        }
      }

      &.-checkboxes {
        width: v.$accordion-header-checkbox-toggler-width;

        &.-sm {
          width: v.$accordion-header-checkbox-toggler-width-sm;
        }
      }
    }
  }

  &_ico {
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-self: stretch;
    justify-content: center;
    width: v.$accordion-ico-width;

    &.-align-left {
      justify-content: flex-start;
      width: 40px;
    }

    &-btn {
      display: block;
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &-img {
      position: relative;
      z-index: 10;
      top: v.$accordion-ico-position-top;
      color: v.$accordion-toggler-color;
      font-size: v.$accordion-ico-size;
      line-height: 1;

      > img {
        display: block;
        max-width: v.$accordion-ico-img-width;
      }
    }

    &:hover {
      .#{$componentSelector}_ico {
        &-img {
          color: v.$accordion-toggler-color-hover;
        }
      }
    }
  }

  &_title {
    display: block;
    flex-grow: 2;
    flex-shrink: 2;
    align-items: center;
    color: v.$accordion-title-color;
    font-size: v.$accordion-title-font-size;
    font-weight: v.$accordion-title-font-weight;
    line-height: v.$accordion-title-line-height;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;

    &-inner {
      display: flex;
    }

    &-ico {
      display: inline-flex;
      flex-shrink: 0;
      align-items: flex-start;
      justify-content: flex-start;
      width: v.$accordion-title-ico-width;
      font-size: v.$accordion-title-ico-size;
    }

    &:not(:disabled) {
      &:hover {
        color: v.$accordion-title-color-hover;
      }
    }
  }

  &_content {
    @include m.padding-y($accordion-content-padding-y);
    @include m.padding-x($accordion-content-padding-x);
    font-size: v.$accordion-content-font-size;

    &-list {
      margin-bottom: 0;
      padding: 0;
      font-size: v.$accordion-content-list-font-size;
      line-height: v.$accordion-content-list-line-height;
      list-style: none;

      &_item {
        @include m.textOverflow();
      }
    }
  }

  &_controls {
    padding-right: v.$accordion-controls-padding-right;
    padding-left: v.$accordion-controls-padding-left;
    @include m.padding-y($accordion-controls-padding-y);
    border: v.$accordion-border-width solid v.$accordion-border-color;
    @include br.border-top-radius($accordion-border-radius);
    background: v.$accordion-controls-bg;

    &-checkbox {
      .mbs-checkbox {
        &_input {
          + .mbs-checkbox_label {
            font-size: v.$accordion-controls-checkbox-label-font-size;

            &:before {
              top: v.$accordion-controls-checkbox-position-top;
            }
          }
        }
      }
    }

    &.-sm {
      padding-left: v.$accordion-controls-padding-left-sm;
    }
  }

  &_menu {
    display: flex;

    &-btn {
      position: relative;
      top: v.$accordion-menu-position-top;
    }
  }

  &.-showControls {
    .card {
      &:first-of-type {
        @include br.border-top-radius(0);
      }
    }
  }

  // Sizes
  &-sm {
    .#{$componentSelector} {
      &_header {
        > * {
          @include m.padding-y($accordion-header-padding-inside-y-sm);
        }

        &-checkbox {
          width: v.$accordion-header-checkbox-width-sm;

          .mbs-checkbox {
            &_input {
              + .mbs-checkbox_label {
                &:before {
                  top: v.$accordion-header-checkbox-position-top-sm;
                }
              }
            }
          }
        }
      }

      &_title {
        font-size: v.$accordion-title-font-size-sm;
        line-height: v.$accordion-title-line-height-sm;
      }

      &_toggler {
        &:before {
          top: v.$accordion-toggler-position-top-sm;
        }
      }

      &_ico {
        &-img {
          top: v.$accordion-ico-position-top-sm;
        }
      }

      &_menu {
        &-btn {
          top: v.$accordion-menu-position-top-sm;
        }
      }
    }

    .mbs-tree {
      &_item {
        &-label {
          padding-left: v.$accordion-header-checkbox-toggler-width-sm;
        }
      }
    }
  }

  // Simple
  &-simple {
    .card {
      border: none !important;

      &-header {
        border: none !important;
      }
    }

    .#{$componentSelector} {
      &_header {
        display: inline-flex;

        > * {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      &_title {
        &.ctrl-primary {
          color: v.$accordion-title-color-primary;
        }
      }

      &_content {
        padding-top: v.$accordion-content-padding-y-sm;
        padding-bottom: 0;
      }

      &_toggler {
        width: auto;
      }

      &_ico {
        width: v.$accordion-ico-width-sm;
        justify-content: start;

        &-img {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

#{$componentSelector} {
  display: block;
}

@import 'checkboxes';
