@use '../../basic/variables' as v;

.mbs-form_note {
  display: flex;
  align-items: baseline;
  line-height: v.$form-note-line-height;

  &-ico {
    font-size: v.$form-note-ico-size;
  }
}
