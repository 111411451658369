@use 'variables' as v;

%loader-placeholder {
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: 0.8s linear infinite loader;
  animation: 0.8s linear infinite loader;
  border-style: solid;
  border-radius: 50%;
  text-indent: -9999em;

  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

%loader {
  @extend %loader-placeholder;
  width: v.$loader-size;
  height: v.$loader-size;
  border-width: v.$loader-border-width;
  font-size: v.$loader-font-size;
}

%loader-xxs {
  @extend %loader-placeholder;
  width: v.$loader-size-xxs;
  height: v.$loader-size-xxs;
  border-width: v.$loader-border-width-xxs;
  font-size: v.$loader-font-size-xxs;
}

%loader-sm {
  @extend %loader-placeholder;
  width: v.$loader-size-sm;
  height: v.$loader-size-sm;
  border-width: v.$loader-border-width-sm;
  font-size: v.$loader-font-size-sm;
}

%loader-lg {
  @extend %loader-placeholder;
  width: v.$loader-size-lg;
  height: v.$loader-size-lg;
  border-width: v.$loader-border-width-lg;
  font-size: v.$loader-font-size-lg;
}

%disabled-element {
  cursor: v.$disabled-cursor;
  pointer-events: none;

  * {
    cursor: inherit;
    pointer-events: inherit;
  }
}
