@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

.mbs-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include m.margin-x(-1 * v.$breadcrumb-padding-x);

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: v.$breadcrumb-inner-height;
    @include m.padding-x(v.$breadcrumb-padding-x);
    font-size: v.$breadcrumb-font-size;

    &-divider {
      color: v.$breadcrumb-divider-color;
    }

    &.-active {
      color: v.$breadcrumb-active-color;
    }
  }
}
