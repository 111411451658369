@use '../../basic/variables' as v;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$stepper-border-width: v.$border-width;
$stepper-border-color: v.$border-color;

$stepper-indicator-bg: v.$white;

// Conditions
$stepper-indicator-active-border-color: v.$primary;
$stepper-indicator-active-bg: $stepper-indicator-bg;
$stepper-title-active-font-weight: v.$font-weight-semibold;

$stepper-indicator-completed-border-color: v.$success;
$stepper-indicator-completed-bg: v.$success;
$stepper-indicator-completed-icon: i.$ico-ok;
$stepper-indicator-completed-icon-color: v.$white;

$stepper-indicator-disabled-border-color: $stepper-border-color;
$stepper-indicator-disabled-bg: $stepper-indicator-bg;
$stepper-title-disabled-color: v.$disabled-color;

$step-conditions: 'active' $stepper-indicator-active-border-color $stepper-indicator-active-bg,
  'completed' $stepper-indicator-completed-border-color $stepper-indicator-completed-bg,
  'disabled' $stepper-indicator-disabled-border-color $stepper-indicator-disabled-bg;

// Sizes
$stepper-step-gap-sm: 5px;
$stepper-step-space-sm: 10px;
$stepper-indicator-size-sm: 18px;
$stepper-indicator-completed-icon-size-sm: 10px;
$stepper-title-font-size-sm: v.$font-size-sm;

$stepper-step-gap-lg: 4px;
$stepper-step-space-lg: 12px;
$stepper-indicator-size-lg: 22px;
$stepper-indicator-completed-icon-size-lg: 13px;
$stepper-title-font-size-lg: v.$font-size-md;

$stepper-sizes: 'sm' $stepper-step-gap-sm $stepper-step-space-sm $stepper-indicator-size-sm $stepper-indicator-completed-icon-size-sm
    $stepper-title-font-size-sm,
  'lg' $stepper-step-gap-lg $stepper-step-space-lg $stepper-indicator-size-lg $stepper-indicator-completed-icon-size-lg
    $stepper-title-font-size-lg;
