@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

.list-group {
  font-size: v.$list-group-font-size;

  &-item {
    border-color: v.$list-group-border-color;
    @include m.padding-y(v.$list-group-padding-y);

    &:first-child {
      @include br.border-top-radius(v.$list-group-border-radius);
    }

    &:last-child {
      @include br.border-bottom-radius(v.$list-group-border-radius);
    }
  }
}
