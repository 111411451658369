@use 'variables' as v;

@each $color, $value in v.$colors {
  .text-#{$color} {
    color: $value !important;
  }
}

@each $gray, $value in v.$grays {
  .text-gray-#{$gray} {
    color: $value !important;
  }
}
