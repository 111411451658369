@use '../../../basic/variables' as v;

$componentSelector: mbs-radio;

#{$componentSelector} {
  @extend %check-radio;

  .#{$componentSelector} {
    @extend %check-radio-container;

    &_input {
      @extend %check-radio-input-hidden;

      + .#{$componentSelector}_label {
        @extend %check-radio-label;

        &:before {
          @extend %check-radio-input;
          @extend %radio-input;
        }
      }

      &:disabled {
        + .#{$componentSelector}_label {
          &:before {
            @extend %radio-input-disabled;
          }
        }
      }

      &:checked {
        + .#{$componentSelector}_label {
          &:before {
            @extend %radio-input-accented;
          }
        }

        &:disabled {
          + .#{$componentSelector}_label {
            &:before {
              @extend %radio-input-accented-disabled;
            }
          }
        }
      }

      &:focus {
        + .#{$componentSelector}_label {
          &:before {
            box-shadow: v.$check-radio-focus-box-shadow;
          }
        }
      }
    }

    &_label {
      &-content {
        @extend %check-radio-label-content;
      }
    }

    &_subtitle {
      @extend %check-radio-subtitle-description;
      @extend %check-radio-subtitle;
    }

    &_description {
      @extend %check-radio-subtitle-description;
    }
  }
}

@mixin radio-sizes($size, $border-width) {
  .#{$componentSelector}_input {
    &:checked {
      + .#{$componentSelector}_label {
        &:before {
          border-width: $border-width;
        }
      }
    }
  }
}

// Sizes
@each $size, $input-size, $min-height, $gap, $offset-top in v.$check-radio-sizes {
  .#{$componentSelector}.-#{$size} {
    min-height: $min-height;

    .#{$componentSelector} {
      &_input {
        + .#{$componentSelector}_label {
          gap: $gap;
          @include check-radio-sizes($size, $input-size, $min-height, $gap, $offset-top);
        }
      }

      &_label {
        &-content {
          @extend %check-radio-label-content-sizes;
        }
      }
    }
  }
}
@each $size, $border-width in v.$radio-sizes {
  .#{$componentSelector}.-#{$size} {
    @include radio-sizes($size, $border-width);
  }
}
