@use '../../../basic/variables' as v;
@use '../../../basic/mixins' as m;
@use '../../../basic/mixins/border-radius' as br;

@use 'variables' as cv;

.mbs-select_ng-select {
  .counter{
    min-width: 19.5px;
  }
}

.mbs-select_ng-select.auto-width {
  .ng-dropdown-panel {
    width: fit-content;
    min-width: 100%;
  }
}

.mbs-select_ng-select.checkbox {
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        &.ng-option-marked {
          background-color: inherit !important;
          cursor: auto !important;
        }

        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          background-color: inherit !important;
        }
      }
    }
  }
}

.ng-dropdown-panel.mbs-select_ng-select,
.mbs-select .ng-dropdown-panel {
  left: 0;
  border: cv.$select-dropdown-border-width solid cv.$select-dropdown-border-color;
  background-color: cv.$select-dropdown-bg;
  box-shadow: cv.$select-dropdown-box-shadow;

  .ng-dropdown-header {
    padding: cv.$select-dropdown-item-padding-y cv.$select-dropdown-item-padding-x;
    border-bottom: cv.$select-dropdown-border-width solid cv.$select-border-color;
  }

  .ng-dropdown-footer {
    padding: cv.$select-dropdown-item-padding-y cv.$select-dropdown-item-padding-x;
    border-top: cv.$select-dropdown-border-width solid cv.$select-dropdown-border-color;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      padding: cv.$select-dropdown-item-padding-y cv.$select-dropdown-item-padding-x;
      font-weight: cv.$select-dropdown-item-optgroup-font-weight;
      cursor: pointer;
      user-select: none;

      &.ng-option-disabled {
        @extend %disabled-element;
      }

      &.ng-option-marked {
        background-color: cv.$select-dropdown-item-marked-bg;
        color: cv.$select-dropdown-item-marked-color;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: cv.$select-dropdown-item-selected-bg;
        color: cv.$select-dropdown-item-selected-color;
        font-weight: cv.$select-dropdown-item-selected-font-weight;
      }
    }

    .ng-option {
      padding: cv.$select-dropdown-item-padding-y cv.$select-dropdown-item-padding-x;
      background-color: cv.$select-dropdown-bg;
      color: cv.$select-dropdown-item-color;
      font-size: cv.$select-dropdown-item-font-size;
      line-height: cv.$select-dropdown-item-line-height;

      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: cv.$select-dropdown-item-selected-bg;
        color: cv.$select-dropdown-item-selected-color;

        .ng-option-label {
          font-weight: cv.$select-dropdown-item-selected-font-weight;
        }
      }

      &.ng-option-marked {
        background-color: cv.$select-dropdown-item-marked-bg;
        color: cv.$select-dropdown-item-marked-color;
      }

      &.ng-option-disabled {
        color: cv.$select-dropdown-item-disabled-color;
      }

      &.ng-option-child {
        padding-left: 22px;
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    margin-bottom: -1 * cv.$select-dropdown-border-width;
    @include br.border-top-radius(cv.$select-dropdown-border-radius);
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          @include br.border-top-radius(cv.$select-dropdown-border-radius);
        }
      }
    }
  }

  &.ng-select-right {
    top: 0;
    left: 100%;
    margin-bottom: -1 * cv.$select-dropdown-border-width;
    @include br.border-right-radius(cv.$select-dropdown-border-radius);
    @include br.border-bottom-radius(cv.$select-dropdown-border-radius);
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: cv.$select-dropdown-border-radius;
        }
      }
    }
  }

  &.ng-select-bottom {
    top: 100%;
    margin-top: -1 * cv.$select-dropdown-border-width;
    @include br.border-bottom-radius(cv.$select-dropdown-border-radius);
    .ng-option {
      &:last-child {
        @include br.border-bottom-radius(cv.$select-dropdown-border-radius);
      }
    }
  }

  &.ng-select-left {
    top: 0;
    left: -100%;
    margin-bottom: -1 * cv.$select-dropdown-border-width;
    @include br.border-bottom-radius(cv.$select-dropdown-border-radius);
    @include br.border-left-radius(cv.$select-dropdown-border-radius);
    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-left-radius: cv.$select-dropdown-border-radius;
        }
      }
    }
  }

  // Sizes
  @each $size, $padding-y, $padding-x, $font-size in cv.$select-dropdown-sizes {
    &.ng-select-#{$size} {
      .ng-dropdown-panel-items {
        .ng-option {
          @include m.padding-y($padding-y);
          @include m.padding-x($padding-x);
          font-size: $font-size;
        }
      }
    }
  }
}

.mbs-select-checkbox-one-line-opened {
  .ng-dropdown-panel.mbs-select_ng-select,
  .mbs-select .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        &.ng-option-child {
          padding-left: 10px;
        }
      }
    }
  }
}
