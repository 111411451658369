@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

$componentSelector: mbs-tabset;

.#{$componentSelector} {
  @extend .list-unstyled;
  display: flex;
  align-items: stretch;
  margin: 0;
  background: v.$tabset-bg;

  &_content {
    overscroll-behavior: contain;
  }

  &_item {
    border: v.$tabset-border-width solid transparent;

    &:not(.-disabled) {
      color: v.$tabset-link-color;
      cursor: pointer;

      &:hover {
        color: v.$tabset-link-active-color;
      }
    }

    &.-disabled {
      color: v.$tabset-link-disabled-color;
      cursor: v.$disabled-cursor;
    }

    &.-active {
      background: v.$tabset-item-active-bg;
      color: v.$tabset-link-active-color;
    }
  }

  &_link {
    display: block;
    width: 100%;
    padding: v.$tabset-link-padding-y v.$tabset-link-padding-x;
    border: 0;
    background: none;
    color: inherit;
    font-size: v.$tabset-link-font-size;
    font-weight: v.$tabset-link-font-weight;
    line-height: v.$tabset-link-line-height;
    text-align: left;

    &-ico {
      font-size: v.$tabset-link-ico-font-size;
      font-weight: v.$tabset-link-ico-font-weight;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    &:not(:disabled) {
      cursor: inherit;
    }
  }

  // Horizontal tabs
  &:not(.-vertical) {
    flex-direction: row;
    padding: v.$tabset-horizontal-padding-top v.$tabset-horizontal-padding-x 0;
    border-bottom: v.$tabset-border-width solid v.$tabset-border-color;

    .#{$componentSelector} {
      &_item {
        margin-bottom: -1 * v.$tabset-border-width;
        @include br.border-top-radius(v.$tabset-border-radius);

        &.-active {
          border-top-color: v.$tabset-border-color;
          border-right-color: v.$tabset-border-color;
          border-left-color: v.$tabset-border-color;
        }

        &:first-child:not(.-bordered) {
          border-left: 0;
          @include br.border-left-radius(0);
        }
      }
    }

    // Inline tabset
    &.-inline {
      .#{$componentSelector} {
        &_item {
          &:last-child {
            border-right: 0;
            @include br.border-right-radius(0);
          }
        }
      }
    }
  }

  // Vertical tabs
  &.-vertical {
    flex-direction: column;
    padding: v.$tabset-vertical-padding-y 0 v.$tabset-vertical-padding-y v.$tabset-vertical-padding-left;
    border-right: v.$tabset-border-width solid v.$tabset-border-color;

    .#{$componentSelector} {
      &_item {
        margin-right: -1 * v.$tabset-border-width;
        @include br.border-left-radius(v.$tabset-border-radius);

        &.-active {
          border-top-color: v.$tabset-border-color;
          border-bottom-color: v.$tabset-border-color;
          border-left-color: v.$tabset-border-color;
        }
      }

      &_group {
        @extend .list-unstyled;
        margin: 0;
        padding: v.$tabset-vertical-group-padding-top v.$tabset-vertical-group-padding-x v.$tabset-vertical-group-padding-bottom;

        &-item {
          position: relative;
          color: v.$tabset-vertical-group-link-color;

          &:before {
            content: '';
            position: absolute;
            z-index: 10;
            top: v.$tabset-vertical-group-link-marker-position-top;
            left: 0;
            width: v.$tabset-vertical-group-link-marker-size;
            height: v.$tabset-vertical-group-link-marker-size;
            border-radius: 50%;
            background: v.$tabset-vertical-group-link-marker-color;
          }

          &.-disabled {
            color: v.$tabset-vertical-group-link-disabled-color;
            cursor: v.$disabled-cursor;

            &:before {
              background: v.$tabset-vertical-group-link-disabled-marker-color;
            }
          }

          &.-active,
          &:not(.-disabled):hover {
            color: v.$tabset-vertical-group-link-active-color;
            cursor: pointer;

            &:before {
              background: v.$tabset-vertical-group-link-active-marker-color;
            }
          }
        }

        &-link {
          display: block;
          position: relative;
          z-index: 20;
          width: 100%;
          padding: v.$tabset-vertical-group-link-padding-y 0 v.$tabset-vertical-group-link-padding-y
            v.$tabset-vertical-group-link-padding-left;
          border: 0;
          background: none;
          color: inherit;
          font-size: v.$tabset-vertical-group-link-font-size;
          line-height: v.$tabset-vertical-group-link-line-height;
          text-align: left;

          &:hover,
          &:focus,
          &:active {
            outline: none;
          }

          &:not(:disabled) {
            cursor: inherit;
          }
        }
      }
    }
  }
}
