@use '../../basic/variables' as v;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-checkbox;

// File mode for tree
.mbs-tree.-file {
  .mbs-tree-item_checkbox {
    // For file tree
    // .-indeterminate: children are checked, parent is not checked
    // .-indeterminate.-checked: not all chldren are checked, parent is checked
    // .-checked: all children are checked, parent is checked
    // no additional classes: children are not checked, parent is not checked
    &.-indeterminate {
      .#{$componentSelector} {
        .#{$componentSelector}_input {
          + .#{$componentSelector}_label {
            &:before {
              content: '';
              border-color: v.$check-radio-accent-color-primary;
              background: v.$tree-file-checkbox-indeterminate-bg;
              font-size: v.$tree-file-checkbox-indeterminate-check-size;
            }
          }
        }
      }

      &.-checked {
        .#{$componentSelector} {
          .#{$componentSelector}_input {
            + .#{$componentSelector}_label {
              &:before {
                content: i.$ico-ok;
                background: v.$tree-file-checkbox-indeterminate-checked-bg;
                color: v.$tree-file-checkbox-indeterminate-checked-color;
                font-size: v.$tree-file-checkbox-indeterminate-check-size;
              }
            }
          }
        }
      }
    }
  }
}
