@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

.#{$componentSelector} {
  &_table {
    margin-bottom: 0;

    .mbs-table-grid {
      &_row {
        @include m.padding-x(calc(#{v.$list-select-table-row-padding-x} + #{v.$list-select-table-border-width}));

        &:first-child {
          @include br.border-top-radius(v.$list-select-table-border-radius);
        }

        &:last-child {
          border-bottom-color: transparent;
          @include br.border-bottom-radius(v.$list-select-table-border-radius);
        }
      }

      &_cell {
        @include m.padding-y(v.$list-select-table-cell-padding-y);
        @include m.padding-x(v.$list-select-table-cell-padding-x);
      }
    }

    &-wrapper {
      border-top: v.$list-select-table-border-width solid v.$list-select-table-border-color;
      border-bottom: v.$list-select-table-border-width solid v.$list-select-table-border-color;

      &.-maxHeight {
        border-right: v.$list-select-table-border-width solid v.$list-select-table-border-color;
        border-bottom: v.$list-select-table-border-width solid v.$list-select-table-border-color;

        .mbs-table-grid {
          &_row {
            padding-right: calc(#{v.$list-select-table-border-width} + #{v.$list-select-table-row-padding-x});
            border-right: v.$list-select-table-border-width solid v.$list-select-table-border-color;
          }
        }
      }

      &:not(.-maxHeight) {
        border-right-width: 0;

        .mbs-table-grid {
          &_row {
            padding-right: v.$list-select-table-row-padding-x;
            border-right: v.$list-select-table-border-width solid v.$list-select-table-border-color;
          }
        }

        &.-invalid {
          .mbs-table-grid {
            &_row {
              border-right-width: 0;
            }
          }
        }
      }

      &.-invalid {
        border-right: v.$list-select-table-border-width solid v.$list-select-table-invalid-border-color;
        border-left: v.$list-select-table-border-width solid v.$list-select-table-invalid-border-color;
        border-top-color: v.$list-select-table-invalid-border-color;
        border-bottom-color: v.$list-select-table-invalid-border-color;

        .mbs-table-grid {
          &_row {
            &:last-child {
              border-bottom-color: transparent;
            }
          }
        }
      }

      &:not(.-invalid) {
        border-top-color: transparent;

        .mbs-table-grid {
          &_row {
            border-left: v.$list-select-table-border-width solid v.$list-select-table-border-color;

            &:first-child {
              border-top: v.$list-select-list-border-width solid v.$list-select-table-border-color;
            }

            &:last-child {
              border-bottom: v.$list-select-list-border-width solid v.$list-select-table-border-color;
            }

            &.-selected {
              padding-right: v.$list-select-table-row-padding-x;
              border-right: v.$list-select-table-border-width solid v.$list-select-table-row-selected-border-color;
              border-left-color: v.$list-select-table-row-selected-bg-color;

              @each $color, $value in $theme-colors {
                &.-#{$color} {
                  border-right-color: mix($value, $white, $table-grid-row-selected-border-color-proportion);
                  border-left-color: mix($value, $white, $table-grid-row-selected-border-color-proportion);
                }
              }
            }
          }
        }

        &:not(.-maxHeight) {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
