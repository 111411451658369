@use '../../../basic/mixins' as m;
@use '../../../basic/mixins/border-radius' as br;
@use '../../../basic/mixins/box-shadow' as box-shadow;
@use '../../../basic/mixins/transition' as transition;
@use '../../../basic/mixins/forms' as forms;
@use '../../../basic/variables' as v;

.form-control {
  display: block;
  width: 100%;
  height: v.$input-height;
  padding: v.$input-padding-y v.$input-padding-x;
  border: v.$input-border-width solid v.$input-border-color;
  background-clip: padding-box;
  background-color: v.$input-bg;
  color: v.$input-color;
  font-family: v.$input-font-family;
  font-size: v.$input-font-size;
  font-weight: v.$input-font-weight;
  line-height: v.$input-line-height;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include br.border-radius(v.$input-border-radius, 0);

  @include box-shadow.box-shadow(v.$input-box-shadow);
  @include transition.transition(v.$input-transition);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include forms.form-control-focus();

  // Placeholder
  &::placeholder {
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
    color: v.$input-placeholder-color;
  }

  // Read only inputs lokks not like disabled ones. To implement read only status to disabled input (or disabled status to read on;y inputs) must use both attributes
  &[readonly]:not(:disabled) {
    border: v.$input-border-width solid v.$input-border-color;
    background-color: v.$input-disabled-bg;
    color: v.$input-color;

    @include forms.form-control-focus(false, v.$input-disabled-bg);
  }

  // Disabled inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &.disabled {
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    @extend %disabled-element;
    opacity: 1;
    background-color: v.$input-disabled-bg;
    color: v.$input-disabled-color;
  }

  // Remove webkit autofill default style
  &:-webkit-autofill,
  &:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s !important;
    background-color: v.$input-bg !important;
    box-shadow: inset 0 0 0 v.$input-height-lg v.$input-bg;

    &:hover,
    &:focus,
    &:active {
      transition: background-color 5000s ease-in-out 0s !important;
      background-color: v.$input-bg !important;
      box-shadow: inset 0 0 0 v.$input-height-lg v.$input-bg;
    }
  }

  &-plaintext {
    &:not(:focus),
    &:not(.focus) {
      box-shadow: none;
    }
  }

  &-placeholder {
    @include m.textOverflow();
    padding: inherit;
    color: v.$input-placeholder-color;
    pointer-events: none;
  }

  // Clearable form-control
  &.-clearable {
    padding-right: v.$input-height;
  }

  &_clear {
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    width: v.$input-height;
    height: v.$input-height;

    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  // Loading form-control
  &_loader {
    display: flex;
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: v.$input-height;
    height: v.$input-height;
  }
}

@each $state, $data in v.$form-validation-states {
  @include forms.form-validation-state($state, map-get($data, color), map-get($data, icon));
}

@import 'sizes';
@import 'textarea';
