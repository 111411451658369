@use '../../basic/variables' as v;
@use '../../basic/functions' as f;

%tooltipTop {
  .arrow {
    transform: rotate(45deg);
  }
}

%tooltipRight {
  .arrow {
    transform: rotate(135deg);
  }
}

%tooltipBottom {
  .arrow {
    transform: rotate(-135deg);
  }
}

%tooltipLeft {
  .arrow {
    transform: rotate(-45deg);
  }
}

.tooltip-z-as-modal {
  z-index: calc(v.$zindex-modal - 1) !important;
}

.tooltip-z-as-fixed {
  z-index: calc(v.$zindex-fixed - 1) !important;
}

.tooltip {
  @extend %tooltipTop;

  &-inner {
    border: v.$tooltip-border-width solid v.$tooltip-border-color;
    box-shadow: v.$tooltip-box-shadow;
  }

  // Replace component styles to have bordered arrow
  .arrow {
    width: v.$tooltip-arrow-width;
    height: v.$tooltip-arrow-width;
    border-right: v.$tooltip-border-width solid v.$tooltip-border-color;
    border-bottom: v.$tooltip-border-width solid v.$tooltip-border-color;
    background: v.$tooltip-bg;

    &:after,
    &:before {
      display: none;
    }
  }

  // Tooltip positioning
  &.bs-tooltip-auto {
    &[x-placement^='right'] {
      @extend %tooltipRight;
    }
    &[x-placement^='bottom'] {
      @extend %tooltipBottom;
    }
    &[x-placement^='left'] {
      @extend %tooltipLeft;
    }
  }

  &.bs-tooltip-right {
    @extend %tooltipRight;
  }

  &.bs-tooltip-top-right, &.bs-tooltip-bottom-right {
    &.tooltip-shift:not(.-no-arrow) {
      margin-left: v.$tooltip-arrow-width - v.$tooltip-border-width * 2;
    }
  }

  &.bs-tooltip-bottom {
    @extend %tooltipBottom;
  }

  &.bs-tooltip-left {
    @extend %tooltipLeft;
  }

  &.bs-tooltip-top-left, &.bs-tooltip-bottom-left {
    &.tooltip-shift:not(.-no-arrow) {
      margin-left: -(v.$tooltip-arrow-width - v.$tooltip-border-width * 2);
    }
  }

  // Tooltip sizing
  &.tooltip-lg {
    .tooltip-inner {
      max-width: v.$tooltip-max-width-lg;
    }
  }
  &.tooltip-xl {
    .tooltip-inner {
      max-width: v.$tooltip-max-width-xl;
      padding: v.$tooltip-padding-y-xl v.$tooltip-padding-x-xl;
    }
  }
  &.tooltip-xl-wide {
    width: v.$tooltip-max-width-xl;
  }


  &.text-left {
    .tooltip-inner {
      text-align: left;
    }
  }

  &.text-right {
    .tooltip-inner {
      text-align: right;
    }
  }

  // Tooltip without arrow
  &.-no-arrow {
    .arrow {
      display: none;
    }
  }

  &-btn {
    display: inline-flex;
    margin-left: v.$tooltip-btn-margin-left;
    color: v.$tooltip-btn-color;
    font-size: v.$tooltip-btn-size;
    vertical-align: middle;
    cursor: help;
  }

  &_text {
    &-lg {
      font-size: v.$tooltip-font-size-lg;
    }
  }
}

@mixin tooltip-variant($color, $background-color: $color) {
  .tooltip-inner {
    border-color: $background-color;
    background-color: $background-color;
    color: f.color-contrast($background-color);
  }

  .arrow {
    border-right-color: $background-color;
    border-bottom-color: $background-color;
    background-color: $background-color;
  }
}

// Outline tooltips
@mixin tooltip-outline-variant($color, $border-color: darken($color, v.$tooltip-border-darken), $background-color: $tooltip-bg) {
  .tooltip-inner {
    border-color: $border-color;
    background-color: $background-color;
    color: f.color-contrast($background-color);
  }

  .arrow {
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    background-color: $background-color;
  }
}

@each $color, $value in v.$theme-colors {
  .tooltip-#{$color} {
    @include tooltip-variant($value);
  }
}

@each $color, $value in v.$theme-colors {
  .tooltip-outline-#{$color} {
    @include tooltip-outline-variant($value);
  }
}
