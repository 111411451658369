@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

$componentSelector: mbs-card;

#{$componentSelector} {
  display: block;
  height: 100%;
}

.#{$componentSelector} {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  border: v.$card-border-width solid v.$card-border-color;
  border-radius: v.$card-border-radius;
  background: v.$card-bg;

  &_header {
    display: flex;
    align-items: center;
    margin-bottom: v.$card-header-margin-bottom;
  }

  &_ico {
    margin-right: v.$card-ico-margin-right;
    font-size: v.$card-ico-size;
  }

  &_title {
    @extend h4;
    margin-top: 0;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  &_content {
    padding: v.$card-content-padding-y v.$card-content-padding-x;
  }

  &_group {
    display: block;

    &-header {
      display: flex;
      align-items: center;
      margin-bottom: v.$card-group-header-margin-bottom;
    }

    &-title {
      margin-bottom: 0;
    }

    & + & {
      padding-top: v.$card-group-padding-y;
      border-top: v.$card-border-width solid v.$card-border-color;
    }

    &:not(:last-child) {
      padding-bottom: v.$card-group-padding-y;
    }
  }

  // Not designed yet
  &_footer {
    padding: v.$card-content-padding-y v.$card-content-padding-x;
    border-top: v.$card-border-width solid v.$card-border-color;
  }

  // Card sizes
  &.-sm {
    .#{$componentSelector} {
      &_content {
        padding: v.$card-content-padding-y-sm v.$card-content-padding-x-sm;
      }
      &_header {
        margin-bottom: v.$card-header-margin-bottom-sm;
      }
      &_title {
        @extend h5;
        margin-bottom: 0;
      }
      &_footer {
        padding: v.$card-content-padding-y-sm v.$card-content-padding-x-sm;
      }
    }
  }
  &.-xs {
    .#{$componentSelector} {
      &_content {
        padding: v.$card-content-padding-y-sm v.$card-content-padding-x-xs;
      }
      &_header {
        margin-bottom: v.$card-header-margin-bottom-sm;
      }
      &_title {
        @extend h5;
        margin-bottom: 0;
      }
    }
  }

  // Card without padding
  &.-no-padding {
    .#{$componentSelector} {
      &_content {
        padding: 0;
      }
      &_header {
        margin-bottom: 0;
      }
      &_footer {
        padding: 0;
      }
    }

  }

  // Card without border
  &.-no-border {
    border: 0;

    .#{$componentSelector} {
      &_content {
        padding-top: 0;
        @include m.padding-x(0);
      }
    }
  }

  // Card without padding but with border
  &.-no-padding {
    .#{$componentSelector} {
      &_content {
        padding-top: 0;
        @include m.padding-x(0);
      }
    }
  }

  &.-overflow-y-auto {
    overflow-y: auto;
  }

  &.inner-bordered {
    .#{$componentSelector} {
      &_content {
        padding: 0;
      }

      &_header {
        margin-bottom: 0;
      }

      &_groups {
        display: flex;
      }

      &_group {
        display: block;
        border: none;
        padding: 0;

        &-header {
          display: flex;
          align-items: center;
          margin-bottom: v.$card-group-header-margin-bottom;
        }

        &-title {
          margin-bottom: 0;
        }

        & + & {
          padding: v.$card-group-padding-y;
          border-top: none;
        }

        &:not(:last-child) {
          padding-bottom: 0;
          border-right: v.$card-border-width solid v.$card-border-color;
        }
      }
    }

    &.-sm {
      .#{$componentSelector} {
        &_header {
          padding: v.$card-content-padding-y-sm v.$card-content-padding-x-sm;
          border-bottom: v.$card-border-width solid v.$card-border-color;
        }

        &_group {
          padding: v.$card-content-padding-y-sm v.$card-content-padding-x-sm;
        }
      }
    }

    &.-xs {
      .#{$componentSelector} {
        &_header {
          padding: v.$card-content-padding-y-xs v.$card-content-padding-x-xs;
          border-bottom: v.$card-border-width solid v.$card-border-color;
        }

        &_group {
          padding: v.$card-content-padding-y-xs v.$card-content-padding-x-xs;
        }
      }
    }
  }
}
