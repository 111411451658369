@use 'sass:math';
@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

@mixin button-size($height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius) {
  height: $height;
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  gap: $gap;
  @include br.border-radius($border-radius, 0);

  .btn-ico {
    font-size: math.ceil($font-size * v.$btn-ico-size);
  }

  &.btn-rounded {
    border-radius: $height / 2;
  }

  &.btn-square {
    width: $height;
  }
}
