@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use 'sass:map';

$componentSelector: mbs-sidepanel;

.#{$componentSelector} {
  display: flex;
  position: fixed;
  z-index: v.$sidepanel-zindex;
  top: v.$header-height;
  bottom: 0;
  flex-direction: column;
  width: v.$sidepanel-width;
  overflow: hidden;
  background: v.$sidepanel-bg;

  .mbs-form {
    > form {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      overflow: hidden;
    }
  }

  &_tabset {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    overflow: hidden;

    .mbs-tabset {
      &_content {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        overflow-y: auto;
      }

      &:not(.-vertical) {
        background: v.$sidepanel-header-bg;
      }
    }

    &.-vertical {
      flex-direction: row;
      overflow-y: auto;
      background: linear-gradient(
        to right,
        v.$tabset-bg 0,
        v.$tabset-bg v.$sidepanel-vertical-tabset-width,
        v.$tabset-border-color v.$sidepanel-vertical-tabset-width,
        v.$tabset-border-color (v.$sidepanel-vertical-tabset-width + v.$tabset-border-width),
        v.$sidepanel-bg (v.$sidepanel-vertical-tabset-width + v.$tabset-border-width),
        v.$sidepanel-bg 100%
      );

      .#{$componentSelector}_tabset {
        &-tabs {
          &_scrolling {
            display: block;
            flex-shrink: 0;
            width: calc(#{v.$sidepanel-vertical-tabset-width} + #{v.$tabset-border-width});

            .ng-scroll {
              &-content {
                height: 100%;
              }
            }
          }
        }

        &-content {
          &_scrolling {
            display: block;
            flex-shrink: 0;
            width: calc(100% - #{v.$sidepanel-vertical-tabs-width} - #{v.$tabset-border-width});
          }
        }
      }

      .mbs-tabset {
        flex-shrink: 0;
        width: v.$sidepanel-vertical-tabset-width;
        border-right: 0;
        background: none;

        &_content {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
        }
      }
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;

    &-scrolling {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      overflow-y: auto;
    }

    &-header {
      padding: v.$sidepanel-content-header-pdy v.$sidepanel-content-header-pdx;
      border-bottom: v.$sidepanel-content-header-border-width solid v.$sidepanel-content-header-border-color;
    }

    &-title {
      margin: 0;
      padding: 0;
      font-size: v.$sidepanel-content-title-font-size;
      font-weight: v.$sidepanel-content-title-font-weight;
    }

    // innerContentType="inner"
    &-inner {
      position: relative;
      padding: v.$sidepanel-content-inner-padding-y v.$sidepanel-content-inner-padding-x;
    }
    &-loader {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &_header {
    position: relative;
    flex-shrink: 0;
    padding: v.$sidepanel-header-padding-y v.$sidepanel-header-padding-x;
    border-bottom: v.$sidepanel-border-width solid v.$sidepanel-border-color;
    background: v.$sidepanel-header-bg;
    color: v.$sidepanel-header-color;

    &-description {
      font-size: v.$sidepanel-header-description-font-size;
    }

    &-row {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      overflow: hidden;
    }

    &-controls {
      flex-shrink: 0;
    }

    &.-light {
      background: v.$sidepanel-header-bg-light;
    }
  }

  &_title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: v.$sidepanel-title-color;
    font-size: v.$sidepanel-title-font-size;

    &-name {
      display: block;
      flex-shrink: 2;
      max-width: 100%;
      overflow: hidden;
      font-weight: v.$sidepanel-title-font-weight;
      letter-spacing: v.$sidepanel-title-letter-spacing;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-ico {
      padding-right: v.$sidepanel-title-padding-right;
      color: v.$sidepanel-title-ico-default-color;
      font-size: v.$sidepanel-title-ico-size;
      line-height: 1;
    }
  }

  &_footer {
    @include m.padding-y(v.$sidepanel-footer-padding-y);
    @include m.padding-x(v.$sidepanel-footer-padding-x);
    border-top: v.$sidepanel-border-width solid v.$sidepanel-border-color;
    background: v.$sidepanel-footer-bg;

    &-row {
      display: flex;
      align-items: center;

      &.-end {
        justify-content: flex-end;
      }

      &.-start {
        justify-content: flex-start;
      }

      &.-center {
        justify-content: center;
      }

      > .d-flex {
        @include m.margin-x(-1 * v.$sidepanel-footer-col-padding-x);
      }
    }

    &-col {
      @extend .col-auto;
      @include m.padding-x(v.$sidepanel-footer-col-padding-x);
    }
  }

  &_section {
    &-header {
      margin-bottom: v.$sidepanel-secton-header-margin-bottom;

      &_ico {
        padding-top: v.$sidepanel-section-header-ico-padding-top;
        font-size: v.$sidepanel-section-header-ico-size;
        line-height: 1;

        .loader {
          position: relative;
          top: v.$sidepanel-section-header-loader-position-top;
        }
      }
    }

    &-title {
      @include m.margin-y(v.$sidepanel-section-title-margin-y);
    }

    &-block {
      p {
        margin-bottom: v.$sidepanel-section-block-p-margin-bottom;
      }

      &_header {
        margin-bottom: v.$sidepanel-section-block-header-margin-bottom;
      }

      &_title {
        @include m.margin-y(v.$sidepanel-section-block-title-margin-y);
        font-weight: v.$sidepanel-section-block-title-font-weight;
        line-height: v.$sidepanel-section-block-title-line-height;
      }

      & + & {
        margin-top: v.$sidepanel-section-block-margin-top;
      }
    }

    &-list {
      @include m.margin(0);
      @include m.padding(0);
      line-height: v.$sidepanel-section-list-line-height;
      list-style: none;

      &_item {
        line-height: inherit;

        & + & {
          margin-top: v.$sidepanel-section-list-item-margin-top;
        }
      }

      &_link {
        display: inline-flex;
        align-items: center;
      }

      &_ico {
        width: v.$sidepanel-section-list-ico-width;
        font-size: v.$sidepanel-section-list-ico-size;
      }
    }

    & + & {
      margin-top: v.$sidepanel-secton-margin-top;

      &.-bordered {
        padding-top: v.$sidepanel-secton-bordered-padding-top;
        border-top: v.$sidepanel-border-width solid v.$sidepanel-border-color;
      }
    }
  }

  // Sizing and positioning
  &.-lg {
    width: v.$sidepanel-width-large;
  }

  &.-right {
    right: v.$sidepanel-position-offset;
    transition: right v.$sidepanel-position-animation-duration;
    box-shadow: v.$sidepanel-box-shadow-offset-x-right v.$sidepanel-box-shadow-offset-y v.$sidepanel-box-shadow-blur-radius
      v.$sidepanel-box-shadow-color;

    &.-lg {
      right: v.$sidepanel-position-offset-lg;
    }

    &.-open {
      right: 0;
    }
  }

  &.-left {
    left: v.$sidepanel-position-offset;
    transition: left v.$sidepanel-position-animation-duration;
    box-shadow: v.$sidepanel-box-shadow-offset-x v.$sidepanel-box-shadow-offset-y v.$sidepanel-box-shadow-blur-radius
      v.$sidepanel-box-shadow-color;

    &.-lg {
      left: v.$sidepanel-position-offset-lg;
    }

    &.-open {
      left: 0;
    }
  }

  // Sidepanel mode="tabs"
  &.-tabs {
    .#{$componentSelector}_header {
      border-bottom: 0;
    }
  }

  // Fullscreen
  &.-fullscreen {
    width: 100vw;
    min-width: map.get($grid-breakpoints, 'sm');


    &:not(.-open) {
      &.-right {
        right: -100vw;

        &.-lg {
          right: -100vw;
        }
      }

      &.-left {
        left: -100vw;

        &.-lg {
          left: -100vw;
        }
      }
    }
  }
}
