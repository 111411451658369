@use '../../../basic/variables' as v;
@use '../../../basic/functions' as f;

$select-height: v.$input-height;
$select-padding-y: v.$input-padding-y;
$select-padding-x: v.$input-padding-x;
$select-font-family: v.$input-font-family;
$select-font-size: v.$input-font-size;
$select-font-weight: v.$input-font-weight;
$select-line-height: v.$input-line-height;
$select-color: v.$input-color;
$select-border-width: v.$input-border-width;
$select-border-color: v.$input-border-color;
$select-border-radius: v.$input-border-radius;
$select-box-shadow: v.$input-box-shadow;
$select-bg: v.$input-bg;
$select-placeholder-color: v.$input-placeholder-color;
$select-transition: v.$input-transition;
$select-placeholder-padding-right: 23px;

// Arrow
$select-arrow-color: $select-border-color;
$select-arrow-size: 4.5px;
$select-arrow-color: v.$dark;
$select-arrow-hover-color: darken($select-arrow-color, v.$btn-bg-darken-hover);

// Multiple select values
$select-multiple-values-gap: 4px;
$select-multiple-value-height: 18px;
$select-multiple-value-gap: 4px;
$select-multiple-value-padding-x: 6px;
$select-multiple-value-border-radius: $select-multiple-value-height;
$select-multiple-value-bg: mix(v.$black, v.$white, 5%);
$select-multiple-value-font-size: v.$font-size-xxs;
$select-multiple-value-color: v.$gray-900;
$select-multiple-value-bg-disabled: mix(v.$black, v.$white, 20%);
$select-multiple-value-color-disabled: v.$white;

// Dropdown
$select-dropdown-bg: v.$white;
$select-dropdown-border-width: $select-border-width;
$select-dropdown-border-color: $select-border-color;
$select-dropdown-border-radius: v.$border-radius;
$select-dropdown-box-shadow: 0 0 5px rgba(v.$black, 0.12);

// Select dropdown items
$select-dropdown-item-font-size: $select-font-size;
$select-dropdown-item-font-weight: v.$font-weight-base;
$select-dropdown-item-optgroup-font-weight: v.$font-weight-semibold;
$select-dropdown-item-line-height: v.$line-height-base;
$select-dropdown-item-padding-y: 5px;
$select-dropdown-item-padding-x: $select-padding-x;
$select-dropdown-item-color: v.$gray-900;

// Selected items
$select-dropdown-item-selected-bg: mix(v.$primary, v.$white, 20%);
$select-dropdown-item-selected-color: f.color-contrast($select-dropdown-item-selected-bg);
$select-dropdown-item-selected-font-weight: v.$font-weight-semibold;

// Marked (focused) items
$select-dropdown-item-marked-bg: mix(v.$primary, v.$white, 10%);
$select-dropdown-item-marked-color: f.color-contrast($select-dropdown-item-marked-bg);
$select-dropdown-item-marked-font-weight: v.$font-weight-base;

// Disabled items
$select-dropdown-item-disabled-color: v.$disabled-color;

// Focus state
$select-focus-bg: v.$input-focus-bg;
$select-focus-border-color: v.$input-focus-border-color;
$select-foxus-box-shadow: v.$input-focus-box-shadow;

// Disabled
$select-disabled-bg: v.$input-disabled-bg;
$select-disabled-color: v.$input-disabled-color;

// select-sm
$select-height-sm: v.$input-height-sm;
$select-padding-y-sm: v.$input-padding-y-sm;
$select-padding-x-sm: v.$input-padding-x-sm;
$select-font-size-sm: v.$input-font-size-sm;
$select-line-height-sm: v.$input-line-height-sm;
$select-border-radius-sm: v.$input-border-radius-sm;
$select-placeholder-padding-right-sm: 20px;
$select-dropdown-item-padding-y-sm: 3px;
$select-dropdown-item-padding-x-sm: $select-padding-x-sm;
$select-dropdown-item-font-size-sm: $select-font-size-sm;

// select-lg
$select-height-lg: v.$input-height-lg;
$select-padding-y-lg: v.$input-padding-y-lg;
$select-padding-x-lg: v.$input-padding-x-lg;
$select-font-size-lg: v.$input-font-size-lg;
$select-line-height-lg: v.$input-line-height-lg;
$select-border-radius-lg: v.$input-border-radius-lg;
$select-placeholder-padding-right-lg: 25px;
$select-dropdown-item-padding-y-lg: 6px;
$select-dropdown-item-padding-x-lg: $select-padding-x-lg;
$select-dropdown-item-font-size-lg: $select-font-size-lg;

// Sizes
$select-sizes: 'sm' $select-height-sm $select-padding-y-sm $select-padding-x-sm $select-font-size-sm $select-line-height-sm
    $select-border-radius-sm $select-placeholder-padding-right-sm,
  'lg' $select-height-lg $select-padding-y-lg $select-padding-x-lg $select-font-size-lg $select-line-height-lg $select-border-radius-lg
    $select-placeholder-padding-right-lg;

// Dropdown Sizes
$select-dropdown-sizes: 'sm' $select-dropdown-item-padding-y-sm $select-dropdown-item-padding-x-sm $select-dropdown-item-font-size-sm,
  'lg' $select-dropdown-item-padding-y-lg $select-dropdown-item-padding-x-lg $select-dropdown-item-font-size-lg;
