@use '../../basic/variables' as v;

.mbs-progress-bar {
  overflow: inherit !important;
  &-waiting {
    display: block;
    width: v.$progress-bar-waiting-size;
    height: v.$progress-bar-waiting-size;
    animation: v.$progress-bar-waiting-animation-timing moveShadow;
    background-image: v.$progress-bar-waiting-bg-img;
    background-size: v.$progress-bar-waiting-bg-size;
    box-shadow: v.$progress-box-shadow;

    @keyframes moveShadow {
      0% {
        background-position-x: v.$progress-bar-waiting-animation-position-from;
      }
      40%,
      100% {
        background-position-x: v.$progress-bar-waiting-animation-position-to;
      }
    }
  }
  &-container {
    width: 100%;
    height: 10px;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
  }
  &-icon-box {
    width: 16px;
    height: 16px;
    border: 1px solid #ffffff;
    border-radius: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-icon-container {
    flex-direction: row;
    position: absolute;
    margin-left: -8px;
  }
  &-icon {
    font-size: 10px;
  }
}
