@use 'variables' as v;

%heading-disabled {
  &.-disabled {
    @extend %disabled-element;
    color: v.$headings-color-disabled;
  }
}

@mixin headers($font-size, $line-height, $margin-top, $margin-bottom, $letter-spacing) {
  margin-bottom: $margin-bottom;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  line-height: $line-height;

  &:not(:first-child) {
    margin-top: $margin-top;
  }

  @extend %heading-disabled;
}

@each $header, $font-size, $line-height, $margin-top, $margin-bottom, $letter-spacing in v.$headers {
  #{$header},
  .#{$header} {
    @include headers($font-size, $line-height, $margin-top, $margin-bottom, $letter-spacing);
  }
}

p {
  margin-bottom: v.$paragraph-margin-bottom;
  line-height: v.$paragraph-line-height;
}

// Font weight
@each $weight, $value in v.$font-weights {
  .font-weight-#{$weight} {
    font-weight: $value !important;
  }
}

strong,
b {
  font-weight: v.$font-weight-semibold;
}

.font-italic {
  font-style: italic !important;
}

small,
.small {
  font-size: v.$small-font-size;
  line-height: v.$small-line-height;
}

.border-bottom-dotted {
  border-bottom: 1px v.$primary dotted;
}

$text-decoration-lines: (none, underline, overline, line-through) !default;
@each $line in $text-decoration-lines {
  .text-decoration-#{$line} {
    text-decoration: $line !important;
  }
}

// Text sizes
@each $size, $value in v.$font-sizes {
  .text-#{$size} {
    font-size: $value !important;
  }
}

// Font family
@each $font, $value in v.$fonts {
  .font-#{$font} {
    font-family: $value !important;
  }
}

// Links (adds gray links)
@each $gray, $value in v.$grays {
  a.text-gray-#{$gray} {
    @include hover() {
      color: darken($value, 15%) !important;
    }
  }
}

abbr,
abbr[title] {
  border-bottom: v.$border-width dashed;
  text-decoration: none;
  cursor: help;
}

.text-decoration-underline-hover {
  &:hover {
    text-decoration: underline !important;
  }
}
