@use '../variables' as v;
@use '../functions' as f;

@mixin badge-variant($bg) {
  background-color: $bg;
  color: f.color-contrast($bg);

  @at-root a#{&} {
    @include hover-focus() {
      background-color: darken($bg, 10%);
      color: f.color-contrast($bg);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 v.$badge-focus-width rgba($bg, 0.5);
    }
  }
}
