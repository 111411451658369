@use '../../basic/variables' as v;
@use '../form-controls/check-radio/check-radio' as ch;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-table-grid;

mbs-table {
  display: block;

  /*.table-responsive {
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 20;
      top: 0;
      bottom: 0;
      width: v.$table-border-width;
      background: v.$table-border-color;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }*/
}

#{$componentSelector} {
  display: block;
  transition: padding-right 0.5s;
}

.#{$componentSelector} {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto;
  width: 100%;
  margin-bottom: v.$table-margin-bottom;
  overflow: auto;
  background: v.$table-bg;
  color: v.$table-color;
  font-size: v.$table-font-size;

  &_content {
    position: relative;
    z-index: 1;

    .-loading & {
      min-height: v.$table-loading-height;
    }
  }

  &_row {
    display: grid;
    position: relative;
    // Default auto set columns (doesn't support in IE)
    grid-template-columns: repeat(auto-fit, minmax(v.$table-grid-default-column-size-min, v.$table-grid-default-column-size-max));
    width: 100%;
    min-height: v.$table-grid-row-min-height;
    transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
    border-top: v.$table-border-width solid v.$table-border-color;
    border-color: v.$table-border-color;
    will-change: background-color, border-color;

    &:last-child {
      border-bottom: v.$table-border-width solid v.$table-border-color;
    }

    &.row-card {
      background: v.$table-card-head-bg;
      border-top-left-radius: v.$table-card-head-border-radius;
      border-top-right-radius: v.$table-card-head-border-radius;
    }

    &.-last-card-row {
      border-bottom-left-radius: v.$table-card-head-border-radius;
      border-bottom-right-radius: v.$table-card-head-border-radius;
      border-bottom: v.$table-border-width solid v.$table-border-color;
    }

    &.#{$componentSelector}_hint,
    &.#{$componentSelector}_header {
      &:not(.row-card) {
        border-bottom: 0;
      }
    }

    &.-selected {
      z-index: 11;
      border-color: v.$table-grid-row-selected-border-color !important;
      background-color: v.$table-grid-row-selected-bg !important;

      &:not(.-disabled):hover {
        background-color: v.$table-grid-row-selected-hover-bg !important;
      }

      + .#{$componentSelector}_row {
        border-top-color: v.$table-grid-row-selected-border-color !important;
      }

      @each $color, $value in v.$theme-colors {
        &.-#{$color} {
          border-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion) !important;
          background-color: mix($value, v.$white, v.$table-grid-row-selected-bg-color-proportion) !important;

          &:not(.-disabled):hover {
            background-color: mix($value, v.$white, v.$table-grid-row-selected-hover-bg-color-proportion) !important;
          }

          + .#{$componentSelector}_row {
            border-top-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion) !important;
          }
        }
      }
    }

    &.-disabled {
      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: v.$disabled-cursor;
      }
    }

    &:not(.-disabled):hover {
      .#{$componentSelector}_hidden-action {
        visibility: visible;
      }
    }
  }

  &_checkbox {
    &:before {
      @extend %check-radio-input;
      @extend %check-input;
      top: 0;
    }

    &:not(.-disabled) {
      &:before {
        cursor: pointer;
      }
    }

    &.-disabled {
      &:before {
        @extend %check-input-disabled;
      }
    }

    &.-checked {
      &:before {
        @extend %check-input-accented;
        content: i.$ico-ok;
      }
    }

    &.-indeterminate {
      &:before {
        @extend %check-input-accented;
        content: i.$ico-Minus;
      }
    }

    @each $size, $input-size, $min-height, $gap, $offset-top in v.$check-radio-sizes {
      &.-#{$size} {
        @include ch.check-radio-sizes($size, $input-size, $min-height, $gap, 0);
      }
    }
    @each $size, $border-radius, $ico-size in v.$check-sizes {
      &.-#{$size} {
        @include ch.check-sizes($size, $border-radius, $ico-size);
      }
    }
  }

  &_checkbox-cell {
    cursor: pointer;
  }

  &_hidden-action {
    visibility: hidden;
  }

  &_table-inner + &_row {
    border-top: 0;
  }

  &_header {
    min-height: v.$table-grid-header-min-height;
    &:not(.row-card) {
      background: v.$table-head-bg;
    }
    &.row-card {
      background-color: v.$table-bg;
      border-radius: v.$table-card-head-border-radius;
    }
    color: v.$table-head-color;
    font-weight: v.$table-head-font-weight;

    &.position-sticky {
      z-index: 10;
      box-shadow: 0 0 0 v.$table-border-width v.$table-border-color;
    }
  }

  &_cell {
    grid-template-columns: repeat(10, auto);
    align-self: center;
    padding: v.$table-cell-padding;
    text-align: left;
    justify-self: start;

    > * {
      align-self: center;
    }

    &-toggler {
      position: relative;
      align-self: stretch;
      padding: 0;
      text-align: center;
      justify-self: stretch;
    }

    .ctrl,
    .btn,
    mbs-button.-isCtrl {
      display: block;
      font-size: inherit;
      line-height: inherit;
      text-align: inherit;

      &-group {
        font-size: v.$btn-font-size;

        @each $size, $height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius in v.$btn-sizes {
          .btn-#{$size} {
            font-size: $font-size;
            line-height: $line-height;
          }
        }

        @each $size, $value, $gap in v.$ctrl-sizes {
          .ctrl-#{$size} {
            font-size: $value;
            gap: $gap;
          }
        }
      }
    }

    mbs-select {
      .ctrl {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: center;
      }
    }

    .mbs-check,
    .mbs-radio {
      top: 0;
    }

    .-disabled & {
      opacity: v.$table-disabled-opacity;
    }

    &.-active {
      opacity: 1;
      z-index: 11;
    }

    // Fix for different ctrl and btn sizes
    @each $size, $height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius in v.$btn-sizes {
      .btn-#{$size} {
        font-size: $font-size;
        line-height: $line-height;
      }
    }

    @each $size, $value, $gap in v.$ctrl-sizes {
      .ctrl-#{$size} {
        font-size: $value;
        gap: $gap;
      }
    }
    // End of fix sizes

    &.-sortable {
      transition: v.$table-sortable-transition;
      cursor: pointer;

      .fa,
      .ico {
        font-size: smaller;
        font-weight: inherit;
      }

      &:hover,
      &.-active {
        color: v.$table-head-sortable-color-hover;
      }
    }

    [sortable] {
      cursor: pointer;
    }

    // Justify content inside cells (horizontal)
    &.-start,
    &.-left {
      justify-self: start;
    }
    &.-end,
    &.-right {
      justify-self: end;
    }
    &.-center {
      justify-self: center;
    }
    &.-stretch {
      justify-self: stretch;
    }

    // Align content inside cells (vertical)
    &.-top {
      align-self: start;
    }
    &.-middle {
      align-self: center;
    }
    &.-bottom {
      align-self: end;
    }
    &.-align-stretch {
      align-self: stretch;
    }

    // Cell position
    &.-sticky {
      position: sticky;
      z-index: 10;
    }

    &.-sticky-left {
      left: 0;
    }

    &.-sticky-right {
      right: 0;
    }

    // Padding sizes
    &.-smy {
      padding-top: v.$table-cell-padding-sm;
      padding-bottom: v.$table-cell-padding-sm;
    }
    &.-smx {
      padding-right: v.$table-cell-padding-sm;
      padding-left: v.$table-cell-padding-sm;
    }
    &.-smpd {
      padding: v.$table-cell-padding-sm;
    }

    // Sizes
    &.-sm {
      font-size: v.$table-font-size-sm;
    }
    &.-lg {
      font-size: v.$table-font-size-lg;
    }
  }

  &_ico {
    font-size: v.$table-icon-size;

    &.-sm {
      font-size: v.$table-icon-size-sm;
    }
  }

  &_toggler {
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(50% - (#{v.$table-toggler-arrow-width-half} / 2));
      left: calc(50% - #{v.$table-toggler-arrow-width-half});
      width: 0;
      height: 0;
      border: v.$table-toggler-arrow-width-half solid transparent;
      border-top-color: v.$table-toggler-color;
    }

    &.collapsed {
      &:before {
        top: calc(50% - #{v.$table-toggler-arrow-width-half});
        left: calc(50% - (#{v.$table-toggler-arrow-width-half} / 2));
        transform: rotate(-90deg);
      }
    }

    &:hover {
      &:before {
        border-top-color: v.$table-toggler-color-hover;
      }
    }
  }

  &:not(.cardMode) {
    .mbs-loader {
      border: v.$table-border-width solid v.$table-border-color;
    }
  }

  .mbs-loader {
    z-index: 100;
  }

  &.-inline {
    display: inline-grid;
  }

  // Sizes
  &.-sm {
    font-size: v.$table-font-size-sm;

    .#{$componentSelector} {
      &_row {
        min-height: v.$table-grid-row-min-height-sm;
      }
    }
  }

  &.-lg {
    font-size: v.$table-font-size-lg;
  }

  &.-cells-borders {
    .#{$componentSelector}_row {
      &:not(.#{$componentSelector}_header).no-data {
        .#{$componentSelector}_cell {
          &:first-child {
            justify-content: center;
          }
        }
      }
    }

    .#{$componentSelector}_cell {
      display: flex;
      justify-self: stretch;
      align-self: stretch;
      align-items: center;

      &:not(:last-child) {
        border-right: v.$table-border-width solid v.$table-border-color;
      }
    }
  }

  // Modifications
  &.-bordered {
    .#{$componentSelector}_row {
      border-right: v.$table-border-width solid v.$table-border-color;
      border-left: v.$table-border-width solid v.$table-border-color;

      &.-selected {
        border-right-color: v.$table-grid-row-selected-border-color !important;
        border-left-color: v.$table-grid-row-selected-border-color !important;

        @each $color, $value in v.$theme-colors {
          &.-#{$color} {
            border-right-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion) !important;
            border-left-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion) !important;
          }
        }
      }
    }

    &.-vertical {
      .#{$componentSelector}_row {
        &:not(.#{$componentSelector}_header) {
          .#{$componentSelector}_cell {
            &:hover {
              background-color: v.$table-bordered-hover-cell-bg;
            }
          }

          &:hover {
            .#{$componentSelector}_cell {
              &:first-child {
                &:not(:has(.mbs-table-grid_checkbox)) {
                  background-color: v.$table-sticky-hover-bg;
                }
              }
            }
          }
          &.-last-card-row {
            .#{$componentSelector}_cell {
              &:first-child {
                border-bottom-left-radius: v.$table-card-head-border-radius;
              }
            }
          }
        }

        &:not(.#{$componentSelector}_header).no-data {
          .#{$componentSelector}_cell {
            &:first-child {
              justify-content: center;
            }
          }
        }
      }

      .#{$componentSelector}_cell {
        display: flex;
        justify-self: stretch;
        align-self: stretch;
        align-items: center;
        transition: background-color 0.1s ease-in-out;

        &:first-child {
          justify-content: start;

          &:has(.mbs-table-grid_checkbox),
          &:has(mbs-checkbox) {
            justify-content: center;
          }

          &:not(:has(.mbs-table-grid_checkbox)) {
            background-color: v.$table-bg;

            &.cell-card {
              background: v.$table-card-head-bg;
              border-top-left-radius: v.$table-card-head-border-radius;
              border-top-right-radius: v.$table-card-head-border-radius;
              justify-self: self-start;
              overflow: hidden;
            }
          }
        }

        &:not(:first-child) {
          justify-content: center;
        }

        &:not(:last-child) {
          border-right: v.$table-border-width solid v.$table-border-color;
        }
      }

      &.cardMode {
        .#{$componentSelector}_cell {
          &:not(:has(.mbs-table-grid_checkbox)) {
            &:not(.cell-card):not(.head-cell-card) {
              &.-sticky-left {
                box-shadow: 2px 3px 4px -1px #00000040;
              }
            }
          }
        }
      }

      .#{$componentSelector}_header {
        .#{$componentSelector}_cell {
          &:not(.head-cell-card) {
            &:first-child {
              background-color: v.$table-head-bg;
            }
          }
          &.head-cell-card {
            &:first-child {
              background-color: v.$table-bg;
              border-top-left-radius: v.$table-card-head-border-radius;
              border-bottom-left-radius: v.$table-card-head-border-radius;
              padding-top: v.$table-cell-card-header-padding-y;
              padding-bottom: v.$table-cell-card-header-padding-y;
              box-shadow: 2px 3px 4px -1px #00000040;
            }
          }
        }
      }
    }
  }

  &.-striped {
    .#{$componentSelector}_row {
      &:nth-child(#{$table-striped-order}) {
        background-color: v.$table-striped-bg;

        &.-selected {
          background-color: v.$table-grid-row-selected-bg !important;

          &:not(.-disabled):hover {
            background-color: v.$table-grid-row-selected-hover-bg !important;
          }

          @each $color, $value in v.$theme-colors {
            &.-#{$color} {
              background-color: mix($value, v.$white, v.$table-grid-row-selected-bg-color-proportion) !important;

              &:not(.-disabled):hover {
                background-color: mix($value, v.$white, v.$table-grid-row-selected-hover-bg-color-proportion) !important;
              }
            }
          }
        }
      }
    }
  }

  &.-hover {
    .#{$componentSelector}_row {
      &:not(.#{$componentSelector}_header) {
        &:hover {
          background-color: v.$table-hover-bg;
        }
      }
    }
  }

  &-wrapper {
    border: v.$list-select-list-border-width solid transparent;

    &.-invalid {
      border: v.$list-select-list-border-width solid v.$list-select-list-invalid-border-color;

      .mbs-table-grid {
        &_row {
          &:last-child {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }

  &-sticky-header {
    position: sticky;
    top: 0;
    z-index: v.$zindex-sticky-element;
  }

  .cardHeader {
    background-color: v.$table-bg;
    border-bottom-left-radius: v.$table-card-head-border-radius;
    border-bottom-right-radius: v.$table-card-head-border-radius;
  }
}

@import 'accordion';
