@use '../../basic/variables' as v;
@use '../../basic/functions' as f;

@mixin button-outline-variant(
  $color,
  $border: $color,
  $hover-background: $color,
  $hover-border: $color,
  $hover-color: f.color-contrast($color),
  $focus-background: darken($color, v.$btn-bg-darken-focus),
  $focus-border: $focus-background,
  $focus-color: f.color-contrast($color),
  $active-background: darken($color, v.$btn-bg-darken-active),
  $active-border: $active-background,
  $active-color: f.color-contrast($color),
  $disabled-background: transparent,
  $disabled-border: mix($color, v.$white, v.$btn-disabled-percentage),
  $disabled-color: $disabled-border
) {
  border-color: $border;
  color: $color;

  @include hover-focus {
    box-shadow: none;
  }

  &:hover,
  &.hover {
    border-color: $hover-border;
    background: $hover-background;
    color: $hover-color;
  }

  &:focus,
  &.focus {
    border-color: $focus-border;
    background: $focus-background;
    color: $focus-color;
  }

  &.disabled,
  &:disabled {
    border-color: $disabled-border;
    background: $disabled-background;
    color: $disabled-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background: $active-background;
    color: $active-color;

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: none;
    }
  }

  &.-loading {
    color: transparent;
    &:after {
      border-color: transparentize($color, 0.4);
      border-left-color: $color;
    }
  }
}

@each $color, $value in v.$theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
