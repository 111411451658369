@use 'variables' as cv;

.gapps-info {
  &-header {
    &_ico {
      display: block;
      width: cv.$gapps-info-header-ico-size;
    }

    h5 {
      line-height: initial;
    }
  }

  &-link {
    &-text {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .gapps-revisions {
    &_list {
      &-item {
        margin-bottom: cv.$gapps-revisions-list-item-margin-bottom;

        &_ico {
          display: block;
          width: cv.$gapps-revisions-list-item-ico-size;
        }
      }
    }
  }
}
