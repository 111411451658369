@use 'sass:math';
@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/breakpoints' as media;
@use '../../basic/mixins/border-radius' as br;
@use '../../basic/mixins/hover' as h;

// special navbar dynamic breakpoints calculations
$start: 0;
$end: 10;

@for $i from $start through $end {
  .navbar-buttons-#{$i*5} {
    $min_breakpoint: map-get(v.$navbar-breakpoints, 'lg');
    $max_breakpoint: map-get(v.$navbar-breakpoints, 'xl');
    $fixed_width: calc(v.$navbar-button_width * $i + v.$navbar-button_right_padding * math.ceil(math.div($i, 2))) + 0px;
    $fixed_min_breakpoint: calc($min_breakpoint + $fixed_width);
    $fixed_max_breakpoint: calc($max_breakpoint + $fixed_width);

    @include media.media-variable-up($fixed_min_breakpoint) {
      flex-flow: row nowrap;
      justify-content: flex-start;

      .col-nav-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }

      .order-nav-lg-2 {
        order: 2;
      }

      .order-nav-lg-3 {
        order: 3;
      }

      .navbar-nav {
        flex-direction: row;

        .dropdown-menu {
          position: absolute;
        }

        .nav-link {
          padding-right: v.$navbar-nav-link-padding-x;
          padding-left: v.$navbar-nav-link-padding-x;
        }
      }

      .navbar-nav-scroll {
        overflow: visible;
      }

      .navbar-collapse {
        display: flex !important; // stylelint-disable-line declaration-no-important

        // Changes flex-bases to auto because of an IE10 bug
        flex-basis: auto;
      }

      .navbar-toggler {
        display: none;
      }
    }

    @include media.media-breakpoint-down(xs, v.$navbar-breakpoints) {
      padding: 0 5px !important;
    }

    .navbar-nav {
      .nav-item {
        > .nav-link {
          @include media.media-variable-down($fixed_min_breakpoint) {
            width: 100%;

            padding: {
              right: v.$navbar-pd-x;
              left: v.$navbar-pd-x;
            }
          }
          @include media.media-variable-only($fixed_min_breakpoint, $fixed_max_breakpoint) {
            padding: {
              right: v.$navbar-link-pd-x-xs;
              left: v.$navbar-link-pd-x-xs;
            }
          }
        }
        @include media.media-variable-down($fixed_min_breakpoint) {
          width: 100%;

          text-align: left;

          padding: {
            right: 0;
            left: 0;
          }

          > .nav-submenu.-rel-sm {
            position: relative !important;
            margin-top: 0;
            transform: none !important;
            border: 0;
            border-radius: 0;
            background: v.$white;
            box-shadow: none;

            .nav-item {
              padding-left: v.$navbar-link-pd-x + v.$navbar-pd-x;
            }

            &.-arrow[placement] {
              &:before {
                display: none;
              }
            }
          }
        }
        @include media.media-variable-down($fixed_max_breakpoint) {
          .nav-link_ico {
            display: none;
          }
        }
      }
      @include media.media-variable-down($fixed_min_breakpoint) {
        width: calc(100% + (2 * #{v.$navbar-pd-x}));

        margin: {
          right: -1 * v.$navbar-pd-x;
          left: -1 * v.$navbar-pd-x;
        }
      }
    }
  }
}

.navbar {
  display: flex;
  position: relative;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: v.$navbar-pd-y v.$navbar-pd-x;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properties so that content nested within behave properly.
  %container-flex-properties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .container,
  .container-fluid {
    @extend %container-flex-properties;
  }

  @each $breakpoint, $container-max-width in v.$container-max-widths {
    > .container#{breakpoint-infix($breakpoint, v.$container-max-widths)} {
      @extend %container-flex-properties;
    }
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  margin-right: v.$navbar-pd-x;
  padding-top: v.$navbar-brand-padding-y;
  padding-bottom: v.$navbar-brand-padding-y;
  font-size: v.$navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;

  @include h.hover-focus() {
    text-decoration: none;
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}

// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: v.$nav-link-padding-y;
  padding-bottom: v.$nav-link-padding-y;
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1 !important;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;
}

.navbar-btn-collapse {
  @include media.media-breakpoint-down(xs, v.$navbar-breakpoints) {
    display: none;
  }
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  padding: v.$navbar-toggler-padding-y v.$navbar-toggler-padding-x;
  border: v.$border-width solid transparent; // remove default button style
  background-color: transparent; // remove default button style
  font-size: v.$navbar-toggler-font-size;
  line-height: 1;
  @include br.border-radius(v.$navbar-toggler-border-radius);

  @include h.hover-focus() {
    text-decoration: none;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  content: '';
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background: 50% / 100% 100% no-repeat;
  vertical-align: middle;
}

.navbar-nav-scroll {
  max-height: v.$navbar-nav-scroll-max-height;
  overflow-y: auto;
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: v.$navbar-light-brand-color;

    @include h.hover-focus() {
      color: v.$navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: v.$navbar-light-color;

      @include h.hover-focus() {
        color: v.$navbar-light-hover-color;
      }

      &.disabled {
        color: v.$navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: v.$navbar-light-active-color;
    }
  }

  .navbar-toggler {
    border-color: v.$navbar-light-toggler-border-color;
    color: v.$navbar-light-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg(v.$navbar-light-toggler-icon-bg);
  }

  .navbar-text {
    color: v.$navbar-light-color;
    a {
      color: v.$navbar-light-active-color;

      @include h.hover-focus() {
        color: v.$navbar-light-active-color;
      }
    }
  }
}

$navbar-list: (
  'navbar': (
    'breakpoints': v.$navbar-breakpoints,
    'navbar-prefix': 'mbs',
    'expand-suffix': '-nav'
  ),
  'navbar-apps': (
    'breakpoints': v.$navbar-apps-breakpoints,
    'navbar-prefix': 'apps',
    'expand-suffix': '-apps'
  )
);

@each $navbar-name, $navbar in $navbar-list {
  $breakpoints: map-get($navbar, 'breakpoints');
  $navbar-prefix: map-get($navbar, 'navbar-prefix');
  $expand-suffix: map-get($navbar, 'expand-suffix');

  // Generate series of `.navbar-expand-*` responsive classes for configuring
  // where your navbar collapses.
  .navbar-expand#{$expand-suffix} {
    @each $breakpoint in map-keys($breakpoints) {
      $next: breakpoint-next($breakpoint, $breakpoints);
      $infix: breakpoint-infix($next, $breakpoints);

      &#{$infix} {
        @include media-breakpoint-down($breakpoint, $breakpoints) {
          > .container,
          > .container-fluid {
            padding-right: 0;
            padding-left: 0;
          }

          @each $size, $container-max-width in v.$container-max-widths {
            > .container#{breakpoint-infix($size, v.$container-max-widths)} {
              padding-right: 0;
              padding-left: 0;
            }
          }
        }

        @include media-breakpoint-up($next, $breakpoints) {
          flex-flow: row nowrap;
          justify-content: flex-start;

          .navbar-nav {
            flex-direction: row;

            .dropdown-menu {
              position: absolute;
            }

            .nav-link {
              padding-right: v.$navbar-nav-link-padding-x;
              padding-left: v.$navbar-nav-link-padding-x;
            }
          }

          > .container,
          > .container-fluid {
            flex-wrap: nowrap;
          }

          @each $size, $container-max-width in v.$container-max-widths {
            > .container#{breakpoint-infix($size, v.$container-max-widths)} {
              flex-wrap: nowrap;
            }
          }

          .navbar-nav-scroll {
            overflow: visible;
          }

          .navbar-collapse {
            display: flex !important; // stylelint-disable-line declaration-no-important

            // Changes flex-bases to auto because of an IE10 bug
            flex-basis: auto;
          }

          .navbar-toggler {
            display: none;
          }
        }
      }
    }
  }

  .navbar.#{$navbar-prefix}-navbar {
    padding: v.$navbar-pd-y v.$navbar-pd-x;
    border-bottom: v.$navbar-border-width solid v.$navbar-border-color;
    background-image: v.$navbar-bg;
    box-shadow: v.$navbar-shadow;

    .navbar-toggler {
      &:hover,
      &:focus {
        outline: none;
      }

      @include media-breakpoint-down(sm, $breakpoints) {
        padding: v.$navbar-toggler-padding-y-md v.$navbar-toggler-padding-x-md;
        font-size: v.$navbar-toggler-font-size-md;
      }
    }

    .nav-link {
      padding: v.$navbar-link-pd-y v.$navbar-link-pd-x-xs;
      border: 0;
      background: none;
      font-size: v.$navbar-font-size;
      text-align: left;

      &:hover,
      &:focus {
        outline: none;
      }

      &_ico {
        margin-right: v.$navbar-link-ico-mg-r;
        font-size: v.$navbar-link-ico-size;
      }

      &_parent {
        padding-top: v.$gutter / 2;
        font-size: 0.8em;
      }

      &.-sublink {
        display: flex;
        align-items: center;
        height: 100%;
        padding: {
          top: 0;
          bottom: 0;
        }
      }

      @include media.media-breakpoint-up(xl, $breakpoints) {
        padding: {
          right: v.$navbar-link-pd-x;
          left: v.$navbar-link-pd-x;
        }
      }
    }

    .navbar-nav {
      align-items: center;

      .nav {
        &-item {
          > .nav-link {
            @include media.media-breakpoint-down(md, $breakpoints) {
              width: 100%;

              padding: {
                right: v.$navbar-pd-x;
                left: v.$navbar-pd-x;
              }
            }
          }

          &:active {
            background: none {
              color: v.$navbar-light-link-active-bg;
            }

            color: v.$navbar-light-active-color;
          }

          &.active {
            background: none {
              color: v.$navbar-light-link-active-bg;
            }
            box-shadow: v.$navbar-light-link-active-shadow;

            color: v.$navbar-light-link-active-color;

            > .nav-link {
              &:active,
              &:focus {
                outline: none;
                background: none {
                  color: v.$navbar-light-link-active-bg;
                }
                box-shadow: v.$navbar-light-link-active-shadow;

                color: v.$navbar-light-link-active-color;
              }
            }
          }

          @include media.media-breakpoint-down(md, $breakpoints) {
            width: 100%;

            text-align: left;

            padding: {
              right: 0;
              left: 0;
            }
          }
        }
      }

      @include media.media-breakpoint-down(md, $breakpoints) {
        width: calc(100% + (2 * #{v.$navbar-pd-x}));

        margin: {
          right: -1 * v.$navbar-pd-x;
          left: -1 * v.$navbar-pd-x;
        }
      }
    }

    .navbar-inner {
      width: 100%;
    }

    &_brand {
      margin-right: 0;
      padding: v.$navbar-logo-pd-y v.$navbar-logo-pd-r v.$navbar-logo-pd-y v.$navbar-logo-pd-l;
      font-size: v.$navbar-logo-size;
      line-height: 0;
    }

    &.navbar-light {
      .#{$navbar-prefix}-navbar {
        &_brand {
          color: v.$navbar-link-color;

          &:hover {
            color: v.$navbar-link-color-hover;
          }
        }
      }

      .nav-item {
        > .nav-link {
          border: 0;
          color: v.$navbar-link-color;
          text-shadow: v.$navbar-link-shadow;

          &_ico {
            color: inherit;
          }

          &:hover {
            color: v.$navbar-link-color-hover;
          }
        }

        &.disabled,
        &:disabled {
          cursor: default;
        }

        &.active,
        &:active {
          &:not(.disabled):not(:disabled) {
            outline: none;
            background: none {
              color: v.$navbar-light-link-active-bg;
            }

            box-shadow: v.$navbar-light-link-active-shadow;
            color: v.$navbar-light-link-active-color;

            > .nav-link {
              &:active,
              &:focus {
                outline: none;
                background-color: v.$navbar-light-link-active-bg;
                box-shadow: v.$navbar-light-link-active-shadow;
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}

@import 'submenu';
