@use '../../basic/mixins' as m;
@use '../../basic/variables' as v;

$componentSelector: mbs-drop-file;

.#{$componentSelector} {
  position: relative;
  overflow: hidden;
  border: v.$drop-file-border-width solid v.$drop-file-border-color;
  background-color: v.$drop-file-bg;

  &_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: v.$drop-file-transition;
    will-change: transform;
  }

  &_label {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &_clear {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    opacity: 0;
    color: v.$drop-file-with-image-color;
    cursor: pointer;
  }

  &_text {
    display: flex;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: v.$drop-file-transition-opacity;
    opacity: 0;
    background-color: v.$drop-file-text-bg;
    color: v.$drop-file-without-image-color;
    font-size: v.$drop-file-label-font-size;
    text-align: center;

    .#{$componentSelector} {
      &_icon {
        transition: v.$drop-file-transition;
        font-size: v.$drop-file-icon-size;
        line-height: v.$drop-file-icon-line-height;
      }

      &_title {
        @include m.textOverflow();
        transition: v.$drop-file-transition;
        font-size: v.$drop-file-title-font-size;
        font-weight: v.$drop-file-title-font-weight;
      }
    }

    &.-text-with-file {
      .#{$componentSelector} {
        &_icon,
        &_title {
          color: v.$drop-file-with-image-color;
        }
      }
    }

    &.-text-without-file {
      transition: v.$drop-file-transition;
      .#{$componentSelector} {
        &_icon,
        &_title {
          color: v.$drop-file-without-image-color;
        }
      }
    }

    &.-horizontal {
      flex-direction: row;
      .#{$componentSelector} {
        &_title {
          margin-left: v.$drop-file-gutter;
        }
        &_inner-text {
          width: v.$drop-file-inner-text-horizontal-width;
          margin-left: v.$drop-file-gutter;
        }
      }
    }
  }

  &:hover,
  &.-show-label {
    .#{$componentSelector} {
      &_img {
        filter: v.$drop-file-blur;
      }

      &_clear,
      &_text {
        opacity: 1;
      }
    }
  }

  &.-show-label {
    .#{$componentSelector}_text {
      &.-text-without-file {
        background-color: v.$drop-file-bg;
      }
    }

    &:hover {
      .#{$componentSelector} {
        &_text,
        &_icon,
        &_title {
          color: v.$drop-file-with-image-color;
        }

        &_text {
          &.-text-without-file {
            background-color: v.$drop-file-bg-hover;
          }
        }
      }
    }
  }

  &.-disabled {
    @extend %disabled-element;
    border-color: v.$drop-file-border-color-disabled;
    background: v.$drop-file-bg-disabled;
    color: v.$drop-file-color-disabled;

    .#{$componentSelector}_img {
      opacity: v.$drop-file-image-opacity-disabled;
    }

    &.-show-label {
      .#{$componentSelector}_text {
        opacity: 1;
        background: inherit;
        color: inherit;

        * {
          color: inherit;
        }
      }
    }
  }

  &.-invalid {
    border-color: v.$drop-file-border-color-invalid;
  }
}
