@use '../../basic/variables' as v;

.mbs-advanced-select {
  flex-wrap: nowrap;

  &_btn {
    &:first-child {
      margin-right: v.$advanced-select-margin-x;
    }

    &:last-child {
      margin-left: v.$advanced-select-margin-x;
    }
  }

  &_select {
    flex: 1 1 auto;
  }
}
