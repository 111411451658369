@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/grid' as gr;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentClass: mbs-wizard-steps;

.#{$componentClass} {
  @include gr.make-row();
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: v.$wizard-steps-padding-y v.$wizard-steps-padding-x;
  border-bottom: v.$wizard-steps-border-width solid v.$wizard-steps-border-color;
  background: v.$wizard-steps-bg;
  list-style: none;

  &_container {
    height: 100%;
    padding: v.$wizard-steps-container-padding-y v.$wizard-steps-container-padding-x;
  }

  &_item {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    text-align: center;

    &:before {
      @include m.icomoon();
      content: '';
      display: flex;
      position: relative;
      z-index: 20;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: v.$wizard-steps-marker-size;
      height: v.$wizard-steps-marker-size;
      margin-bottom: v.$wizard-steps-item-marker-mg-b;
      border: v.$wizard-steps-item-marker-border-width solid v.$wizard-steps-item-marker-border-color;
      border-radius: 50%;
      background: v.$wizard-steps-item-marker-bg;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 10;
      top: calc((#{v.$wizard-steps-marker-size} - #{v.$wizard-steps-item-marker-border-width}) / 2);
      left: 0;
      width: 100%;
      height: v.$wizard-steps-item-marker-border-width;
      background: v.$wizard-steps-item-marker-border-color;
    }

    &.active {
      &:before {
        border-width: v.$wizard-steps-item-active-marker-border-width;
        border-color: v.$wizard-steps-item-active-marker-border-color;
      }
    }

    &.success {
      &:before {
        content: i.$ico-tick;
        border-width: v.$wizard-steps-item-success-marker-border-width;
        background: v.$wizard-steps-item-success-marker-bg;
        color: v.$wizard-steps-item-success-marker-color;
        font-size: v.$wizard-steps-item-success-marker-ico-size;
      }
    }

    &:first-child {
      &:after {
        right: 0;
        left: auto;
        width: 50%;
      }
    }

    &:last-child {
      &:after {
        width: 50%;
      }
    }
  }

  &_link {
    padding-right: v.$wizard-steps-horizontal-link-pd-x;
    padding-left: v.$wizard-steps-horizontal-link-pd-x;
    color: v.$wizard-steps-horizontal-link-color;
    font-size: v.$wizard-steps-horizontal-link-font-size;
  }
}
