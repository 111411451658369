@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

$componentSelector: mbs-advanced-search;

.#{$componentSelector} {
  .#{$componentSelector} {
    &_dropdown {
      width: calc(100% + (2 * #{v.$input-border-width}));
      margin-top: v.$input-border-width;
      @include m.margin-x(-1 * v.$input-border-width);
      padding: v.$advanced-search-dropdown-padding-y v.$advanced-search-dropdown-padding-x;
      border: 0;
      @include br.border-top-radius(0);
    }

    &_content {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include m.padding-y(v.$input-padding-y);
      @include m.padding-x(v.$input-padding-x);
      overflow: hidden;
      white-space: nowrap;

      > * {
        display: inline;
        white-space: nowrap;
      }

      &:focus {
        outline: none;
      }
    }

    &_form-control {
      display: flex;
      align-items: stretch;
      @include m.padding(0);

      &.focus {
        border: 1px solid v.$input-focus-border-color;
        outline: none;
        background-color: v.$input-focus-bg;
        color: v.$input-focus-color;
      }
    }

    &_placeholder {
      @include m.padding-y(v.$input-padding-y);
      @include m.padding-x(v.$input-padding-x);
      position: absolute;
      top: 0;
      left: 0;
    }

    &_input {
      flex-grow: 2;
      height: 100%;
    }

    &_controls {
      flex-shrink: 0;
    }

    &_loader {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .autocomplete {
    &-position {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  .loader {
    @extend %loader;
  }
}
