@use 'sass:math';
@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/grid' as gr;

.mbs-form-dropdown {
  transform: translate(25px, 22.5714px) !important;

  .mbs-form {
    width: v.$form-dropdown-width;

    &-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_content {
    padding: v.$form-dropdown-pdy v.$form-dropdown-pdx 0;
  }

  &_data {
    height: v.$form-dropdown-data-height;
    overflow-x: hidden;
    overflow-y: auto;
    border: v.$form-dropdown-data-border-width solid v.$form-dropdown-data-border-color;
    background: v.$form-dropdown-data-bg;

    &-item {
      padding: v.$form-dropdown-data-item-pdy v.$form-dropdown-data-item-pdx;
      border-bottom: v.$form-dropdown-data-border-width solid v.$form-dropdown-data-border-color;
      background: v.$form-dropdown-data-item-bg;
      font-size: v.$form-dropdown-data-item-font-size;

      > .row {
        align-items: center;
      }

      &:hover {
        background: v.$form-dropdown-data-item-hover-bg;
      }
    }

    &.-filled {
      background: v.$form-dropdown-data-bg-items;
    }
  }

  &_footer {
    padding: v.$form-dropdown-footer-pdt v.$form-dropdown-footer-pdx v.$form-dropdown-footer-pdb;
    background: v.$form-dropdown-footer-bg;

    &-row {
      @include gr.make-row(0);
      align-items: center;
      @include m.margin-x(math.div(v.$form-dropdown-footer-pdx, -2));

      &.-end {
        justify-content: flex-end;
      }
      &.-start {
        justify-content: flex-start;
      }
      &.-center {
        justify-content: center;
      }
    }

    &-col {
      padding: math.div(v.$gutter, 2) math.div(v.$form-dropdown-footer-pdx, 2);
    }
  }
}
