@use '../../basic/variables' as v;
@use '../../basic/mixins/grid' as gr;

.mbs-form_label {
  margin-bottom: v.$label-margin-bottom;
  color: v.$label-color;
  font-size: v.$label-font-size;
  line-height: v.$label-line-height;

  &-required {
    color: v.$label-required-color;
  }

  &-group {
    @include gr.make-row();
    align-items: center;
    padding-right: v.$gutter;
    padding-bottom: v.$label-margin-bottom;
    padding-left: v.$gutter;
    font-size: v.$label-font-size;

    * {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: inherit;
    }
  }

  &.-accented {
    font-weight: v.$label-font-weight-accented;
  }

  // Sizes
  &.-sm {
    font-size: v.$label-font-size-sm;
  }
}

label {
  &:not(.-disabled):not(.disabled) {
    color: v.$label-color;
    cursor: pointer;
  }

  &.-disabled,
  &.disabled {
    @extend %disabled-element;
    color: v.$label-disabled-color;

    @each $color, $value in v.$theme-colors {
      .text-#{$color} {
        color: mix($value, v.$white, v.$label-disabled-percentage) !important;
      }
    }
  }
}
