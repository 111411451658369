@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/grid' as gr;
@use '../../basic/mixins/breakpoints' as media;

$componentSelector: mbs-steps;

.#{$componentSelector} {
  &_item {
    position: relative;
    padding-top: v.$steps-item-padding-top;

    &-header {
      @include gr.make-row();
      align-items: center;
      justify-content: space-between;

      &_controls {
        align-items: center;

        @include media.media-breakpoint-down(sm) {
          padding-left: v.$steps-content-padding-left;
        }
      }
    }

    &-index {
      display: flex;
      align-items: center;
      justify-content: center;
      width: v.$steps-item-index-size;
      height: v.$steps-item-index-size;
      border-radius: 50%;
      background: v.$steps-item-index-bg;
      color: v.$steps-item-index-color;
      font-size: v.$steps-item-index-font-size;
      font-weight: v.$steps-item-index-font-weight;

      &-ico {
        font-size: v.$steps-item-success-index-ico-size;
      }
    }

    &-title {
      @include m.margin-y(0);

      .small {
        font-weight: inherit;
      }
    }

    &-content {
      position: relative;
      margin-top: v.$steps-content-margin-top;
      padding-left: v.$steps-content-padding-left;
    }

    &.-active {
      .#{$componentSelector} {
        &_item {
          &-index {
            background: v.$steps-item-active-index-bg;
            color: v.$steps-item-active-index-color;
          }

          &-content {
            &:before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: v.$steps-content-line-position-x;
              width: v.$steps-content-line-width;
              background: v.$steps-content-line-color;
            }
          }
        }
      }
    }

    &.-success {
      .#{$componentSelector} {
        &_item {
          &-index {
            background: v.$steps-item-success-index-bg;
            color: v.$steps-item-success-index-color;
          }
        }
      }
    }
  }
}
