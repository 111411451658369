@use 'variables' as v;
@use 'functions' as f;
@use 'mixins/border-radius' as br;

@mixin icomoon($icomoon-font-family: MBS_font, $lh: 1) {
  font-family: '#{$icomoon-font-family}' !important;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: $lh;
  text-transform: none;
  speak: none;
}

@mixin icon($iconPosT, $iconPosL, $content, $iconColor, $iconFont: inherit, $iconPosR: auto, $iconPosB: auto) {
  &:before {
    @include icomoon();
    content: $content;
    display: block;
    position: absolute;
    top: $iconPosT;
    left: $iconPosL;
    color: $iconColor;
    font-size: $iconFont;
  }
}

@mixin gradient($direction, $list) {
  background: nth(nth($list, 1), 1);
  background: linear-gradient($direction, $list);
}

@mixin arrTop(
  $width,
  $height,
  $top,
  $left,
  $right: auto,
  $bg: $white,
  $borderColor: v.$border-color,
  $borderWidth: v.$border-width,
  $zindex: 1
) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: $zindex;
    top: $top;
    right: $right;
    left: $left;
    width: $width;
    height: $height;
    transform: rotate(-45deg);
    border-top: $borderWidth solid $borderColor;
    border-right: $borderWidth solid $borderColor;
    background: $bg;
  }
}

@mixin arrBottom(
  $width,
  $height,
  $bottom,
  $left,
  $right: auto,
  $bg: $white,
  $borderColor: v.$border-color,
  $borderWidth: v.$border-width,
  $zindex: 1
) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: $zindex;
    right: $right;
    bottom: $bottom;
    left: $left;
    width: $width;
    height: $height;
    transform: rotate(135deg);
    border-top: $borderWidth solid $borderColor;
    border-right: $borderWidth solid $borderColor;
    background: $bg;
  }
}

@mixin arrRight(
  $width,
  $height,
  $top,
  $left,
  $bottom: auto,
  $bg: $white,
  $borderColor: v.$border-color,
  $borderWidth: v.$border-width,
  $zindex: 1
) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: $zindex;
    top: $top;
    bottom: $bottom;
    left: $left;
    width: $width;
    height: $height;
    transform: rotate(-135deg);
    border-top: $borderWidth solid $borderColor;
    border-right: $borderWidth solid $borderColor;
    background: $bg;
  }
}

@mixin arrLeft(
  $width,
  $height,
  $top,
  $right,
  $bottom: auto,
  $bg: $white,
  $borderColor: v.$border-color,
  $borderWidth: v.$border-width,
  $zindex: 1
) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: $zindex;
    top: $top;
    right: $right;
    bottom: $bottom;
    width: $width;
    height: $height;
    transform: rotate(45deg);
    border-top: $borderWidth solid $borderColor;
    border-right: $borderWidth solid $borderColor;
    background: $bg;
  }
}

@mixin textOverflow($maxWidth: 100%, $value: ellipsis) {
  max-width: $maxWidth;
  overflow: hidden;
  text-overflow: $value;
  white-space: nowrap;
}

@mixin gridColumn($val) {
  @for $i from 1 through $val {
    &:nth-child(#{$i}) {
      grid-column: $i;
    }
  }
}
@mixin gridRow($val) {
  @for $i from 1 through $val {
    &:nth-child(#{$i}) {
      grid-row: $i;
    }
  }
}

// Contextual backgrounds
@mixin bg-variant($parent, $color, $weight: 100%) {
  #{$parent} {
    background-color: mix($color, $white, 10%) !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}
/* Dirty Hack */
/* forced to add the 3rd argument, because there is a sass error.
in node_modules/bootstrap/scss/utilities/_background.scss file
the call of the mixin of the same name is performed with 3 aruments.
The validator interprets that call as a call to this mixin. */
@mixin bg-gradient-variant($parent, $color, $any) {
  #{$parent} {
    background: $color linear-gradient(180deg, mix(v.$body-bg, $color, 50%), $color) repeat-x !important;
  }
}
/* Dirty Hack -- end */

@mixin margin-y($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@mixin margin-x($value) {
  margin-right: $value;
  margin-left: $value;
}

@mixin margin($value) {
  margin-top: $value;
  margin-right: $value;
  margin-bottom: $value;
  margin-left: $value;
}

@mixin padding-y($value) {
  padding-top: $value;
  padding-bottom: $value;
}

@mixin padding-x($value) {
  padding-right: $value;
  padding-left: $value;
}

@mixin padding($value) {
  padding-top: $value;
  padding-right: $value;
  padding-bottom: $value;
  padding-left: $value;
}

@mixin position($y, $x, $position: absolute, $position-y: top, $position-x: left) {
  position: $position;

  @if $position-y == top {
    top: $y;
  } @else if $position-y == bottom {
    bottom: $y;
  }

  @if $position-x == left {
    left: $x;
  } @else if $position-x == right {
    right: $x;
  }
}
