@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

.#{$componentSelector} {
  &_list {
    margin-bottom: 0;
    border-radius: v.$list-select-list-border-radius;

    .mbs-table-grid {
      &_row {
        @include m.padding-x(v.$list-select-list-row-padding-x);

        &:first-child {
          border-top-color: transparent;
          @include br.border-top-radius(v.$list-select-list-border-radius);
        }

        &:last-child {
          border-bottom-color: transparent;
          @include br.border-bottom-radius(v.$list-select-list-border-radius);
        }
      }

      &_cell {
        @include m.padding-y(v.$list-select-list-cell-padding-y);
        @include m.padding-x(v.$list-select-list-cell-padding-x);
      }
    }

    &-wrapper {
      border-top: v.$list-select-list-border-width solid v.$list-select-list-border-color;
      border-bottom: v.$list-select-list-border-width solid v.$list-select-list-border-color;
      border-radius: v.$list-select-list-border-radius;

      &.-maxHeight {
        border-right: v.$list-select-list-border-width solid v.$list-select-list-border-color;
        .mbs-table-grid {
          &_row {
            padding-right: calc(#{v.$list-select-list-border-width} + #{v.$list-select-list-row-padding-x});
            border-right: 0 solid v.$list-select-list-border-color;
          }
        }
      }

      &:not(.-maxHeight) {
        border-right-width: 0;

        .mbs-table-grid {
          &_row {
            padding-right: v.$list-select-list-row-padding-x;
            border-right: v.$list-select-list-border-width solid v.$list-select-list-border-color;
          }
        }

        &.-invalid {
          .mbs-table-grid {
            &_row {
              border-right-width: 0;
            }
          }
        }
      }

      &.-invalid {
        border-right: v.$list-select-list-border-width solid v.$list-select-list-invalid-border-color;
        border-left: v.$list-select-list-border-width solid v.$list-select-list-invalid-border-color;
        border-top-color: v.$list-select-list-invalid-border-color;
        border-bottom-color: v.$list-select-list-invalid-border-color;

        .mbs-table-grid {
          &_row {
            &:first-child {
              border-top-color: transparent;
            }

            &:last-child {
              border-bottom-color: transparent;
            }
          }
        }
      }

      &:not(.-invalid) {
        border-top-color: transparent;
        border-bottom-color: transparent;

        .mbs-table-grid {
          &_row {
            border-left: v.$list-select-list-border-width solid v.$list-select-list-border-color;

            &:first-child {
              border-top: v.$list-select-list-border-width solid v.$list-select-list-border-color;
            }

            &:last-child {
              border-bottom: v.$list-select-list-border-width solid v.$list-select-list-border-color;
            }

            &.-selected {
              padding-right: v.$list-select-list-row-padding-x;
              border-right: v.$list-select-list-border-width solid v.$list-select-list-row-selected-border-color;
              border-left-color: v.$list-select-list-row-selected-bg-color;

              @each $color, $value in v.$theme-colors {
                &.-#{$color} {
                  border-right-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion);
                  border-left-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion);

                  &:first-child {
                    border-top-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion);
                  }

                  &:last-child {
                    border-bottom-color: mix($value, v.$white, v.$table-grid-row-selected-border-color-proportion);
                  }
                }
              }
            }
          }
        }
      }

      &.-showTopControls {
        margin-top: -2 * v.$list-select-list-border-width;
        @include br.border-top-radius(0);

        .mbs-table-grid {
          @include br.border-top-radius(0);

          &_row {
            &:first-child {
              @include br.border-top-radius(0);
            }
          }
        }
      }
    }
  }
}
