@use '../../basic/variables' as bv;

//TODO: Replace footer with common styles and variables
.gapps-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: bv.$footer-height;
  padding: 0 20px;
  border-top: 1px solid #d2d2d2;
  background: #f9f9f9;
  text-align: center;

  &-logo {
    max-width: 255px;
    max-height: 65px;
    object-fit: cover;
  }
}
