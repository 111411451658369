@use 'variables' as v;
@use 'mixins/gradients' as gradients;

// Transparent bg
@mixin bg-transparent($color) {
  background-color: transparentize($color, 0.7) !important;
}
@each $color, $value in v.$theme-colors {
  .bg-transparent-#{$color} {
    @include bg-transparent($value);
  }
}
@each $color, $value in v.$colors {
  .bg-transparent-#{$color} {
    @include bg-transparent($value);
  }
}

// Solid bg
@each $color, $value in v.$theme-colors {
  .background-#{$color} {
    background-color: $value !important;
  }
}
@each $color, $value in v.$colors {
  .background-#{$color} {
    background-color: $value !important;
  }
}

// background transparent in 5% step
@for $i from 0 through 100 {
  @if $i % 5 == 0 {
    @each $color, $value in v.$theme-colors {
      .bg-#{$color}-#{$i} {
        background-color: rgba($value, $i / 100) !important;
      }
    }
    @each $color, $value in v.$colors {
      .bg-#{$color}-#{$i} {
        background-color: rgba($value, $i / 100) !important;
      }
    }
  }
}

// Background-gradient
@each $color, $value in v.$theme-colors {
  .gradient-x-#{$color} {
    @include gradients.gradient-x(mix(v.$white, $value, 50%), $value);
  }
  .gradient-y-#{$color} {
    @include gradients.gradient-y(mix(v.$white, $value, 50%), $value);
  }
  .gradient-radial-#{$color} {
    @include gradients.gradient-radial(mix(v.$white, $value, 50%), $value);
  }
  .gradient-striped-#{$color} {
    @include gradients.gradient-striped($value);
  }
}
