@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

$componentSelector: mbs-btn-group;

mbs-button-group {
  @extend .btn-group;
}

.#{$componentSelector} {
  // Basic
  > mbs-button {
    > .btn {
      position: relative;

      &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus,
        &:active,
        &.active {
          z-index: 10;
        }
      }
    }
  }

  // Horizontal
  &:not(.#{$componentSelector}_vertical) {
    > mbs-button {
      &:not(:first-child) {
        margin-left: -1 * v.$btn-border-width;

        > .btn,
        > .dropdown > .btn {
          @include br.border-left-radius(0);
        }
      }

      &:not(:last-child) {
        > .btn,
        > .dropdown > .btn {
          @include br.border-right-radius(0);
        }
      }

      // Nested
      &-group {
        &:not(:first-child) {
          margin-left: -1 * v.$btn-border-width;

          > .#{$componentSelector} {
            > mbs-button {
              > .dropdown {
                > .btn {
                  @include br.border-left-radius(0);
                }
              }
            }
          }

          > .btn-group {
            > #{$componentSelector},
            > mbs-button {
              &:first-child {
                > .btn {
                  @include br.border-left-radius(0);
                }
              }
            }
          }
        }

        &:not(:last-child) {
          > .#{$componentSelector} {
            > mbs-button {
              > .dropdown {
                > .btn {
                  @include br.border-right-radius(0);
                }
              }
            }
          }

          > .btn-group {
            > #{$componentSelector},
            > mbs-button {
              &:last-child {
                > .btn {
                  @include br.border-right-radius(0);
                }
              }
            }
          }
        }
      }
    }
  }

  // Vertical
  &_vertical {
    align-items: stretch;

    > mbs-button {
      display: block;

      > .btn,
      > .dropdown,
      > .dropdown > .btn {
        display: block;
        width: 100%;
      }

      &:not(:first-child) {
        margin-top: -1 * v.$btn-border-width;
        margin-left: 0;

        > .btn,
        > .dropdown > .btn {
          @include br.border-top-radius(0);
        }
      }

      &:not(:last-child) {
        > .btn,
        > .dropdown > .btn {
          @include br.border-bottom-radius(0);
        }
      }

      // Nested
      &-group {
        &:not(:first-child) {
          margin-top: -1 * v.$btn-border-width;
          margin-left: 0;

          > .#{$componentSelector} {
            > mbs-button {
              > .dropdown {
                > .btn {
                  @include br.border-top-radius(0);
                }
              }
            }
          }
        }

        &:not(:last-child) {
          > .#{$componentSelector} {
            > mbs-button {
              > .dropdown {
                > .btn {
                  @include br.border-bottom-radius(0);
                }
              }
            }
          }
        }
      }
    }
  }

  // Sizing
  @each $size, $height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius in v.$btn-sizes {
    &_#{$size} {
      > mbs-button {
        > .btn {
          @extend .btn-#{$size};
        }
      }
    }
  }
}
