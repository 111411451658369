@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/functions' as f;
@use '../../basic/mixins/hover' as h;

.dropup,
.dropright,
.dropdown,
.dropleft {
  z-index: v.$zindex-dropdown;

  &-menu {
    z-index: v.$zindex-dropdown + 1;

    .dropdown-item {
      display: flex;
      position: relative;
      z-index: v.$zindex-dropdown;
      align-items: center;
      min-height: v.$dropdown-item-min-height;
      line-height: v.$dropdown-item-line-height;

      @include h.hover-focus() {
        background: none;
        color: inherit;
      }

      &.-sm {
        min-height: v.$dropdown-item-min-height-sm;
        padding: v.$dropdown-item-padding-y-sm v.$dropdown-item-padding-x-sm;
        font-size: v.$dropdown-font-size-sm;
      }

      &.-lg {
        min-height: v.$dropdown-item-min-height-lg;
        font-size: v.$dropdown-font-size-lg;
      }
    }

    a,
    button {
      &.dropdown-item {
        &:not(:disabled):not(.disabled) {
          cursor: pointer;

          &:hover {
            background: v.$secondary;
            color: f.color-contrast(v.$secondary);
          }
        }
        &:not(:disabled):not(.disabled):not(.disabled-focus) {
          &:focus {
            outline: none;
            background: v.$primary;
            color: f.color-contrast(v.$primary);
          }
        }
      }

      &:disabled,
      &.disabled {
        @extend %disabled-element;
      }
    }

    &.-arrow {
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
      }

      &[placement^='bottom'],
      &[x-placement^='bottom'] {
        margin-top: v.$dropdown-arrow-size + v.$dropdown-spacer;

        &:before {
          top: v.$dropdown-arrow-size * -1;
          left: calc(50% - #{v.$dropdown-arrow-size});
          border-top: 0;
          border-right: v.$dropdown-arrow-size solid transparent;
          border-bottom: v.$dropdown-arrow-size solid v.$dropdown-bg;
          border-left: v.$dropdown-arrow-size solid transparent;
        }
      }

      &[placement='bottom-left'],
      &[x-placement='bottom-left'] {
        &:before {
          left: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }

      &[placement='bottom-right'],
      &[x-placement='bottom-right'] {
        &:before {
          right: v.$dropdown-arrow-size + v.$dropdown-spacer;
          left: auto;
        }
      }

      &[placement^='top'],
      &[x-placement^='top'] {
        margin-bottom: v.$dropdown-arrow-size + v.$dropdown-spacer;

        &:before {
          bottom: v.$dropdown-arrow-size * -1;
          left: calc(50% - #{v.$dropdown-arrow-size});
          border-top: v.$dropdown-arrow-size solid v.$dropdown-bg;
          border-right: v.$dropdown-arrow-size solid transparent;
          border-bottom: 0;
          border-left: v.$dropdown-arrow-size solid transparent;
        }
      }

      &[placement='top-left'],
      &[x-placement='top-left'] {
        &:before {
          left: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }

      &[placement='top-right'],
      &[x-placement='top-right'] {
        &:before {
          right: v.$dropdown-arrow-size + v.$dropdown-spacer;
          left: auto;
        }
      }

      &[placement^='left'],
      &[x-placement^='left'] {
        margin-right: v.$dropdown-arrow-size + v.$dropdown-spacer;

        &:before {
          top: calc(50% - #{v.$dropdown-arrow-size});
          right: v.$dropdown-arrow-size * -1;
          border-top: v.$dropdown-arrow-size solid transparent;
          border-right: 0;
          border-bottom: v.$dropdown-arrow-size solid transparent;
          border-left: v.$dropdown-arrow-size solid v.$dropdown-bg;
        }
      }

      &[placement='left-top'],
      &[x-placement='left-top'] {
        &:before {
          top: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }

      &[placement='left-bottom'],
      &[x-placement='left-bottom'] {
        &:before {
          top: auto;
          bottom: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }

      &[placement^='right'],
      &[x-placement^='right'] {
        margin-left: v.$dropdown-arrow-size + v.$dropdown-spacer;

        &:before {
          top: calc(50% - #{v.$dropdown-arrow-size});
          left: v.$dropdown-arrow-size * -1;
          border-top: v.$dropdown-arrow-size solid transparent;
          border-right: v.$dropdown-arrow-size solid v.$dropdown-bg;
          border-bottom: v.$dropdown-arrow-size solid transparent;
          border-left: 0;
        }
      }

      &[placement='right-top'],
      &[x-placement='right-top'] {
        &:before {
          top: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }

      &[placement='right-bottom'],
      &[x-placement='right-bottom'] {
        &:before {
          top: auto;
          bottom: v.$dropdown-arrow-size + v.$dropdown-spacer;
        }
      }
    }

    // Sizes
    &.-sm {
      font-size: v.$dropdown-font-size-sm;

      .dropdown-item {
        min-height: v.$dropdown-item-min-height-sm;
        padding: v.$dropdown-item-padding-y-sm v.$dropdown-item-padding-x-sm;
      }

      &.-arrow {
        &[placement^='bottom'],
        &[x-placement^='bottom'] {
          margin-top: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;

          &:before {
            top: v.$dropdown-arrow-size-sm * -1;
            left: calc(50% - #{v.$dropdown-arrow-size-sm});
            border-right: v.$dropdown-arrow-size-sm solid transparent;
            border-bottom: v.$dropdown-arrow-size-sm solid v.$dropdown-bg;
            border-left: v.$dropdown-arrow-size-sm solid transparent;
          }
        }

        &[placement='bottom-left'],
        &[x-placement='bottom-left'] {
          &:before {
            left: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }

        &[placement='bottom-right'],
        &[x-placement='bottom-right'] {
          &:before {
            right: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
            left: auto;
          }
        }

        &[placement^='top'],
        &[x-placement^='top'] {
          margin-bottom: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;

          &:before {
            bottom: v.$dropdown-arrow-size-sm * -1;
            left: calc(50% - #{v.$dropdown-arrow-size-sm});
            border-top: v.$dropdown-arrow-size-sm solid v.$dropdown-bg;
            border-right: v.$dropdown-arrow-size-sm solid transparent;
            border-left: v.$dropdown-arrow-size-sm solid transparent;
          }
        }

        &[placement='top-left'],
        &[x-placement='top-left'] {
          &:before {
            left: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }

        &[placement='top-right'],
        &[x-placement='top-right'] {
          &:before {
            right: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
            left: auto;
          }
        }

        &[placement^='left'],
        &[x-placement^='left'] {
          margin-right: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;

          &:before {
            top: calc(50% - #{v.$dropdown-arrow-size-sm});
            right: v.$dropdown-arrow-size-sm * -1;
            border-top: v.$dropdown-arrow-size-sm solid transparent;
            border-bottom: v.$dropdown-arrow-size-sm solid transparent;
            border-left: v.$dropdown-arrow-size-sm solid v.$dropdown-bg;
          }
        }

        &[placement='left-top'],
        &[x-placement='left-top'] {
          &:before {
            top: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }

        &[placement='left-bottom'],
        &[x-placement='left-bottom'] {
          &:before {
            top: auto;
            bottom: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }

        &[placement^='right'],
        &[x-placement^='right'] {
          margin-left: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;

          &:before {
            top: calc(50% - #{v.$dropdown-arrow-size-sm});
            left: v.$dropdown-arrow-size-sm * -1;
            border-top: v.$dropdown-arrow-size-sm solid transparent;
            border-right: v.$dropdown-arrow-size-sm solid v.$dropdown-bg;
            border-bottom: v.$dropdown-arrow-size-sm solid transparent;
          }
        }

        &[placement='right-top'],
        &[x-placement='right-top'] {
          &:before {
            top: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }

        &[placement='right-bottom'],
        &[x-placement='right-bottom'] {
          &:before {
            top: auto;
            bottom: v.$dropdown-arrow-size-sm + v.$dropdown-spacer;
          }
        }
      }
    }

    &.-lg {
      font-size: v.$dropdown-font-size-lg;

      .dropdown-item {
        min-height: v.$dropdown-item-min-height-lg;
      }
    }
  }

  &-item_ico {
    vertical-align: middle;
  }

  &-toggle {
    &.-no-arrow {
      &:after {
        display: none;
      }
    }

    &-ctrl {
      display: inline-block;
      margin-left: 7px;
      line-height: 1;
      vertical-align: text-bottom;

      .ctrl {
        line-height: inherit;
      }
    }
  }

  & &-menu {
    pointer-events: auto;
  }
}

.dropdown-z-default {
  z-index: 10!important;
}

.btn-group.show {
  .dropdown-toggle {
    box-shadow: none;
  }
}
