@use '../../basic/variables' as v;
@use '../../basic/mixins/alert' as alert;
@use '../../basic/mixins/border-radius' as br;

.mbs-alert-container {
  position: relative;
  z-index: v.$zindex-alert;
  padding-top:5px;
}

//
// Base styles
//

.alert {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: v.$alert-margin-bottom;
  padding: v.$alert-padding-y v.$alert-padding-x;
  border: v.$alert-border-width solid transparent;
  font-size: v.$alert-font-size;
  line-height: v.$alert-line-height;
  @include br.border-radius(v.$alert-border-radius);

  // Going to be deprecated
  &_ico {
    font-size: v.$alert-ico-size;
  }

  // mbs-alert component styles
  &-content {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    word-break: break-word;
  }

  &-icon {
    position: relative;
    top: v.$alert-ico-offset-top;
    font-size: v.$alert-ico-size;

    &_wrapper {
      display: flex;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding-right: v.$alert-content-inner-padding-x;
      font-size: v.$alert-ico-size;
    }
  }

  &-close {
    position: relative;
    top: v.$alert-close-offset-top;

    &_wrapper {
      display: flex;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      padding-left: v.$alert-content-inner-padding-x;
    }
  }

  // Sizing
  &-sm {
    padding: v.$alert-padding-y-sm v.$alert-padding-x-sm;
    font-size: v.$alert-font-size-sm;
    line-height: v.$alert-line-height-sm;

    .alert {
      &-icon {
        top: v.$alert-ico-offset-top-sm;
        font-size: v.$alert-ico-size-sm;

        &_wrapper {
          font-size: v.$alert-ico-size-sm;
        }
      }

      &-close {
        top: v.$alert-close-offset-top-sm;
      }
    }
  }

  &-lg {
    padding: v.$alert-padding-y-lg v.$alert-padding-x-lg;
    font-size: v.$alert-font-size-lg;
    line-height: v.$alert-line-height-lg;

    .alert {
      &-icon {
        top: v.$alert-ico-offset-top-lg;
        font-size: v.$alert-ico-size-lg;

        &_wrapper {
          font-size: v.$alert-ico-size-lg;
        }
      }

      &-close {
        top: v.$alert-close-offset-top-lg;
      }
    }
  }

  // Positioning
  &.position-sticky,
  &.position-fixed {
    @include br.border-radius(0);
  }
  &.content-center {
    justify-content: space-between;
    .alert-content{
      flex-basis: auto;
      flex-grow: 0;
    }
    .alert-icon_wrapper, .alert-close_wrapper{
      margin-left: auto;
    }
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing v.$headings-color
  color: inherit;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  padding-right: v.$close-font-size + v.$alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: v.$alert-padding-y v.$alert-padding-x;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in v.$theme-colors {
  .alert-#{$color} {
    @include alert.alert-variant($value);
  }
}
