@use '../../basic/variables' as v;

[class^='i-'] {
  display: inline-block;
  vertical-align: middle;
}

.i {
  &-OS {
    font-size: v.$icon-os-size;
  }
  &-Status {
    font-size: v.$icon-status-size;
  }
  &-User {
    font-size: v.$icon-user-size;
  }
  &-Google {
    display: inline-block;
    background-size: contain;
    vertical-align: top;
  }

  // Types
  &.-inline {
    display: inline-block;
  }
  &.-block {
    display: block;
  }

  // Vertical alignment
  &.-top {
    vertical-align: top;
  }
  &.-middle {
    vertical-align: middle;
  }
  &.-bottom {
    vertical-align: bottom;
  }
}
