@use '../../../basic/variables' as v;
@use '../../../basic/mixins' as m;
@use '../../../basic/mixins/border-radius' as br;
@use 'variables' as cv;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

@import 'placeholders';

$componentSelector: mbs-datepicker;
$innerSelector: ngb-dp;

.#{$componentSelector} {
  &_dropdown {
    padding: cv.$datepicker-dropdown-padding-y cv.$datepicker-dropdown-padding-x;
  }

  &_datepicker {
    border: 0;
    border-radius: 0;

    .#{$innerSelector} {
      &-header {
        padding: cv.$datepicker-header-padding-y cv.$datepicker-header-padding-x;
        border-radius: 0;
        background: transparent;

        ngb-datepicker-navigation {
          gap: cv.$datepicker-navigation-gap;

          .#{$innerSelector} {
            &-arrow {
              align-items: stretch;
              width: cv.$datepicker-navigation-arrow-width;
              height: cv.$datepicker-navigation-arrow-height;

              .btn.btn-link {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                @include m.margin(0);
                @extend .ctrl;
                @extend .ctrl-primary;

                &:before {
                  @include m.icomoon();
                  content: i.$ico-arrLeft;
                }

                .#{$innerSelector}-navigation-chevron {
                  display: none;
                }
              }

              &.right {
                .btn.btn-link {
                  &:before {
                    content: i.$ico-arrRight;
                  }
                }
              }
            }

            &-navigation {
              &-select {
                flex-basis: cv.$datepicker-navigation-select-width;
                gap: cv.$datepicker-navigation-select-gap;

                .custom-select {
                  @extend .form-control;
                  @extend .form-control-xs;
                }
              }
            }
          }
        }
      }

      &-content {
        border-top: cv.$datepicker-border-width solid cv.$datepicker-border-color;
      }

      &-month {
        &:first-child,
        &:last-child {
          .#{$innerSelector}-week {
            @include m.padding-y(cv.$datepicker-weekdays-padding-y);
            @include m.padding-x(cv.$datepicker-weekdays-padding-x);
          }
        }
      }

      &-weekdays {
        border-bottom: 0;
        background: cv.$datepicker-weekdays-bg;
      }

      &-weekday {
        @extend %dp-cell;
        color: cv.$datepicker-weekday-color;
        font-size: cv.$datepicker-weekday-font-size;
        font-style: normal;
      }

      &-day {
        @extend %dp-cell;

        .#{$componentSelector}_datepicker-day {
          @extend %dp-cell;
          width: 100%;
          height: 100%;
          color: cv.$datepicker-item-color;
          font-size: cv.$datepicker-item-font-size;

          &.-weekend {
            background: cv.$datepicker-day-weekend-bg;
            color: cv.$datepicker-day-weekend-color;
          }

          &.-outside {
            background: cv.$datepicker-item-outside-bg;
            color: cv.$datepicker-item-outside-color;
          }

          &.-today {
            background: cv.$datepicker-item-current-bg;
            color: cv.$datepicker-item-current-color;
          }

          &.-selected {
            background: cv.$datepicker-item-selected-bg;
            color: cv.$datepicker-item-selected-color;
          }

          &.-focused {
            border: cv.$datepicker-item-focus-border-width solid cv.$datepicker-item-focus-border-color;
          }
          // range styles
          &.focused {
            background-color: cv.$datepicker-item-focus-border-width solid cv.$datepicker-item-focus-border-color;
          }
          &.range,
          &:hover {
            background-color: cv.$datepicker-item-selected-bg;
            color: cv.$datepicker-item-selected-color;
          }
          &.faded {
            background-color: cv.$datepicker-item-faded-bg;
          }
        }

        &.disabled,
        &.-disabled,
        &:disabled {
          @extend %disabled-element;

          .#{$componentSelector}_datepicker-day {
            background: cv.$datepicker-item-disabled-bg;
            color: cv.$datepicker-item-disabled-color;
          }
        }

        &:hover {
          &:not(.-disabled) {
            .#{$componentSelector}_datepicker-day {
              background: cv.$datepicker-item-hover-bg;
              color: cv.$datepicker-item-hover-color;
            }
          }
        }
      }
    }

    &-months {
      display: grid;
      grid-auto-rows: cv.$datepicker-month-height;
      grid-template-columns: repeat(cv.$datepicker-months-grid-colums, cv.$datepicker-month-width);
    }

    &-month {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: cv.$datepicker-item-color;
      font-size: cv.$datepicker-item-font-size;

      &.-current {
        background: cv.$datepicker-item-current-bg;
        color: cv.$datepicker-item-current-color;
      }

      &.-selected {
        background: cv.$datepicker-item-selected-bg;
        color: cv.$datepicker-item-selected-color;
      }

      &.-disabled,
      &:disabled {
        @extend %disabled-element;
        background: cv.$datepicker-item-disabled-bg;
        color: cv.$datepicker-item-disabled-color;
      }

      &:hover {
        background: cv.$datepicker-item-hover-bg;
        color: cv.$datepicker-item-hover-color;
      }
    }

    &-footer {
      @include m.padding-y(cv.$datepicker-footer-padding-y);
      @include m.padding-x(cv.$datepicker-footer-padding-x);
    }

    &.-year {
      .#{$innerSelector} {
        &-arrow {
          display: none;
        }

        &-navigation {
          &-select {
            .custom-select {
              &:first-child {
                display: none;
              }
            }
          }
        }
      }
    }

    &.-plain {
      padding: cv.$datepicker-plain-padding-y cv.$datepicker-plain-padding-x;
      border: cv.$datepicker-border-width solid cv.$datepicker-border-color;
      @include br.border-bottom-radius(cv.$datepicker-plain-border-radius);
    }
  }
}
