@use '../../../basic/variables' as v;
@use '../../../basic/mixins/border-radius' as br;

@mixin form-control-sizes(
  $size,
  $input-height,
  $input-padding-y,
  $input-padding-x,
  $input-font-size,
  $input-line-height,
  $input-border-radius
) {
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  line-height: $input-line-height;
  @include br.border-radius($input-border-radius, 0);

  &.-clearable {
    padding-right: $input-height;
  }
}

@each $size, $input-height, $input-padding-y, $input-padding-x, $input-font-size, $input-line-height, $input-border-radius in $input-sizes {
  .form-control-#{$size} {
    @include form-control-sizes(
      $size,
      $input-height,
      $input-padding-y,
      $input-padding-x,
      $input-font-size,
      $input-line-height,
      $input-border-radius
    );
  }

  .form-control_clear.-#{$size} {
    width: $input-height;
    height: $input-height;
  }

  .form-control_loader.-#{$size} {
    width: $input-height;
    height: $input-height;
  }
}
