@use '../../basic/mixins/buttons' as btn-m;
@use '../../basic/variables' as v;
@use '../../basic/functions' as f;
@use '../../basic/mixins/hover' as h;
@use '../../basic/mixins/gradients' as gr;

@mixin tag-variant(
  $background,
  $border: darken($background, 10%),
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%)
) {
  border-color: $border;
  background: $background;
  color: f.color-contrast($background);

  &.-clickable:not(.-disabled) {
    @include h.hover {
      border-color: $hover-border;
      background: $hover-background;
      color: f.color-contrast($hover-background);
    }
  }
  &.-no-border {
    border-color: rgba(0, 0, 0, 0);
  }
}

.mbs-tag {
  display: inline-flex;
  align-items: center;
  gap: v.$tag-gap;
  justify-content: space-between;
  height: v.$tag-height;
  border: v.$tag-border-width solid transparent;
  color: v.$body-color;
  font-family: v.$tag-font-family;
  font-weight: v.$tag-font-weight;
  text-align: center;
  vertical-align: middle;
  cursor: default;
  user-select: none;
  @include btn-m.button-size(v.$tag-padding-y, v.$tag-padding-x, v.$tag-font-size, v.$tag-line-height, v.$tag-border-radius);
  @include tag-variant(v.$secondary);

  &_content {
    .-closable & {
      max-width: calc(100% - #{v.$tag-padding-right} - #{v.$tag-gap});
    }
  }

  &.-closable {
    padding-right: v.$tag-padding-right;
  }

  &.-active.-primary:not(.-disabled) {
    @include tag-variant(v.$primary);
  }

  &.-disabled {
    @extend %disabled-element;
  }

  .ctrl {
    margin: 0;
  }

  &.-clickable:not(.-disabled) {
    cursor: pointer;

    @include hover {
      color: v.$body-color;
      text-decoration: none;
    }
  }

  &.-sm {
    height: v.$tag-height-sm;
    @include btn-m.button-size(v.$tag-padding-y, v.$tag-padding-x, v.$tag-font-size-sm, v.$tag-line-height, v.$tag-border-radius);
    gap: v.$tag-gap-sm;

    &.-closable {
      padding-right: v.$tag-padding-right-sm;
    }
  }

  &.-xs {
    height: v.$tag-height-xs;
    @include btn-m.button-size(v.$tag-padding-y, v.$tag-padding-x-sm, v.$tag-font-size-sm, v.$tag-line-height-xs, v.$tag-border-radius);
    gap: v.$tag-gap-sm;

    &.-closable {
      padding-right: v.$tag-padding-right-xs;
    }
  }

  &_close-btn {
    color: v.$gray-900;
  }
}

// Make a tag look and behave like a link
.tag.tag-link.-clickable {
  @extend .btn-link;
}
