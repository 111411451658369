@use '../../../basic/variables' as v;

$timepicker-dropdown-padding-y: 12px;
$timepicker-dropdown-padding-x: 15px;

$timepicker-input-font-size: 18px;
$timepicker-input-font-weight: v.$font-weight-semibold;
$timepicker-input-container-width: 40px;
$timepicker-input-height: 40px;
$timepicker-input-btn-height: 30px;

$timepicker-spacer-widht: 18px;
$timepicker-spacer-font-size: $timepicker-input-font-size;
$timepicker-spacer-font-weight: v.$font-weight-base;

$timepicker-meridian-btn-height: $timepicker-input-height;
