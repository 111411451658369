@use '../../../basic/variables' as v;

$componentSelector: mbs-input-range;

.#{$componentSelector} {
  padding: 0;
  overflow: hidden;

  &.focus {
    box-shadow: v.$input-focus-box-shadow;
  }

  .input-group {
    input {
      border: none;
      border-radius: 0;
      z-index: 0 !important;
    }

    .input-group_control {
      width: fit-content;
      padding: 0;
      overflow: hidden;

      .form-control {
        box-shadow: none;
      }

      > mbs-input {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
