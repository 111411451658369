@use '../../basic/variables' as v;

.mbs-header {
  position: fixed;
  z-index: v.$zindex-fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-width: v.$min-width;
}
