@use '../variables' as v;
@use 'deprecate' as d;

/// Grid system
//
// Generate semantic grid columns with these m.

@mixin make-container($gutter: v.$grid-gutter-width) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: $gutter * 0.5;
  padding-left: $gutter * 0.5;
}

@mixin make-row($gutter: v.$grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter * 0.5;
  margin-left: -$gutter * 0.5;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: v.$container-max-widths, $breakpoints: v.$grid-breakpoints) {
  @each $breakpoint, $container-max-width in v.$max-widths {
    @include media-breakpoint-up($breakpoint, v.$breakpoints) {
      max-width: v.$container-max-width;
    }
  }
  @include d.deprecate('The `make-container-max-widths` mixin', 'v4.5.2', 'v5');
}

@mixin make-col-ready($gutter: v.$grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $gutter * 0.5;
  padding-left: $gutter * 0.5;
}

@mixin make-col($size, $columns: v.$grid-columns) {
  flex: 0 0 percentage(divide($size, $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(divide($size, $columns));
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: v.$grid-columns) {
  $num: divide($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 divide(100%, $count);
    max-width: divide(100%, $count);
  }
}
