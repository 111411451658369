@use '../variables' as v;

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: v.$grid-columns, $gutter: v.$grid-gutter-width) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
  }

  $grid-list: (
    'grid': (
      'breakpoints': v.$grid-breakpoints,
      'suffix': ''
    ),
    'navbar': (
      'breakpoints': v.$navbar-breakpoints,
      'suffix': '-nav'
    ),
    'navbar-apps': (
      'breakpoints': v.$navbar-apps-breakpoints,
      'suffix': '-nav-apps'
    )
  );

  @each $grid-name, $grid in $grid-list {

    $breakpoints: map-get($grid, 'breakpoints');
    $suffix: map-get($grid, 'suffix');

    @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      @if $breakpoint != 'lg' or $suffix != '-nav' {
        @if $columns > 0 {
          // Allow columns to stretch full width below their breakpoints
          @for $i from 1 through $columns {
            .col#{$suffix}#{$infix}-#{$i} {
              @extend %grid-column;
            }
          }
        }

        .col#{$suffix}#{$infix},
        .col#{$suffix}#{$infix}-auto {
          @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
          // Provide basic `.col-{bp}` classes for equal-width flexbox columns
          .col#{$suffix}#{$infix} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
          }

          @if $grid-row-columns > 0 {
            @for $i from 1 through $grid-row-columns {
              .row-cols#{$suffix}#{$infix}-#{$i} {
                @include row-cols($i);
              }
            }
          }

          .col#{$suffix}#{$infix}-auto {
            @include make-col-auto();
          }

          @if $columns > 0 {
            @for $i from 1 through $columns {
              .col#{$suffix}#{$infix}-#{$i} {
                @include make-col($i, $columns);
              }
            }
          }

          .order#{$suffix}#{$infix}-first {
            order: -1;
          }

          .order#{$suffix}#{$infix}-last {
            order: $columns + 1;
          }

          @for $i from 0 through $columns {
            .order#{$suffix}#{$infix}-#{$i} {
              order: $i;
            }
          }

          @if $columns > 0 {
            // `$columns - 1` because offsetting by the width of an entire row isn't possible
            @for $i from 0 to $columns {
              @if not($infix == '' and $i == 0) {
                // Avoid emitting useless .offset-0
                .offset#{$suffix}#{$infix}-#{$i} {
                  @include make-col-offset($i, $columns);
                }
              }
            }
          }
        }
      }
    }
  }
}
