@use '../../basic/variables' as v;

.mbs-form_card {
  padding: v.$form-card-padding-y v.$form-card-padding-x;
  border: v.$form-card-border-width solid v.$form-card-border-color;
  border-radius: v.$form-card-border-radius;

  &-title {
    display: flex;
    align-items: center;

    &_ico {
      margin-right: v.$form-card-header-ico-margin-right;
      font-size: v.$form-card-header-ico-size;
    }
  }

  &.-sm {
    padding: v.$form-card-padding-y-sm v.$form-card-padding-x-sm;

    .mbs-form_card {
      &-title {
        &_ico {
          margin-right: v.$form-card-header-ico-margin-right-sm;
          font-size: v.$form-card-header-ico-size-sm;
        }
      }
    }
  }

  &.-disabled {
    @extend %disabled-element;
    color: v.$disabled-color;
  }
}
