@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;
@use '../../basic/mixins/forms' as forms;

.mbs-smart-search {
  &_dropdown {
    width: calc(100% + (2 * #{v.$input-border-width}));
    margin-top: v.$input-border-width;
    @include m.margin-x(-1 * v.$input-border-width);
    padding: v.$advanced-search-dropdown-padding-y v.$advanced-search-dropdown-padding-x;
    border: 0;
    @include br.border-top-radius(0);

    &-menu {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  &_content {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    @include m.padding-y(v.$input-padding-y);
    @include m.padding-x(v.$input-padding-x);

    > * {
      display: inline;
      white-space: nowrap;
    }

    &:focus {
      outline: none;
    }

    @each $size, $input-height, $input-padding-y, $input-padding-x, $input-font-size, $input-line-height,
      $input-border-radius in $input-sizes
    {
      &-#{$size} {
        @include m.padding-y($input-padding-y);
        @include m.padding-x($input-padding-x);
        line-height: $input-height - (2 * v.$input-border-width) - (2 * $input-padding-y);
      }
    }
  }

  &_placeholder {
    @include m.padding-y(v.$input-padding-y);
    @include m.padding-x(v.$input-padding-x);
    position: absolute;
    top: 0;
    left: 0;

    @each $size, $input-height, $input-padding-y, $input-padding-x, $input-font-size, $input-line-height,
      $input-border-radius in $input-sizes
    {
      &-#{$size} {
        @include m.padding-y($input-padding-y);
        @include m.padding-x($input-padding-x);
        line-height: $input-height - (2 * v.$input-border-width) - (2 * $input-padding-y);
      }
    }
  }

  .form-control {
    display: flex;
    align-items: stretch;
    @include m.padding(0);
  }

  .loader {
    @extend %loader;
  }

  &-invalid {
    text-decoration: underline;
    text-decoration-color: v.$danger;
  }

  &-plain {
    color: v.$gray-900;
  }

  &-tag {
    font-weight: v.$font-weight-semibold;
  }

  &-field {
    color: v.$primary;
  }

  .autocomplete-item {
    cursor: pointer;

    &.-active,
    &:active {
      background: lighten(v.$primary, 43.6%) !important; // HEX: #c0d3e3
    }
  }
}
