@use 'sass:math';
@use '../../basic/variables' as v;
@use '../../basic/mixins/transition' as tr;
@use '../../basic/mixins/hover' as h;
@use 'mixins' as btn-m;

$componentSelector: mbs-button;

.btn {
  display: inline-flex;
  align-items: center;
  border: v.$btn-border-width solid transparent;
  background-color: transparent;
  box-shadow: none;
  color: v.$body-color;
  font-family: v.$btn-font-family;
  font-weight: v.$btn-font-weight;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  pointer-events: auto;
  user-select: none;
  @include btn-m.button-size(
    v.$btn-height,
    v.$btn-padding-y,
    v.$btn-padding-x,
    v.$btn-gap,
    v.$btn-font-size,
    v.$btn-line-height,
    v.$btn-border-radius
  );
  @include tr.transition(v.$btn-transition);

  @include h.hover-focus {
    color: v.$body-color;
    text-decoration: none;
  }

  &:before,
  &:after {
    pointer-events: inherit;
  }

  * {
    pointer-events: inherit;

    &:before,
    &:after {
      pointer-events: inherit;
    }
  }

  &.tooltip-always {
    pointer-events: auto;
  }

  &-ico {
    color: inherit;
    font-size: math.ceil(v.$btn-font-size * v.$btn-ico-size);
  }

  &.active {
    cursor: default;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: v.$btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @extend %disabled-element;
    opacity: v.$btn-disabled-opacity;
    box-shadow: none;

    @each $color, $value in v.$theme-colors {
      .text-#{$color} {
        color: mix($value, v.$white, v.$btn-disabled-percentage) !important;
      }
    }
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
    &:active,
    &.active {
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.-loading {
    position: relative;
    color: transparent;
    text-shadow: none;

    &:after {
      @extend %loader;
      content: '';
      position: absolute;
      top: calc(50% - (#{v.$loader-size} / 2));
      left: calc(50% - (#{v.$loader-size} / 2));
    }
  }

  &.btn-rounded {
    border-radius: v.$btn-height / 2;
  }

  &.btn-square {
    justify-content: center;
    width: v.$btn-height;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
  }
}

.dashed {
  background: none;
  border: none;
  padding-right: 0;
  padding-left: 0;
  pointer-events: auto;
  border-bottom: 1px dashed;

  &-muted {
    border-bottom-color: 1px dashed v.$text-muted;
    color: v.$text-muted;

    @include hover {
      color: darken(v.$text-muted, 10%);
      border-bottom-color: darken(v.$text-muted, 10%);
    }
  }
}

@import 'sizes';
@import 'flat';
@import 'outline';
@import 'link';

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  & + & {
    margin-top: v.$btn-block-spacing-y;
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

#{$componentSelector} {
  display: inline-flex;
  pointer-events: none;

  &:before,
  &:after {
    pointer-events: inherit;
  }

  &.-isBlock {
    display: block;
    width: 100%;

    & + & {
      margin-top: v.$btn-block-spacing-y;
    }
  }

  &.-disabled {
    @extend %disabled-element;
  }
}

#{$componentSelector}.dropdown-item {
  display: block;
  width: auto;
}
