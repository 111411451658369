@use '../../basic/variables' as v;

.ctrl-link {
  color: v.$link-color;
  font-weight: v.$font-weight-normal;
  text-decoration: v.$link-decoration;
  vertical-align: baseline;
  user-select: auto;

  @include hover-focus {
    outline: none;
    box-shadow: none;
    color: v.$link-hover-color;
    text-decoration: v.$link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: v.$link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    @extend %disabled-element;
    color: v.$ctrl-link-disabled-color;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      box-shadow: none;

      &:hover,
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }
  }

  &.-loading {
    color: transparent !important;
    &:after {
      border-color: v.$loader-border-color;
      border-left-color: v.$loader-border-accent-color;
    }

    * {
      color: transparent !important;
    }
  }

  // No need for an active state here
}
