@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

.ng-select-disabled {
  .ng-select.mbs-select__ng-select {
    .ng-select-container {
      background-color: v.$light;
      cursor: default;
    }
  }
}
