@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.gapps-calendar-attachment {
  &-item {
    padding-bottom: cv.$calendar-attachment-padding-bottom;

    &_ico {
      color: cv.$calendar-attachment-ico-color;
      font-size: v.$font-size-md;
      line-height: cv.$calendar-attachment-ico-line-height;
    }

    &_link {
      font-size: cv.$calendar-font-size-xs;
      line-height: cv.$calendar-attachment-link-line-height;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
