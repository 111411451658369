@use '../../basic/variables' as v;

.dashed {
  background: none;
  border: none;
  padding-right: 0;
  padding-left: 0;
  pointer-events: auto;
  border-bottom: 1px dashed;

  &-muted {
    border-bottom-color: v.$text-muted;
    color: v.$text-muted;

    @include hover {
      color: darken(v.$text-muted, 10%);
      border-bottom-color: darken(v.$text-muted, 10%);
    }
  }
}

@import 'sizes';
