@import 'node_modules/mbs-ui-kit/styles/basic/variables';
@import 'node_modules/mbs-ui-kit/styles/basic/mixins';

/* You can add global styles to this file, and also import other style files */
@import 'node_modules/mbs-fonts/fonts';

// Bootstrap and Shared components
@import 'node_modules/mbs-ui-kit/styles';

// Project styles
@import 'projects/apps-ui/src/app/common/styles/styles';
