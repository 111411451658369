@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-toast;

.mbs-toast {
  max-width: v.$toast-max-width;
  overflow: hidden; // cheap rounded corners on nested items
  @include m.padding-x(v.$toast-body-padding-x);
  @include m.padding-y(v.$toast-padding-y);
  border: v.$toast-border-width solid transparent;
  opacity: 0;
  background-clip: padding-box;
  box-shadow: v.$toast-box-shadow;
  color: v.$toast-color;
  backdrop-filter: blur(10px);
  font-size: v.$toast-font-size;
  @include br.border-radius(v.$toast-border-radius);
  line-height: v.$toast-line-height;

  &-header {
    display: flex;
    align-items: flex-start;
  }

  .toast {
    &-body {
      @include m.padding-x(0);
      @include m.padding-y(0);
      display: flex;

      > .mbs-toast-icon {
        display: flex;
        justify-content: flex-start;
        font-size: v.$toast-icon-font-size;
        padding-top: v.$toast-icon-padding-top;
        padding-left: v.$toast-icon-padding-left;
      }

      > .mbs-toast-content {
        flex-basis: 0;
        flex-grow: 1;
      }

      > .mbs-toast-close {
        display: flex;
        justify-content: flex-end;
        padding-top: v.$toast-close-padding-top;
        padding-right: v.$toast-close-padding-right;
      }
    }

    &-header {
      @include m.padding-y(0);
      align-items: flex-start;
      border: 0;
      background: transparent;
      font-size: v.$toast-header-font-size;
      font-weight: v.$toast-header-font-weight;
      line-height: v.$toast-header-line-height;

      strong {
        font-weight: v.$toast-header-font-weight;
      }
    }
  }

  &-title {
    font-size: v.$toast-header-font-size;
    font-weight: v.$toast-header-font-weight;
    line-height: v.$toast-header-line-height;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &-ico {
    font-size: inherit !important;
    line-height: inherit !important;
    color: v.$alert-color;
  }

  &:not(:last-child) {
    margin-bottom: v.$toast-gap-y;
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

// To rewrite default cross
ngb-toast {
  &.-noClose {
    .close {
      display: none;
    }
  }
}

@mixin toast-variant($color) {
  background: mix($color, v.$black, v.$toast-lighten-level);
  color: v.$white;
  box-shadow: inset 4px 0px 0px $color;
}

@each $color, $value in v.$theme-colors {
  .mbs-toast-#{$color} {
    @include toast-variant($value);
  }
}
