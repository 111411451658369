@use '../../basic/variables' as v;

$componentSelector: mbs-form-section;

// This block going to be deprecated due incorrect naming.
.mbs-form_section {
  padding-bottom: v.$form-section-padding-y;

  &-title {
    display: flex;
    align-items: baseline;
  }

  &-header {
    &_ico {
      margin-right: v.$form-section-header-ico-margin-right;
      font-size: v.$form-section-header-ico-size;
    }
  }

  & + & {
    padding-top: v.$form-section-padding-y;
    border-top: v.$form-section-border-width solid v.$form-section-border-color;
  }

  &-lg {
    padding-bottom: v.$form-section-padding-y-lg;

    & + & {
      padding-top: v.$form-section-padding-y-lg;
    }
  }

  &-sm {
    padding-bottom: v.$form-section-padding-y-sm;

    & + & {
      padding-top: v.$form-section-padding-y-sm;
    }
  }
}

// Actual styles for `mbs-form-section` component
.#{$componentSelector} {
  display: block;

  &_title {
    display: flex;
    align-items: baseline;
  }

  &_header {
    &-ico {
      margin-right: v.$form-section-header-ico-margin-right;
      font-size: v.$form-section-header-ico-size;
    }
  }

  &:not(:last-of-type) {
    padding-bottom: v.$form-section-padding-y;
  }

  & + & {
    padding-top: v.$form-section-padding-y;
    border-top: v.$form-section-border-width solid v.$form-section-border-color;
  }

  &.-lg {
    &:not(:last-of-type) {
      padding-bottom: v.$form-section-padding-y-lg;
    }

    & + & {
      padding-top: v.$form-section-padding-y-lg;
    }
  }

  &.-sm {
    &:not(:last-of-type) {
      padding-bottom: v.$form-section-padding-y-sm;
    }

    & + & {
      padding-top: v.$form-section-padding-y-sm;
    }
  }
}
