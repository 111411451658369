@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

.mbs-user_ico {
  position: relative;

  .fa-times {
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: calc(100% - 5px);
    color: v.$danger;
    font-size: 60%;
    pointer-events: none;
  }

  .fa-star {
    position: absolute;
    z-index: 1;
    bottom: -2px;
    left: calc(100% - 5px);
    color: v.$yellow;
    font-size: 60%;
    pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background-color: white;
      border-radius: 50%;
      z-index: -1
    }
  }
}
