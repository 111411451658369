@use "sass:math";
@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;
@use '../buttons/_mixins.scss' as btn-m;

.mbs-number {
  &-buttons {
    > mbs-button-group {
      height: 100%;

      .btn {
        @include btn-m.button-size(
          math.div(v.$btn-height, 2) + math.div(v.$btn-border-width, 2),
          math.div(v.$btn-padding-y, 2),
          math.div(v.$btn-padding-x, 2),
          math.div(v.$btn-gap, 2),
          math.div(v.$btn-font-size, 1.5),
          v.$btn-line-height,
          v.$btn-border-radius
        );
        @include br.border-left-radius(0);
      }

      @each $size, $height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius in v.$btn-sizes {
        .btn-#{$size} {
          @include btn-m.button-size(
            math.div($height, 2) + math.div(v.$btn-border-width, 2),
            math.div($padding-y, 2),
            math.div($padding-x, 2),
            math.div($gap, 2),
            math.div($font-size, 1.5),
            math.div($line-height, 1.5),
            $border-radius
          );
          @include br.border-left-radius(0);
        }
      }
    }
  }
}
