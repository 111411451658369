@use 'variables' as cv;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins' as m;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/border-radius' as br;

@each $size, $height, $padding-y, $padding-x, $font-size, $line-height, $border-radius, $padding-right in cv.$select-sizes {
  .ng-select-#{$size} {
    .ng-select-container {
      height: $height;
      @include m.padding-x($padding-x);
      @include br.border-radius($border-radius);
      font-size: $font-size;
      line-height: $line-height;
    }

    &.ng-select-single {
      .ng-select-container {
        height: $height;
        .ng-value-container {
          .ng-placeholder {
            line-height: $height - (2 * cv.$select-border-width);
          }

          .ng-input {
            @include m.padding-y($padding-y);
            padding-right: $padding-right;
            padding-left: $padding-x;
          }
        }
      }

      &.ng-select-clearable {
        .ng-select-container {
          .ng-value-container {
            .ng-input {
              padding-right: 2 * $padding-right;
            }
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            max-width: calc(100% - (2 * #{$padding-right}));
            line-height: $height - (2 * cv.$select-border-width);
          }

          .ng-input {
            @include m.padding-y($padding-y);
          }
        }
      }
    }
  }
}
