@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

.input-group > .input-group-prepend > mbs-button > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > mbs-button > .btn,
.input-group:not(.has-validation) > .input-group-append > mbs-button:not(:last-child) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > mbs-button > .btn {
  @include br.border-right-radius(0);
}

.input-group > .input-group-append > mbs-button > .btn,
.input-group > .input-group-prepend:not(:first-child) > mbs-button > .btn,
.input-group > .input-group-prepend > mbs-button:not(:first-child) > .btn,
.input-group > .input-group-prepend:first-child > mbs-button:not(:first-child) > .btn {
  @include br.border-left-radius(0);
}

.input-group {
  > .form-control {
    &:focus,
    &.ng-touched.ng-dirty {
      z-index: 10;
    }
  }
}

.mbs-form {
  &_input-group {
    padding-top: v.$input-border-width;

    .form-control {
      position: relative;
      z-index: 1;
      margin-top: -1 * v.$input-border-width;
      border-radius: 0;

      &:first-child {
        @include br.border-left-radius(v.$input-border-radius);
      }

      &:last-child {
        @include br.border-right-radius(v.$input-border-radius);
      }

      &:focus,
      &:active,
      &.ng-invalid.ng-touched,
      &.is-invalid.ng-touched,
      &.ng-invalid.is-touched,
      &.is-invalid.is-touched {
        z-index: 2;
      }

      @each $size, $height, $padding-y, $padding-x, $font-size, $line-height, $border-radius in v.$input-sizes {
        &-#{$size} {
          &:first-child {
            @include br.border-left-radius($border-radius);
          }
          &:last-child {
            @include br.border-right-radius($border-radius);
          }
        }
      }
    }
  }
}

.input-group > .form-control.is-invalid {
  z-index: 3;
}
