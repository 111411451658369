@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.mbs-page {
  min-height: cv.$page-min-height;

  &_content {
    padding-top: cv.$page-content-padding-top;
    padding-bottom: cv.$page-content-padding-bottom;
  }
}

.text-decoration-underline-hover:hover {
  text-decoration: underline;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 1s infinite linear;
}
/** Reset Css **/
form {
  margin-bottom: 0;
}
.navbar.mbs-navbar .navbar-nav {
  align-items: stretch;
}
