@use '../../basic/variables' as v;

.loader {
  @extend %loader;
  display: inline-block;
  border-width: v.$loader-border-width;
  border-style: solid;
  border-color: v.$loader-border-color;
  border-left-color: v.$loader-border-accent-color;
  vertical-align: middle;

  &-xxs {
    @extend %loader-xxs;
  }

  &-sm {
    @extend %loader-sm;
  }

  &-lg {
    @extend %loader-lg;
  }
}

@mixin loader-variant($color) {
  border-color: transparentize($color, 0.4);
  border-left-color: $color;
}

@each $color, $value in v.$theme-colors {
  .loader-#{$color} {
    @include loader-variant($value);
  }
}
