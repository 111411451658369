@use '../../basic/variables' as v;
@use '../../basic/mixins/transition' as tr;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

.table {
  th,
  td {
    font-size: v.$table-font-size;
    vertical-align: middle;

    .ctrl {
      font-size: inherit;
    }
  }
  thead th {
    border-bottom: 1px solid v.$table-border-color;
    vertical-align: middle;
  }

  th[sortable] {
    cursor: pointer;
    @include tr.transition(v.$ctrl-transition);
    &:hover {
      color: v.$primary;
    }
  }

  th[sortable]:after {
    content: i.$ico-arrowDownSm;
    display: inline-block;
    visibility: hidden;
    font-family: i.$icomoon-font-family;
  }

  th[sortable].desc:after,
  th[sortable].asc:after {
    visibility: visible;
  }

  th[sortable].asc:after {
    content: i.$ico-arrowUpSm;
  }

  &.disabled {
    opacity: v.$table-disabled-opacity;
    box-shadow: none;
    color: v.$table-disabled-color;
    pointer-events: none;
  }

  &.small {
    th,
    td {
      font-size: v.$table-font-size-sm;
    }
  }

  &.large {
    th,
    td {
      font-size: v.$table-font-size-lg;
    }
  }
}
