@use 'sass:math';
@use '../../../basic/variables' as v;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-checkbox;

#{$componentSelector} {
  @extend %check-radio;

  .#{$componentSelector} {
    @extend %check-radio-container;

    &_input {
      @extend %check-radio-input-hidden;

      + .#{$componentSelector}_label {
        @extend %check-radio-label;

        &:before {
          @extend %check-radio-input;
          @extend %check-input;
        }
      }

      &:disabled {
        + .#{$componentSelector}_label {
          &:before {
            @extend %check-input-disabled;
          }
        }
      }

      &:checked {
        + .#{$componentSelector}_label {
          &:before {
            @extend %check-input-accented;
            content: i.$ico-ok;
          }
        }

        &:disabled {
          + .#{$componentSelector}_label {
            &:before {
              @extend %check-input-accented-disabled;
            }
          }
        }
      }

      &:indeterminate {
        + .#{$componentSelector}_label {
          &:before {
            @extend %check-input-accented;
            content: i.$ico-Minus;
          }
        }

        &:disabled {
          + .#{$componentSelector}_label {
            &:before {
              @extend %check-input-accented-disabled;
            }
          }
        }
      }

      &:focus {
        + .#{$componentSelector}_label {
          &:before {
            box-shadow: v.$check-radio-focus-box-shadow;
          }
        }
      }
    }

    &_label {
      &-content {
        @extend %check-radio-label-content;
      }
    }

    &_subtitle {
      @extend %check-radio-subtitle-description;
      @extend %check-radio-subtitle;
    }

    &_description {
      @extend %check-radio-subtitle-description;
    }
  }
}

.-pin-mode {
  .#{$componentSelector} {
    &_input {
      + .#{$componentSelector}_label {
        &:before {
          transform: rotate(50deg);
          font-family: FontAwesome !important;
          border: none;
          content: "\f08d";
        }
      }

      &:disabled {
        + .#{$componentSelector}_label {
          &:before {
            font-family: FontAwesome !important;
            border: none;
            content: "\f08d";
            color: v.$check-radio-label-disabled-color;
          }
        }
      }

      &:checked {
        + .#{$componentSelector}_label {
          &:before {
            transform: none;
            border: none;
            background: none;
            content: "\f08d";
            color: v.$check-radio-accent-color-primary;
            font-size: inherit;
          }
        }

        &:disabled {
          + .#{$componentSelector}_label {
            &:before {
              font-family: FontAwesome !important;
              border: none;
              content: "\f08d";
              color: v.$check-radio-label-disabled-color;
            }
          }
        }
      }


      &:focus {
        + .#{$componentSelector}_label {
          &:before {
            font-family: FontAwesome !important;
            border: none;
            content: "\f08d";
          }
        }
      }
    }
  }
}

@mixin check-sizes($size, $border-radius, $ico-size) {
  &:before {
    border-radius: $border-radius;
    font-size: $ico-size;
  }
}

// Sizes
@each $size, $input-size, $min-height, $gap, $offset-top in v.$check-radio-sizes {
  .#{$componentSelector}.-#{$size} {
    min-height: $min-height;

    .#{$componentSelector} {
      &_input {
        + .#{$componentSelector}_label {
          gap: $gap;
          @include check-radio-sizes($size, $input-size, $min-height, $gap, $offset-top);
        }
      }

      &_label {
        &-content {
          @extend %check-radio-label-content-sizes;
        }
      }
    }
  }
}
@each $size, $border-radius, $ico-size in v.$check-sizes {
  .#{$componentSelector}.-#{$size} {
    .#{$componentSelector}_input {
      + .#{$componentSelector}_label {
        @include check-sizes($size, $border-radius, $ico-size);
      }
    }
  }
}
