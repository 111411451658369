@use 'sass:math';

@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Utilities
//
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid, inline-grid, subgrid;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;
$min-width: 360px;
//
// End of Utilies
//

//
// Color system
//
$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #f5f5f5;
$gray-300: #e3e3e3;
$gray-400: #cfcfcf;
$gray-500: #b5b5b5;
$gray-600: #757575;
$gray-700: #555;
$gray-800: #424242;
$gray-900: #252525;
$black: #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900
  ),
  $grays
);

$blue: #2d6ca2;
$indigo: #6610f2;
$purple: #6969fc;
$pink: #f76c88;
$red: #ea321f;
$orange: #ff9214;
$yellow: #ffc014;
$green: #14b844;
$teal: #0ac29d;
$cyan: #0aa9c2;
$blue-dark: #12429d;
$blue-light: #60a5fa;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'black': $black,
    'white': $white,
    'gray-light': $gray-400,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'blue-dark': $blue-dark,
    'blue-light': $blue-light
  ),
  $colors
);

$primary: $blue;
$secondary: $gray-200;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$medium: $gray-400;
$dark: $gray-800;
$brand: $blue-dark;
$primary-light: $blue-light;
$text-muted: $gray-600;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'medium': $medium,
    'dark': $dark,
    'brand': $brand,
    'primary-light': $primary-light
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 170;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;
//
// End of Color system
//

// Disabled elements
$disabled-opacity: 0.6;
$disabled-cursor: default;
$disabled-color: $gray-500;
$disabled-percentage: $disabled-opacity * 100%;
// End of Disabled elements

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: false;
$enable-deprecation-messages: true;
//
// End of Options
//

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 14px;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);
//
// End of Spacing
//

//
// Sizes
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);
//
// End of Sizes
//

// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-900;
//
// End of Body
//

// Links
//
// Style anchor elements.
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, $emphasized-link-hover-darken-percentage);
$link-hover-decoration: none;
//
// End of Links
//

//
// Grid system
//
// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1920px
);

// Navbar breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$navbar-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 1062px,
  xl: 1325px,
  xxl: 1920px
);
$navbar-button_width: 40;
$navbar-button_right_padding: 14;

// Navbar Apps breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$navbar-apps-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 1090px,
  xl: 1260px,
  xxl: 1920px
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-gutter-half: math.div($grid-gutter-width, 2);
$gutter: $grid-gutter-half;
//
// End of Grid system
//

// Printing
$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, 'lg');
// End of Printing

//
// Common variables (not related to definied component)
//
// Border
$border-width: 1px;
$border-color: $gray-300;
$border-radius: 4px;
$border-radius-sm: 3px;
$border-radius-md: 5px;
$border-radius-lg: 6px;
$border-radius-xl: 7px;
// End of Border

// Box-shadow
$box-shadow-sm: 0 2px 3px rgba($black, 0.075);
$box-shadow: 0 7px 14px rgba($black, 0.15);
$box-shadow-lg: 0 14px 42px rgba($black, 0.175);
// End of Box-shadow

// Common components styles
$component-active-color: $white;
$component-active-bg: $primary;
// End of Common components styles

// Caret
$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;
// End of Caret

// Transitions
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease-in-out, max-height 0.35s ease-in-out;
// End of Transitions

// Aspect ratios
$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(((21 9), (16 9), (4 3), (1 1)), $embed-responsive-aspect-ratios);
// End of Aspect ratios

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-sticky-element: 100;
$zindex-dropdown-container: 900;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-dropdown-over-sticky: 1025;
$zindex-fixed: 1030;
$zindex-alert: 1035;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
// End of Z-index master list
//
// End of Common variables
//

//
// Typography
//
// Font
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Open Sans', 'Noto Serif CS', 'Noto Serif JP', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$fonts: (
  sans-serif: $font-family-sans-serif,
  monospace: $font-family-monospace,
  base: $font-family-base
);

$font-size-base: 14px; // Set up for html "14px"
$font-size-xxs: 8.5px;
$font-size-xs: 11px;
$font-size-sm: 12px;
$font-size-md: 13px;
$font-size-lmd: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;
$font-size-xxl: 19px;

$font-sizes: (
  base: $font-size-base,
  xxs: $font-size-xxs,
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-md,
  lg: $font-size-lg,
  xl: $font-size-xl,
  xxl: $font-size-xxl
);

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-extrabold: 800;
$font-weight-base: $font-weight-normal;

$font-weights: (
  base: $font-weight-base,
  lighter: $font-weight-lighter,
  light: $font-weight-lighter,
  normal: $font-weight-normal,
  semibold: $font-weight-semibold,
  bold: $font-weight-bold,
  bolder: $font-weight-bolder,
  extrabold: $font-weight-extrabold
);
// End of Font

// Line-height
$line-height-base: 1.5;
$line-height-lg: 1.5;
$line-height-sm: 1.5;
// End of Line-height

// Headings (Style H1-H6 elements and .h1-.h6 classes)
$h1-font-size: 26px;
$h2-font-size: 24px;
$h3-font-size: 22px;
$h4-font-size: 18px;
$h5-font-size: 14px;
$h6-font-size: 13px;

$headings-margin-bottom: math.div($spacer, 2);
$headings-font-family: null;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-color: null;
$headings-color-disabled: $gray-600;

$h1-line-height: 1.54;
$h2-line-height: 1.54;
$h3-line-height: 1.59;
$h4-line-height: 1.67;
$h5-line-height: 1.79;
$h6-line-height: 1.55;

$h1-margin-top: 30px;
$h2-margin-top: $h1-margin-top;
$h3-margin-top: $h1-margin-top;
$h4-margin-top: $h1-margin-top;
$h5-margin-top: $h1-margin-top;
$h6-margin-top: $h1-margin-top;

$h1-margin-bottom: 16px;
$h2-margin-bottom: $h1-margin-bottom;
$h3-margin-bottom: $h1-margin-bottom;
$h4-margin-bottom: $h1-margin-bottom;
$h5-margin-bottom: $h1-margin-bottom;
$h6-margin-bottom: $h1-margin-bottom;

$h1-letter-spacing: 0.03em;
$h2-letter-spacing: 0.03em;
$h3-letter-spacing: 0.03em;
$h4-letter-spacing: 0.03em;
$h5-letter-spacing: 0.02em;
$h6-letter-spacing: 0.02em;

$headers: 'h1' $h1-font-size $h1-line-height $h1-margin-top $h1-margin-bottom $h1-letter-spacing,
  'h2' $h2-font-size $h2-line-height $h2-margin-top $h2-margin-bottom $h2-letter-spacing,
  'h3' $h3-font-size $h3-line-height $h3-margin-top $h3-margin-bottom $h3-letter-spacing,
  'h4' $h4-font-size $h4-line-height $h4-margin-top $h4-margin-bottom $h4-letter-spacing,
  'h5' $h5-font-size $h5-line-height $h5-margin-top $h5-margin-bottom $h5-letter-spacing,
  'h6' $h6-font-size $h6-line-height $h6-margin-top $h6-margin-bottom $h6-letter-spacing;

$display1-size: 84px;
$display2-size: 77px;
$display3-size: 63px;
$display4-size: 49px;

$display1-weight: $font-weight-light;
$display2-weight: $font-weight-light;
$display3-weight: $font-weight-light;
$display4-weight: $font-weight-light;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: $font-weight-light;
// End of Headings

// Paragraphs (Style p element.)
$paragraph-line-height: 1.79;
$paragraph-margin-bottom: 24px;
// End of Paragraphs

// Small (Style tag <small> and class .small)
$small-font-size: $font-size-xs;
$small-line-height: 1.64;
// End of Small

// Blockquote
$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;
// End of Blockquote

// HR
$hr-border-color: $border-color;
$hr-border-width: $border-width;
$hr-margin-y: $spacer;
// End of HR

// Mark
$mark-padding: 0.2em;
$mark-bg: $gray-100;
// End of Mark

// Code
$code-font-size: 87.5%;
$code-color: $pink;
// End of Code

// Descriptions and Definitions (DL, DT, DD elements)
$dt-font-weight: $font-weight-bold;
// End of Descriptions and Definitions

// Keyboard (KBD element)
$kbd-box-shadow: inset 0 -2px 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$kbd-padding-y: 3px;
$kbd-padding-x: 6px;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;
// End of Keyboard

// Preformatted text (PRE element)
$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;
// End of Preformatted text
//
// End of Typography
//

//
// ***
// Components
// ***
//

// Header (Main header)
$header-height: 41px;
$header-color: $gray-800;
// End of Header

// Mbs-loader (triple spinner)
$mbs-loader-color: $primary;
$mbs-loader-border: 3px solid transparent;
$mbs-loader-size: 150px;
$mbs-loader-size-sm: 75px;
$mbs-loader-size-xs: 35px;
$mbs-loader-1st-spinner-transparency: 0.7;
$mbs-loader-3rd-spinner-transparency: 0.5;
$mbs-loader-2nd-spinner-position: 5px;
$mbs-loader-3rd-spinner-position: 15px;
$mbs-loader-2nd-spinner-position-xs: 3px;
$mbs-loader-3rd-spinner-position-xs: 9px;
// End of Mbs-loader

// Icons parameters (going to be deprecated)
$icon-os-size: 22px;
$icon-status-size: 16px;
$icon-status-mx: 4px;
$icon-user-size: 22px;
// End of Icons parameters

//
// Tables
//
$table-font-size: $font-size-sm; // 12px
$table-font-size-lg: $font-size-base; // 14px
$table-font-size-sm: $font-size-xs; // 11px

$table-margin-bottom: 0;
$table-cell-padding-y: 3px;
$table-cell-padding-x: 10px;
$table-cell-padding: 3px 10px;
$table-cell-padding-sm: 4px;
$table-cell-card-header-padding-y: 7px;

$table-color: $gray-900;
$table-bg: $white;
$table-accent-bg: $gray-200;
$table-hover-color: $table-color;
$table-hover-bg: rgba($blue, 0.075);
$table-sticky-hover-bg: #f0f4f8;
$table-bordered-hover-cell-bg: rgba($blue, 0.085);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $border-color;
$table-invalid-border-color: $danger;

$table-card-head-bg: $gray-100;
$table-card-head-border-radius: $border-radius-xl;

$table-head-bg: $gray-200;
$table-head-color: $gray-900;
$table-head-font-weight: $font-weight-semibold;
$table-head-sortable-color-hover: $blue;

$table-dark-color: $white;
$table-dark-bg: $gray-800;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;

$table-striped-order: even;
$table-striped-bg: $gray-100;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

$table-sortable-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
$table-icon-size: 22px;
$table-icon-size-sm: 15px;
$table-loading-height: calc(#{$mbs-loader-size} * 1.42); // 1.42 is the diagonal coefficient
$table-disabled-opacity: $disabled-opacity;
$table-disabled-color: $disabled-color;

$table-line-height: 1.5;

// Table-grid
$table-grid-header-min-height: 30px;
$table-grid-row-min-height: 40px;
$table-grid-row-min-height-sm: unset;
$table-grid-row-selected-bg-color-proportion: 20%;
$table-grid-row-selected-hover-bg-color-proportion: 25%;
$table-grid-row-selected-border-color-proportion: 40%;
$table-grid-row-selected-bg: mix($primary, $white, $table-grid-row-selected-bg-color-proportion);
$table-grid-row-selected-hover-bg: mix($primary, $white, $table-grid-row-selected-hover-bg-color-proportion);
$table-grid-row-selected-border-color: mix($primary, $white, $table-grid-row-selected-border-color-proportion);

$table-grid-default-column-size-min: 100px;
$table-grid-default-column-size-max: 1fr;

$table-grid-controls-padding-x: 8px;
$table-grid-controls-padding-y: 10px;
// End of Table-grid

// Table-grid accordion mode
$table-grid-accordion-title-font-size: $font-size-base;
$table-grid-accordion-title-font-weight: $font-weight-semibold;
$table-grid-accordion-title-description-font-size: $font-size-xs;
$table-grid-accordion-title-description-line-height: 1.75;
// End of Table-grid accordion mode

// Table navigation
$table-nav-padding-y: 14px;
$table-nav-font-size: $font-size-md;
$table-nav-inner-height: 21px;
// End of Table navigation

// Table pagination
$table-pagination-link-height: $table-nav-inner-height;
$table-pagination-link-padding-x: 7px;
$table-pagination-link-font-size: $table-nav-font-size;
$table-pagination-arrow-font-size: $font-size-lmd;
$table-pagination-link-color: $primary;
$table-pagination-link-active-bg: $gray-100;
$table-pagination-link-active-color: $gray-900;
$table-pagination-link-disabled-opacity: 0.4;
$table-pagination-button_size: 30px;
// End of Table pagination

// Table filter (uses Table navigation sizes)
$table-filter-height: $table-nav-inner-height;
$table-filter-item-font-size: $table-nav-font-size;
$table-filter-item-font-weight: $font-weight-semibold;
$table-filter-divider-height: 14px;
// End of Table filter

// Table / grids switcher
$table-switcher-pdx: math.div($gutter, 2);
$table-switcher-ctrl-size: $font-size-md;
$table-switcher-ctrl-color: $gray-400;
$table-switcher-ctrl-active-color: $blue;
// End of Table / grids switcher

// Table / toggler
$table-toggler-arrow-width-half: 5px;
$table-toggler-arrow-height: 5px;
$table-toggler-color: $dark;
$table-toggler-color-hover: darken($table-toggler-color, 15%); // Copied value to simulate .ctrl behavior;
// End of Table / toggler
//
// End of Tables
//

//
// Forms
//
// Drop File
$drop-file-bg: $gray-100;
$drop-file-bg-hover: $white;
$drop-file-bg-disabled: $white;
$drop-file-border-width: $border-width;
$drop-file-border-color: $success;
$drop-file-border-color-disabled: $border-color;
$drop-file-border-color-invalid: $danger;
$drop-file-color: $gray-600;
$drop-file-color-disabled: $gray-400;
$drop-file-blur: blur(12px);
$drop-file-gutter: 10px;
$drop-file-transition: all ease-in-out 0.3s;
$drop-file-transition-opacity: opacity ease-in-out 0.3s;
$drop-file-with-image-color: $blue;
$drop-file-without-image-color: $gray-700;
$drop-file-label-color: $primary;
$drop-file-label-font-size: $font-size-xs;
$drop-file-title-font-size: $font-size-md;
$drop-file-title-font-weight: $font-weight-semibold;
$drop-file-text-bg: rgba($white, 0.6);
$drop-file-icon-size: 22px;
$drop-file-icon-line-height: 1.36;
$drop-file-inner-text-horizontal-width: 50%;
$drop-file-image-opacity-disabled: 0.5;
// End of Drop File

// Label
$label-margin-bottom: 7px;
$label-margin-x: 7px;
$label-font-size: $font-size-base;
$label-font-weight: $font-weight-base;
$label-font-weight-accented: $font-weight-semibold;
$label-line-height: 1.5;
$label-color: $gray-900;
$label-disabled-color: $disabled-color;
$label-disabled-percentage: $disabled-percentage;
$label-required-color: $danger;

$label-font-size-sm: $font-size-sm;
// End of Label

// Form text
$form-text-margin-top: 4px;
$form-text-line-height: 1.75;
// End of Form text

// Form check
$form-check-input-gutter: 17.5px;
$form-check-input-margin-y: 4px;
$form-check-input-margin-x: 3px;

$form-check-inline-margin-x: 10.5px;
$form-check-inline-input-margin-x: 4px;
// End of Form check

// Form grid
$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 14px;
// End of Form grid

// Form content (going to be depricated)
$form-content-pdy: 30px;
$form-content-pdx: 21px;
// End of Form content

// Form section
$form-section-padding-y: 21px;
$form-section-padding-y-sm: 10px;
$form-section-padding-y-lg: 35px;
$form-section-border-width: 1px;
$form-section-border-color: $border-color;
$form-section-header-ico-size: 0.9em;
$form-section-header-ico-margin-right: 0.7em;
// End of Form-section

// Form Footer
$form-footer-border-width: $border-width;
$form-footer-border-color: $border-color;
$form-footer-bg: $white;
// End of Form Footer

// Form Group
$form-group-title-margin-top: 24px;
$form-group-title-margin-bottom: 14px;
// End of Form Group

// Form Subgroup (going to be depricated)
$form-subgroup-padding-left: 0;
$form-subgroup-padding-top: 7px;
// End of Form Subgroup

// Form Note (going to be depricated)
$form-note-ico-size: 16px;
$form-note-line-height: 1.6;
// End of Form Note

// Form Card
$form-card-padding-y: 25px;
$form-card-padding-x: 28px;
$form-card-padding-y-sm: 21px;
$form-card-padding-x-sm: 21px;
$form-card-border-width: 1px;
$form-card-border-color: $border-color;
$form-card-border-radius: $border-radius;
$form-card-header-ico-size: 20px;
$form-card-header-ico-margin-right: 10px;
$form-card-header-ico-size-sm: 17px;
$form-card-header-ico-margin-right-sm: 8px;
// End of Form Card

// Form Filter
$form-filter-bg: $gray-200;
$form-filter-border-width: $border-width;
$form-filter-border-color: $border-color;
$form-filter-pdy: 7px;
$form-filter-pdx: $gutter;
// End of Form Filter

// Form validation
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $font-size-xs;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: none;

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid
    )
  ),
  $form-validation-states
);
// End of Form validation
//
// End of Forms
//

//
// Form Controls
//

// Common Input and Button styles
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-height: 30px;
$input-btn-font-family: $font-family-base;
$input-btn-font-size: $font-size-md;
$input-btn-line-height: $line-height-base;
$input-btn-border-width: $border-width;
$input-btn-border-radius: $border-radius;
$input-btn-padding-y: math.floor(
  math.div(($input-btn-height - ($input-btn-font-size * $input-btn-line-height) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y > 0 {
  $input-btn-padding-y: $input-btn-padding-y;
} @else {
  $input-btn-padding-y: 0;
}
$input-btn-padding-x: 10px;

$input-btn-focus-width: 0;
$input-btn-focus-persentage: 0.3;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-persentage);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-height-xxs: 18px;
$input-btn-font-size-xxs: $font-size-xs;
$input-btn-line-height-xxs: 1.5;
$input-btn-border-radius-xxs: $border-radius-sm;
$input-btn-padding-y-xxs: math.floor(
  math.div(($input-btn-height-xxs - ($input-btn-font-size-xxs * $input-btn-line-height-xxs) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y-xxs > 0 {
  $input-btn-padding-y-xxs: $input-btn-padding-y-xxs;
} @else {
  $input-btn-padding-y-xxs: 0;
}
$input-btn-padding-x-xxs: 5px;

$input-btn-height-xs: 24px;
$input-btn-font-size-xs: $font-size-xs;
$input-btn-line-height-xs: 1.5;
$input-btn-border-radius-xs: $border-radius-sm;
$input-btn-padding-y-xs: math.floor(
  math.div(($input-btn-height-xs - ($input-btn-font-size-xs * $input-btn-line-height-xs) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y-xs > 0 {
  $input-btn-padding-y-xs: $input-btn-padding-y-xs;
} @else {
  $input-btn-padding-y-xs: 0;
}
$input-btn-padding-x-xs: 7px;

$input-btn-height-sm: 27px;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;
$input-btn-border-radius-sm: $border-radius-sm;
$input-btn-padding-y-sm: math.floor(
  math.div(($input-btn-height-sm - ($input-btn-font-size-sm * $input-btn-line-height-sm) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y-sm > 0 {
  $input-btn-padding-y-sm: $input-btn-padding-y-sm;
} @else {
  $input-btn-padding-y-sm: 0;
}
$input-btn-padding-x-sm: 7px;

$input-btn-height-md: 28px;
$input-btn-font-size-md: $font-size-md;
$input-btn-line-height-md: $line-height-sm;
$input-btn-border-radius-md: $border-radius;
$input-btn-padding-y-md: math.floor(
  math.div(($input-btn-height-md - ($input-btn-font-size-md * $input-btn-line-height-md) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y-md > 0 {
  $input-btn-padding-y-md: $input-btn-padding-y-md;
} @else {
  $input-btn-padding-y-md: 0;
}
$input-btn-padding-x-md: 7px;

$input-btn-font-size-lmd: $font-size-lmd;

$input-btn-height-lg: 40px;
$input-btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg: $line-height-lg;
$input-btn-border-radius-lg: $border-radius-lg;
$input-btn-padding-y-lg: math.floor(
  math.div(($input-btn-height-lg - ($input-btn-font-size-lg * $input-btn-line-height-lg) - (2 * $input-btn-border-width)), 2)
);
@if $input-btn-padding-y-lg > 0 {
  $input-btn-padding-y-lg: $input-btn-padding-y-lg;
} @else {
  $input-btn-padding-y-lg: 0;
}
$input-btn-padding-x-lg: 14px;

// End of Common Input and Button styles

// Buttons
$btn-height: $input-btn-height;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-gap: 7px;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-font-weight: $font-weight-normal;
$btn-line-height: $input-btn-line-height;
$btn-ico-size: 0.8;
$btn-border-width: $input-btn-border-width;
$btn-border-radius: $input-btn-border-radius;
$btn-border-darken: 8.6%;
$btn-bg-darken-hover: 8.6%;
$btn-border-darken-hover: 8.6%;
$btn-bg-darken-focus: 8.6%;
$btn-border-darken-focus: 8.6%;
$btn-bg-darken-active: 5%;
$btn-border-darken-active: 5%;
$btn-disabled-percentage: $disabled-percentage;

$btn-height-xxs: $input-btn-height-xxs;
$btn-padding-y-xxs: $input-btn-padding-y-xxs;
$btn-padding-x-xxs: $input-btn-padding-x-xxs;
$btn-gap-xxs: 4px;
$btn-font-size-xxs: $input-btn-font-size-xxs;
$btn-line-height-xxs: $input-btn-line-height-xxs;
$btn-border-radius-xxs: $input-btn-border-radius-xxs;

$btn-height-xs: $input-btn-height-xs;
$btn-padding-y-xs: $input-btn-padding-y-xs;
$btn-padding-x-xs: $input-btn-padding-x-xs;
$btn-gap-xs: 5px;
$btn-font-size-xs: $input-btn-font-size-xs;
$btn-line-height-xs: $input-btn-line-height-xs;
$btn-border-radius-xs: $input-btn-border-radius-xs;

$btn-height-sm: $input-btn-height-sm;
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-gap-sm: 6px;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;
$btn-border-radius-sm: $input-btn-border-radius-sm;

$btn-height-md: $input-btn-height-md;
$btn-padding-y-md: $input-btn-padding-y-md;
$btn-padding-x-md: $input-btn-padding-x-md;
$btn-gap-md: 7px;
$btn-font-size-md: $input-btn-font-size-md;
$btn-line-height-md: $input-btn-line-height-md;
$btn-border-radius-md: $input-btn-border-radius-md;

$btn-gap-lmd: 8px;
$btn-font-size-lmd: $input-btn-font-size-lmd;

$btn-height-lg: $input-btn-height-lg;
$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-gap-lg: 10px;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;
$btn-border-radius-lg: $input-btn-border-radius-lg;

$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $btn-box-shadow;
$btn-disabled-opacity: 1;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $disabled-color;

$btn-block-spacing-y: 7px;

// Btn loading status
$btn-loading-color: $gray-400;

$btn-transition: none;

// Buttons size master list: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
$btn-sizes: 'xxs' $btn-height-xxs $btn-padding-y-xxs $btn-padding-x-xxs $btn-gap-xxs $btn-font-size-xxs $btn-line-height-xxs
    $btn-border-radius-xxs,
  'xs' $btn-height-xs $btn-padding-y-xs $btn-padding-x-xs $btn-gap-xs $btn-font-size-xs $btn-line-height-xs $btn-border-radius-xs,
  'sm' $btn-height-sm $btn-padding-y-sm $btn-padding-x-sm $btn-gap-sm $btn-font-size-sm $btn-line-height-sm $btn-border-radius-sm,
  'md' $btn-height-md $btn-padding-y-md $btn-padding-x-md $btn-gap-md $btn-font-size-md $btn-line-height-md $btn-border-radius-md,
  'lg' $btn-height-lg $btn-padding-y-lg $btn-padding-x-lg $btn-gap-lg $btn-font-size-lg $btn-line-height-lg $btn-border-radius-lg;
// End of Buttons

// Controls
$ctrl-padding-y: 0;
$ctrl-padding-x: 0;
$ctrl-gap: $btn-gap;
$ctrl-font-family: $input-btn-font-family;
$ctrl-font-size: $font-size-base;
$ctrl-line-height: 1;
$ctrl-border-radius: 0;

$ctrl-gap-xxs: $btn-gap-xxs;
$ctrl-font-size-xxs: $font-size-xxs;

$ctrl-gap-xs: $btn-gap-xs;
$ctrl-font-size-xs: $input-btn-font-size-xs;

$ctrl-gap-sm: $btn-gap-sm;
$ctrl-font-size-sm: $input-btn-font-size-sm;

$ctrl-gap-md: $btn-gap-md;
$ctrl-font-size-md: $input-btn-font-size-md;

$ctrl-gap-lmd: $btn-gap-lmd;
$ctrl-font-size-lmd: $input-btn-font-size-lmd;

$ctrl-gap-lg: $btn-gap-lg;
$ctrl-font-size-lg: $input-btn-font-size-lg;

$ctrl-border-width: 0;

$ctrl-font-weight: $font-weight-normal;
$ctrl-focus-width: 0;
$ctrl-disabled-opacity: 1;

$ctrl-color: $body-color;
$ctrl-link-disabled-color: $disabled-color;
$ctrl-active-color: $primary;
$ctrl-hover-color: $primary;

$ctrl-block-spacing-y: 7px;

$ctrl-transition: none;

// Controls size master list: 'inherit' | 'xxs' | 'xs' | 'sm' | 'md' | 'lmd' | 'xmd' | 'lg'
$ctrl-sizes: 'inherit' inherit $ctrl-gap, 'xxs' $ctrl-font-size-xxs $ctrl-gap-xxs, 'xs' $ctrl-font-size-xs $ctrl-gap-xs,
  'sm' $ctrl-font-size-sm $ctrl-gap-sm, 'md' $ctrl-font-size-md $ctrl-gap-md, 'lmd' $ctrl-font-size-lmd $ctrl-gap-lmd,
  'lg' $ctrl-font-size-lg $ctrl-gap-lg;
// End of Controls

// Inputs
$input-height: $input-btn-height;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;
$input-color: $gray-900;
$input-border-color: $border-color;
$input-border-width: $input-btn-border-width;
$input-border-radius: $border-radius;
$input-box-shadow: none;
$input-bg: $white;
$input-height-border: $input-border-width * 2;
$input-height-inner: calc(#{$input-height} - #{$input-height-border});
$input-height-inner-half: math.div($input-height-inner, 2);
$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Disabled state
$input-disabled-bg: $gray-200;
$input-disabled-color: $disabled-color;
$input-disabled-border-color: $gray-300;

// Focus state
$input-focus-bg: $input-bg;
$input-focus-border-color: mix($component-active-bg, $white, 40%);
$input-focus-color: $input-color;
$input-focus-width: 7px;
$input-focus-box-shadow: 0 0 $input-focus-width $input-btn-focus-color;

// Sizes
$input-height-xxs: $input-btn-height-xxs;
$input-height-inner-xxs: calc(#{$input-height-xxs} - #{$input-height-border});
$input-height-inner-half-xxs: math.div($input-height-inner-xxs, 2);
$input-padding-y-xxs: $input-btn-padding-y-xxs;
$input-padding-x-xxs: $input-btn-padding-x-xxs;
$input-font-size-xxs: $input-btn-font-size-xxs;
$input-line-height-xxs: $input-btn-line-height-xxs;
$input-border-radius-xxs: $input-btn-border-radius-xxs;

$input-height-xs: $input-btn-height-xs;
$input-height-inner-xs: calc(#{$input-height-xs} - #{$input-height-border});
$input-height-inner-half-xs: math.div($input-height-inner-xs, 2);
$input-padding-y-xs: $input-btn-padding-y-xs;
$input-padding-x-xs: $input-btn-padding-x-xs;
$input-font-size-xs: $input-btn-font-size-xs;
$input-line-height-xs: $input-btn-line-height-xs;
$input-border-radius-xs: $input-btn-border-radius-xs;

$input-height-sm: $input-btn-height-sm;
$input-height-inner-sm: calc(#{$input-height-sm} - #{$input-height-border});
$input-height-inner-half-sm: math.div($input-height-inner-sm, 2);
$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;
$input-border-radius-sm: $input-btn-border-radius-sm;

$input-height-md: $input-btn-height-md;
$input-height-inner-md: calc(#{$input-height-md} - #{$input-height-border});
$input-height-inner-half-md: math.div($input-height-inner-md, 2);
$input-padding-y-md: $input-btn-padding-y-md;
$input-padding-x-md: $input-btn-padding-x-md;
$input-font-size-md: $input-btn-font-size-md;
$input-line-height-md: $input-btn-line-height-md;
$input-border-radius-md: $input-btn-border-radius-md;

$input-height-lg: $input-btn-height-lg;
$input-height-inner-lg: calc(#{$input-height-lg} - #{$input-height-border});
$input-height-inner-half-lg: math.div($input-height-inner-lg, 2);
$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;
$input-border-radius-lg: $input-btn-border-radius-lg;

// Inputs size master list: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
$input-sizes: 'xxs' $input-height-xxs $input-padding-y-xxs $input-padding-x-xxs $input-font-size-xxs $input-line-height-xxs
    $input-border-radius-xxs,
  'xs' $input-height-xs $input-padding-y-xs $input-padding-x-xs $input-font-size-xs $input-line-height-xs $input-border-radius-xs,
  'sm' $input-height-sm $input-padding-y-sm $input-padding-x-sm $input-font-size-sm $input-line-height-sm $input-border-radius-sm,
  'md' $input-height-md $input-padding-y-md $input-padding-x-md $input-font-size-md $input-line-height-md $input-border-radius-md,
  'lg' $input-height-lg $input-padding-y-lg $input-padding-x-lg $input-font-size-lg $input-line-height-lg $input-border-radius-lg;

// End of Inputs

// Input group
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
// End of Input group

// Common variables for form subtitles and description
$subtitle-description-margin-top: 4px;

// Common variables for form subtitles
$subtitle-font-size: $font-size-xs;
$subtitle-disabled-color: $disabled-color;
$subtitle-disabled-percentage: $disabled-percentage;
// End of subtitles

// End of subtitles and descriptions

// Custom form elements (not used)
$custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$custom-control-gutter: 7px;
$custom-control-spacer-x: 14px;

$custom-control-indicator-size: 14px;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $gray-500;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $gray-600;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba($primary, 0.5);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-switch-width: $custom-control-indicator-size * 1.75;
$custom-switch-indicator-border-radius: math.div($custom-control-indicator-size, 2);
$custom-switch-indicator-size: calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4});

$custom-select-padding-y: 0;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 14px; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding}
);
$custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075);

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-range-track-width: 100%;
$custom-range-track-height: 7px;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 14px;
$custom-range-track-box-shadow: inset 0 3px 3px rgba($black, 0.1);

$custom-range-thumb-width: 14px;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 14px;
$custom-range-thumb-box-shadow: 0 2px 3px rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $gray-500;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: 'Browse'
);
// End of Custom form elements

// Advanced select
$advanced-select-margin-x: 7px;
// End of Advanced select

// Form elements (checkboxes, radiobuttons, switchers)
$check-radio-size: 17px;
$check-radio-gap: 8px;
$check-radio-margin-top: 8px;
$check-radio-color-primary: $input-bg;
$check-radio-color-secondary: $gray-600;
$check-radio-label-font-size: $font-size-base;
$check-radio-label-line-height: 1.5;
$check-radio-label-color: $label-color;
$check-radio-input-offset-top: math.floor(
  math.div(($check-radio-label-font-size * $check-radio-label-line-height), 2) - math.div($check-radio-size, 2)
);
$check-radio-min-height: math.floor($check-radio-size + (2 * $check-radio-input-offset-top));
@if $check-radio-size < $check-radio-min-height {
  $check-radio-min-height: $check-radio-min-height;
} @else {
  $check-radio-min-height: $check-radio-size;
}
$check-radio-accent-color-primary: $primary;
$check-radio-accent-color-secondary: $white;
$check-radio-focus-width: 4px;
$check-radio-focus-box-shadow: 0 0 $check-radio-focus-width $input-btn-focus-color;
$check-radio-disabled-color-primary: $input-disabled-bg;
$check-radio-disabled-color-secondary: $check-radio-color-secondary;
$check-radio-disabled-border-color: $input-disabled-border-color;
$check-radio-disabled-percentage: $disabled-percentage;
$check-radio-accent-disabled-color-primary: mix($check-radio-accent-color-primary, $white, $check-radio-disabled-percentage);
$check-radio-accent-disabled-color-secondary: mix($check-radio-accent-color-secondary, $white, $check-radio-disabled-percentage);
$check-radio-label-disabled-color: $label-disabled-color;
$check-radio-subtitle-description-inside-margin-left: $check-radio-size + $check-radio-gap;

// Small
$check-radio-size-sm: 14px;
$check-radio-gap-sm: 8px;
$check-radio-input-offset-top-sm: math.floor(
  math.div(($check-radio-label-font-size * $check-radio-label-line-height), 2) - math.div($check-radio-size-sm, 2)
);
$check-radio-min-height-sm: math.floor($check-radio-size-sm + (2 * $check-radio-input-offset-top-sm));
@if $check-radio-size-sm < $check-radio-min-height-sm {
  $check-radio-min-height-sm: $check-radio-min-height-sm;
} @else {
  $check-radio-min-height-sm: $check-radio-size-sm;
}
$check-radio-subtitle-description-inside-margin-left-sm: $check-radio-size-sm + $check-radio-gap-sm;

// Large
$check-radio-size-lg: 22px;
$check-radio-gap-lg: 12px;
$check-radio-input-offset-top-lg: math.floor(
  math.div(($check-radio-label-font-size * $check-radio-label-line-height), 2) - math.div($check-radio-size-lg, 2)
);
$check-radio-min-height-lg: math.floor($check-radio-size-lg + (2 * $check-radio-input-offset-top-lg));
@if $check-radio-size-lg < $check-radio-min-height-lg {
  $check-radio-min-height-lg: $check-radio-min-height-lg;
} @else {
  $check-radio-min-height-lg: $check-radio-size-lg;
}
$check-radio-subtitle-description-inside-margin-left-lg: $check-radio-size-lg + $check-radio-gap-lg;

// Checkbox and radio size master list: 'sm' 'lg'
$check-radio-sizes: 'sm' $check-radio-size-sm $check-radio-min-height-sm $check-radio-gap-sm $check-radio-input-offset-top-sm,
  'lg' $check-radio-size-lg $check-radio-min-height-lg $check-radio-gap-lg $check-radio-input-offset-top-lg;

// Checkbox and radio descriptions and subtitles positions list
$check-radio-subtitle-description-sizes: 'sm' $check-radio-subtitle-description-inside-margin-left-sm,
  'lg' $check-radio-subtitle-description-inside-margin-left-lg;

// Checkboxes
$check-input-border-width: $input-border-width;
$check-input-border-radius: $border-radius-sm;
$check-icon-size: 10px;
$check-subtitle-font-size: $font-size-sm;
$check-subtitle-margin-top: 6px;

// Small checkboxes
$check-input-border-radius-sm: $border-radius-sm;
$check-icon-size-sm: 8px;

// Large checkboxes
$check-input-border-radius-lg: $border-radius;
$check-icon-size-lg: 12px;

// Checkbox size master list: 'sm' 'lg'
$check-sizes: 'sm' $check-input-border-radius-sm $check-icon-size-sm, 'lg' $check-input-border-radius-lg $check-icon-size-lg;
// End of Checkboxes

// Radiobuttons
$radio-input-border-width: $input-border-width;
$radio-input-border-radius: 50%;
$radio-input-accented-border-width: 5px;

// Small radio
$radio-input-accented-border-width-sm: 4px;

// Large radio
$radio-input-accented-border-width-lg: 7px;

// Radio size master list: 'sm' 'lg'
$radio-sizes: 'sm' $radio-input-accented-border-width-sm, 'lg' $radio-input-accented-border-width-lg;
// End of Radiobuttons

// Switcher
$switcher-min-height: $check-radio-min-height;
$switcher-margin-top: $check-radio-margin-top;
$switcher-color-primary: $gray-600;
$switcher-color-secondary: $white;
$switcher-accent-color: $primary;
$switcher-track-height: $check-radio-size;
$switcher-track-width: 28px;
$switcher-track-gap: $check-radio-gap;
$switcher-track-border-radius: $switcher-track-height;
$switcher-track-offset-top: $check-radio-input-offset-top;
$switcher-track-padding: 2px;
$switcher-toggler-size: $switcher-track-height - (2 * $switcher-track-padding);
$switcher-toggler-icon-size: 7px;
$switcher-toggler-icon-checked-size: 9px;
$switcher-disabled-percentage: $disabled-percentage;
$switcher-disabled-color-primary: $gray-200;
$switcher-disabled-color-secondary: mix($switcher-color-secondary, $white, $switcher-disabled-percentage);
$switcher-accent-disabled-color: mix($switcher-accent-color, $white, $switcher-disabled-percentage);
$switcher-subtitle-description-inside-padding-x: $switcher-track-width + $switcher-track-gap;
// End of Switcher

// Advanced search
$advanced-search-dropdown-padding-y: 14px;
$advanced-search-dropdown-padding-x: 21px;
// End of Advanced search

//
// End of Form Controls
//

// Form inside dropdown
$form-dropdown-pdy: 10px;
$form-dropdown-pdx: 10px;
$form-dropdown-width: 350px;
$form-dropdown-margin-left: 25px;
$form-dropdown-data-height: 200px;
$form-dropdown-data-bg: $gray-200;
$form-dropdown-data-bg-items: $white;
$form-dropdown-data-border-width: 1px;
$form-dropdown-data-border-color: $gray-400;
$form-dropdown-data-item-font-size: $font-size-sm;
$form-dropdown-data-item-pdy: 6px;
$form-dropdown-data-item-pdx: $input-btn-padding-x;
$form-dropdown-data-item-bg: $form-dropdown-data-bg-items;
$form-dropdown-data-item-hover-bg: $table-hover-bg;
$form-dropdown-footer-pdt: 7px;
$form-dropdown-footer-pdb: 0;
$form-dropdown-footer-pdx: $form-dropdown-pdx;
$form-dropdown-footer-bg: $form-footer-bg;

// Shown select inside dropdown form
$form-dropdown-select-header-font-size: $font-size-sm;
$form-dropdown-select-header-font-weight: $font-weight-normal;
$form-dropdown-select-header-color: $gray-600;
$form-dropdown-select-header-pdt: 21px;
$form-dropdown-select-header-pdx: 0;
$form-dropdown-select-header-pdb: 7px;
$form-dropdown-select-data-height: $form-dropdown-data-height;
$form-dropdown-select-data-bg: $form-dropdown-data-bg;
$form-dropdown-select-data-bg-items: $form-dropdown-data-bg-items;
$form-dropdown-select-data-border-width: $form-dropdown-data-border-width;
$form-dropdown-select-data-border-color: $form-dropdown-data-border-color;
$form-dropdown-select-data-item-font-size: $form-dropdown-data-item-font-size;
$form-dropdown-select-data-item-pdy: 6px;
$form-dropdown-select-data-item-pdx: $form-dropdown-data-item-pdx;
$form-dropdown-select-data-item-bg: $form-dropdown-data-item-bg;
$form-dropdown-select-data-item-hover-bg: $form-dropdown-data-item-hover-bg;
$form-dropdown-select-data-item-border-radius: 0;
// End of Form inside dropdown

//
// Navigation
//
// Navigation links
$nav-link-padding-y: 7px;
$nav-link-padding-x: 14px;
$nav-link-disabled-color: $disabled-color;
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: $font-weight-bold;
// End of Navigation links

// Navigation tabs
$nav-tabs-padding-y: 14px;
$nav-tabs-padding-x: 11px;
$nav-tabs-bg-color: $gray-200;
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: 6px;
$nav-tabs-link-color: $gray-700;
$nav-tabs-link-hover-color: $gray-900;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-font-size: $font-size-md;
$nav-tabs-link-font-weight: $font-weight-semibold;
$nav-tabs-link-line-height: 1.55;
$nav-tabs-item-index-size: 20px;
$nav-tabs-item-index-margin-r: $gutter;
$nav-tabs-item-index-margin-b: $gutter;
$nav-tabs-item-index-font-size: $font-size-sm;
$nav-tabs-item-index-font-weight: $font-weight-normal;
$nav-tabs-item-index-color: $white;
$nav-tabs-item-index-bg: $gray-300;

// Disabled tab
$nav-tabs-link-disabled-color: $disabled-color;

// Active tab
$nav-tabs-link-active-color: $gray-900;
$nav-tabs-link-active-hover-color: $nav-tabs-link-hover-color;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $border-color;
$nav-tabs-item-active-index-bg: $primary;
$nav-tabs-item-active-index-color: $white;

// Success tab
$nav-tabs-item-success-index-bg: $success;
$nav-tabs-item-success-index-color: $white;
$nav-tabs-item-success-color: $nav-tabs-link-active-color;
$nav-tabs-item-success-hover-color: $nav-tabs-link-active-hover-color;
// End of Navigation tabs

// Navigation pills
$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;
// End of Navigation pills

// Navigation divider
$nav-divider-color: $gray-200;
$nav-divider-margin-y: math.div($spacer, 2);
// End of Navigation divider

//
// End of Navigation
//

// Navbar
$navbar-toggler-padding-y: 3.5px;
$navbar-toggler-padding-x: 10.5px;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-padding-y-md: 3.5px;
$navbar-toggler-padding-x-md: 5px;
$navbar-toggler-font-size-md: $font-size-sm;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: $white;
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-light-toggler-border-color: rgba($black, 0.1);
$navbar-light-bg-color: #fafafa;
$navbar-light-bg-gradient: linear-gradient(to bottom, #ffffff, #f2f2f2); // TODO: Replace with variables
$navbar-light-border-width: $border-width;
$navbar-light-border-color: #d4d4d4; // TODO: Replace with variables
$navbar-light-box-shadow: 0 1px 4px rgba($black, 0.065);

$navbar-light-link-color: $gray-700;
$navbar-light-link-active-color: $gray-900;
$navbar-light-link-active-bg: #e5e5e5; // TODO: Replace with variables
$navbar-light-link-active-shadow: inset 0 3px 8px rgba($black, 0.125);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

$navbar-shadow: 0 1px 10px rgba($black, 0.1);
$navbar-bg: linear-gradient(to bottom, $white, $gray-200);
$navbar-pd-x: $gutter;
$navbar-pd-y: 0;
$navbar-border-width: $border-width;
$navbar-border-color: $border-color;
$navbar-font-size: $font-size-md;
$navbar-link-color: $gray-700;
$navbar-link-color-hover: $gray-900;
$navbar-link-pd-x: 12px;
$navbar-link-pd-x-xs: math.div($navbar-link-pd-x, 2);
$navbar-link-pd-y: $gutter;
$navbar-link-ico-size: inherit;
$navbar-link-ico-mg-r: 3px;
$navbar-link-shadow: 0 1px 0 $white;
$navbar-logo-size: 20px;
$navbar-logo-pd-r: 2 * $gutter;
$navbar-logo-pd-l: 0;
$navbar-logo-pd-y: $gutter;
$navbar-submenu-ico-width: 14px;
$navbar-submenu-ico-mg-r: $navbar-link-ico-mg-r;
$navbar-submenu-zindex: $zindex-fixed + 1;

$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-nav-link-padding-x: 7px;

$navbar-brand-font-size: $font-size-lg;
$navbar-brand-padding-y: 7px;
$navbar-brand-height: $navbar-brand-font-size + $navbar-brand-padding-y * 2;

$navlink-padding-x: 1.5 * $gutter;
$navlink-padding-y: $gutter;

$navbar-nav-scroll-max-height: 75vh;
// End of Navbar

// Layout
$container-content-pd-x: 0;
$container-content-pd-t: $header-height - $navbar-border-width;
$container-content-pd-b: 0;
// End of Layout

// Page
$page-footer-padding-y: 14px;
$page-footer-bg: $gray-200;
$page-footer-border-width: $border-width;
$page-footer-border-color: $border-color;
$page-content-padding-top: 28px;
$page-content-padding-bottom: calc(
  (2 * #{$page-footer-border-width}) + (2 * #{$page-footer-padding-y}) + #{$input-height}
); // Calculated for page-footer height
$page-content-padding-x: $grid-gutter-width;
$page-footer-padding-x: $page-content-padding-x;
$page-header-info-ico-margin-left: 10.5px;
$page-header-info-ico-size: 21px;
$page-header-info-ico-color: $gray-400;
$page-header-info-ico-color-active: $primary;
$page-title-margin-bottom: 7px;
// End of Page

// Tabset
$tabset-bg: $gray-100;
$tabset-border-width: $border-width;
$tabset-border-color: $border-color;
$tabset-border-radius: $border-radius-lg;
$tabset-item-active-bg: $white;
$tabset-link-padding-y: 11px;
$tabset-link-padding-x: 14px;
$tabset-link-color: $gray-800;
$tabset-link-active-color: $gray-900;
$tabset-link-disabled-color: $gray-500;
$tabset-link-font-size: $font-size-md;
$tabset-link-font-weight: $font-weight-semibold;
$tabset-link-line-height: 1.55;
$tabset-link-ico-font-size: $font-size-lg;
$tabset-link-ico-font-weight: $font-weight-normal;

// Horizontal tabset
$tabset-horizontal-padding-top: 0;
$tabset-horizontal-padding-x: 0;

// Vertical tabset
$tabset-vertical-padding-y: 9px;
$tabset-vertical-padding-left: 9px;
$tabset-vertical-group-padding-top: 0;
$tabset-vertical-group-padding-bottom: 7px;
$tabset-vertical-group-padding-x: $tabset-link-padding-x;
$tabset-vertical-group-link-padding-y: 3.5px;
$tabset-vertical-group-link-padding-left: 14px;
$tabset-vertical-group-link-font-size: $font-size-sm;
$tabset-vertical-group-link-line-height: 1.45;
$tabset-vertical-group-link-color: $gray-700;
$tabset-vertical-group-link-active-color: $blue;
$tabset-vertical-group-link-disabled-color: $gray-500;
$tabset-vertical-group-link-marker-size: 3px;
$tabset-vertical-group-link-marker-color: $gray-500;
$tabset-vertical-group-link-active-marker-color: $tabset-vertical-group-link-active-color;
$tabset-vertical-group-link-disabled-marker-color: $tabset-vertical-group-link-disabled-color;
$tabset-vertical-group-link-marker-position-top: calc(
  #{$tabset-vertical-group-link-padding-y} - (#{$tabset-vertical-group-link-marker-size} / 2) + (#{$tabset-vertical-group-link-font-size} * #{$tabset-vertical-group-link-line-height} /
        2)
);
// End of Tabset

// Dropdowns
$dropdown-min-width: 140px;
$dropdown-padding-y: 7px;
$dropdown-spacer: 1.75px;
$dropdown-font-size: $font-size-base;
$dropdown-font-size-sm: $font-size-sm;
$dropdown-font-size-lg: $font-size-lg;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: transparent;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
$dropdown-divider-bg: rgba($black, 0.15);
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: 0 2px 10px rgba($black, 0.1);

$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-line-height: $line-height-base;

$dropdown-item-padding-y: 3.5px;
$dropdown-item-padding-x: 21px;
$dropdown-item-min-height: calc((2 * #{$dropdown-item-padding-y}) + (#{$dropdown-font-size} * #{$dropdown-item-line-height}));

$dropdown-item-padding-y-sm: 2px;
$dropdown-item-padding-x-sm: 10.5px;
$dropdown-item-min-height-sm: calc((2 * #{$dropdown-item-padding-y-sm}) + (#{$dropdown-font-size-sm} * #{$dropdown-item-line-height}));

$dropdown-item-min-height-lg: calc((2 * #{$dropdown-item-padding-y}) + (#{$dropdown-font-size-lg} * #{$dropdown-item-line-height}));

$dropdown-header-color: $gray-600;

$dropdown-arrow-size: 8px;
$dropdown-arrow-size-sm: 6px;
// End of Dropdowns

// Pagination
$pagination-padding-y: 7px;
$pagination-padding-x: 10.5px;
$pagination-padding-y-sm: 3.5px;
$pagination-padding-x-sm: 7px;
$pagination-padding-y-lg: 10.5px;
$pagination-padding-x-lg: 21px;
$pagination-line-height: 1.25;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-300;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;
// End of Pagination

// Jumbotron
$jumbotron-padding: 28px;
$jumbotron-color: null;
$jumbotron-bg: $gray-200;
// End of Jumbotron

// Cards
$card-spacer-y: 10.5px;
$card-spacer-x: 17.5px;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: rgba($black, 0.03);
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 17.5px;

$card-group-margin: math.div($grid-gutter-width, 2);
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 17.5px;
$card-columns-margin: $card-spacer-y;
// End of Cards

// Tooltips
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-margin-top: 2px;
$tooltip-bg: $white;
$tooltip-color: color-yiq($tooltip-bg);
$tooltip-border-radius: $border-radius;
$tooltip-border-width: $border-width;
$tooltip-border-darken: 15%;
$tooltip-border-color: darken($secondary, $tooltip-border-darken);
$tooltip-opacity: 1;
$tooltip-padding-y: 3.5px;
$tooltip-padding-x: 7px;
$tooltip-margin: 0;
$tooltip-box-shadow: 0 2px 12px rgba($black, 0.1);

$tooltip-arrow-width: 11px;
$tooltip-arrow-height: 5.5px;
$tooltip-arrow-color: $tooltip-bg;

// Tooltip Sizes
$tooltip-font-size-lg: $font-size-base;
$tooltip-max-width-lg: 345px;

$tooltip-max-width-xl: 550px;
$tooltip-padding-y-xl: 18px;
$tooltip-padding-x-xl: 21px;

// Tooltip info btn
$tooltip-btn-margin-left: 10.5px;
$tooltip-btn-color: $primary;
$tooltip-btn-size: 14px;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;
// End of Tooltips

// Popovers
$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0 3.5px 7px rgba($black, 0.2);

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 7px;
$popover-header-padding-x: 10.5px;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 14px;
$popover-arrow-height: 7px;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);
// Popovers

// Badges
$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-border-radius: $border-radius;

$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;

$badge-pill-padding-x: 0.6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 140px;
// End of Badges

//
// Modals
//
// Padding applied to the modal body
$modal-inner-padding: $gutter;
$modal-inner-padding-x: 21px;

$modal-dialog-margin: 7px;
$modal-dialog-margin-y-sm-up: 24px;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-lg;
$modal-content-box-shadow-xs: 0 4px 34px rgba($black, 0.25);
$modal-content-box-shadow-sm-up: 0 4px 34px rgba($black, 0.25);
$modal-content-box-shadow: 0 4px 34px rgba($black, 0.25);
$modal-content-ico-mgx: 14px;
$modal-content-ico-circle-size: 40px;
$modal-content-ico-circle-font-size: 20px;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.3;

$modal-header-border-width: $border-width;
$modal-header-border-color: $border-color;
$modal-header-height: 55px;
$modal-header-padding-y: 16px;
$modal-header-padding-x: $modal-inner-padding-x;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-header-sm-padding-y: 7px;
$modal-header-sm-padding-x: $modal-inner-padding-x;
$modal-header-sm-padding: $modal-header-sm-padding-y $modal-header-sm-padding-x; // Keep this for backwards compatibility
$modal-header-mg-r: math.div($gutter, 4);
$modal-header-font-size: inherit;
$modal-header-gap: 10px;

$modal-title-font-size: $h5-font-size;
$modal-title-line-height: $line-height-base;
$modal-title-min-height: calc(#{$modal-title-font-size} * #{$modal-title-line-height});

$modal-footer-padding-y: 14px;
$modal-footer-padding-x: $modal-inner-padding-x;
$modal-footer-border-color: $modal-header-border-color;
$modal-footer-border-width: $modal-header-border-width;
$modal-footer-margin-between: 7px;

$modal-body-padding-y: 21px;
$modal-body-padding-x: $modal-inner-padding-x;

$modal-xl: 972px;
$modal-lg: 890px;
$modal-md: 680px;
$modal-sm: 500px;
$modal-res-xl: 90%;
$modal-res-lg: 80%;
$modal-res-md: 70%;
$modal-res-sm: 50%;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;

$modal-collapsing-min-height: 200px;
$modal-collapsing-max-height: calc(100vh - (2 * #{$modal-dialog-margin-y-sm-up}));
$modal-inside-collapsing-max-height: calc(100% - (2 * #{$modal-dialog-margin-y-sm-up}));

//
// End of Modals
//

// Progress bars
$progress-height: 6px;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 2px 2px rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 1.2s ease;
$progress-bar-waiting-size: 100%;
$progress-bar-waiting-bg-img: linear-gradient(120deg, $progress-bg 10%, $gray-300 30%, $progress-bg 50%);
$progress-bar-waiting-bg-size: 150%;
$progress-bar-waiting-animation-timing: 1.8s ease-out infinite;
$progress-bar-waiting-animation-position-from: $progress-bar-waiting-bg-size;
$progress-bar-waiting-animation-position-to: calc(-1 * #{$progress-bar-waiting-bg-size});
// End of Progress bars

// List group
$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: 10.5px;
$list-group-item-padding-x: 17.5px;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

$list-group-font-size: $font-size-xs;
$list-group-padding-y: 14px;
// End of List group

// Image thumbnails
$thumbnail-padding: 3.5px;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);
// End of Image thumbnails

// Figures
$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;
// End of Figures

// Breadcrumbs
$breadcrumb-inner-height: 21px;
$breadcrumb-padding-y: 10.5px;
$breadcrumb-padding-x: 7px;
$breadcrumb-item-padding: 7px;
$breadcrumb-margin-bottom: 14px;
$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-300;
$breadcrumb-active-color: $gray-900;
$breadcrumb-divider: quote('/');
$breadcrumb-border-radius: $border-radius;
$breadcrumb-font-size: $font-size-sm;
// End of Breadcrumbs

// Carousel
$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
// End of Carousel

// Spinners
$spinner-width: 28px;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;

$spinner-width-sm: 14px;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;
// End of Spinners

// Close
$close-font-size: 21px;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;
// End of Close

// Wizard
$wizard-width: 840px; // Not implemented yet. See MBS-11699

$wizard-nav-tabs-padding-x: 13px;
$wizard-nav-tabs-padding-y: 11.5px;

$wizard-steps-bg: $nav-tabs-bg-color;
$wizard-steps-padding-y: 1.2 * $gutter;
$wizard-steps-padding-x: $gutter;
$wizard-steps-border-width: $nav-tabs-border-width;
$wizard-steps-border-color: $nav-tabs-link-active-border-color;
$wizard-steps-marker-size: 22px;
$wizard-steps-container-padding-y: 21px;
$wizard-steps-container-padding-x: 21px;
$wizard-steps-item-marker-bg: $white;
$wizard-steps-item-marker-border-width: 2px;
$wizard-steps-item-marker-border-color: $wizard-steps-border-color;
$wizard-steps-item-active-marker-border-width: 7px;
$wizard-steps-item-active-marker-border-color: $blue;
$wizard-steps-item-success-marker-border-width: 0;
$wizard-steps-item-success-marker-bg: $nav-tabs-item-success-index-bg;
$wizard-steps-item-success-marker-color: $white;
$wizard-steps-item-success-marker-ico-size: $font-size-xs;
$wizard-steps-item-marker-mg-b: 0.8 * $gutter;
$wizard-steps-horizontal-link-font-size: $font-size-sm;
$wizard-steps-horizontal-link-color: $gray-800;
$wizard-steps-horizontal-link-pd-x: 1.5 * $gutter;
// End of Wizard

// Alerts
$alert-padding-y: 8px;
$alert-padding-x: 14px;
$alert-margin-bottom: 14px;
$alert-border-radius: $border-radius-sm;
$alert-link-font-weight: $font-weight-normal;
$alert-border-width: 0;
$alert-font-size: $font-size-md;
$alert-line-height: 1.55;
$alert-color: $gray-900;
$alert-content-inner-padding-x: 10px;
$alert-ico-size: $font-size-base;
$alert-ico-offset-top: 4px;
$alert-close-offset-top: 7px;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 5;

$alert-darken-level: 75%;
$alert-lighten-level: 20%;

$alert-padding-y-sm: 4px;
$alert-padding-x-sm: 12px;
$alert-font-size-sm: $font-size-xs;
$alert-line-height-sm: 1.75;
$alert-ico-size-sm: $font-size-base;
$alert-ico-offset-top-sm: 3px;
$alert-close-offset-top-sm: 6px;

$alert-padding-y-lg: 10px;
$alert-padding-x-lg: 16px;
$alert-font-size-lg: $font-size-lg;
$alert-line-height-lg: 1.75;
$alert-ico-size-lg: $font-size-lg;
$alert-ico-offset-top-lg: 7px;
$alert-close-offset-top-lg: 10px;
// End of Alerts

// Toasts (use Alerts colors and sizes)
$toast-max-width: 350px;

$toast-gap-y: 10px;
$toast-padding-x: 14px;
$toast-padding-y: 8px;
$toast-font-size: 13px;
$toast-line-height: 19.5px;
$toast-color: $alert-color;
$toast-border-width: 0px;
$toast-border-radius: $alert-border-radius;
$toast-box-shadow: none;

$toast-bg-level: $alert-bg-level;
$toast-border-level: $alert-border-level;
$toast-color-level: $alert-color-level;

$toast-darken-level: 10%;
$toast-lighten-level: 10%;

$toast-header-padding-x: $toast-padding-x;
$toast-header-font-size: 13px;
$toast-header-font-weight: $font-weight-bold;
$toast-header-line-height: 19.5px;

$toast-body-padding-x: $toast-padding-x;
$toast-body-padding-top: 3px;

$toast-icon-font-size: 14px;
$toast-icon-padding-top: 1px;
$toast-icon-padding-left: 0px;

$toast-close-padding-top: 6px;
$toast-close-padding-right: 1px;

$toast-close-position-top: calc(((#{$toast-header-font-size} * #{$toast-header-line-height}) / 2) - (#{$ctrl-font-size-xxs / 2}));

$toast-ico-position-top: 1px;
// End of Toasts

// Tiles
$tiles-loading-height: $table-loading-height;
$tiles-margin-bottom: $table-margin-bottom;
$tile-padding-x: $gutter;
$tile-bg: $white;
$tile-border-width: $border-width;
$tile-border-color: $border-color;
$tile-border-radius: $border-radius-xl;
$tile-header-height: 44px;
$tile-header-bg: $gray-100;
$tile-name-font-size: 14px;
$tile-name-font-weight: $font-weight-semibold;
$tile-body-pdy: $gutter;
$tile-body-pdx: $tile-padding-x;
$tile-body-pd: $tile-body-pdy $tile-body-pdx;
$tile-data-font-size: $font-size-sm;
$tile-icon-size: $table-icon-size;
$tile-hover-bg: transparentize($primary, 0.9);
$tile-selected-border-color: transparentize($primary, 0.6);
$tile-selected-header-bg: transparentize($link-color, 0.8);
$tile-disabled-opacity: $disabled-opacity;
// End of Tiles

// Stub
$stub-pd-y: 40px;
$stub-pd-x: 0;
$stub-icon-pd-b: 20px;
$stub-icon-max-width: 102px;
$stub-icon-height: auto;
$stub-title-font-size: $font-size-lg;
$stub-title-font-weight: $font-weight-bold;
$stub-title-color: $gray-800;
$stub-content-font-size: $font-size-base;
$stub-content-color: $gray-600;
$stub-images-pdt: 28px;
$stub-image-caption-font-size: $font-size-base;
$stub-image-block-pd-y: 14px;
$stub-image-block-pd-x: 0;
$stub-counter-size: 30px;
$stub-counter-color: $primary;
$stub-counter-font-size: $font-size-base;
$stub-counter-font-weight: $font-weight-bold;
$stub-counter-border-width: 2px;
$stub-counter-border-color: $primary;
$stub-counter-mg-r: $gutter;
// End of Stub

// Loader
$loader-border-accent-color: $primary;
$loader-border-color: transparentize($loader-border-accent-color, 0.4);
$loader-size: 17px;
$loader-font-size: $font-size-xs;
$loader-border-width: 2px;
$loader-size-xxs: 11px;
$loader-font-size-xxs: $loader-font-size;
$loader-border-width-xxs: 1px;
$loader-size-sm: 14px;
$loader-font-size-sm: $loader-font-size;
$loader-border-width-sm: 2px;
$loader-size-lg: 20px;
$loader-font-size-lg: $loader-font-size;
$loader-border-width-lg: 2px;
// End of Loader

// Tree
$tree-item-height: 26px;
$tree-item-margin-top: 4px;
$tree-item-padding-y: 4.5px;
$tree-item-padding-x: 5px;
$tree-item-inner-padding-x: 8px;
$tree-item-level-offset: 20px;
$tree-item-selected-bg: $table-grid-row-selected-bg;
$tree-item-selected-hover-bg: $table-grid-row-selected-hover-bg;
$tree-item-hover-bg: $table-hover-bg;
$tree-item-arrow-btn-width: calc(#{$tree-item-level-offset} + #{$tree-item-padding-x});
$tree-item-arrow-icon-size: 4px;
$tree-item-arrow-color: $gray-900;
$tree-item-arrow-color-hover: $primary;

// File mode tree checkboxes
$tree-file-checkbox-indeterminate-bg: $gray-300;
$tree-file-checkbox-indeterminate-checked-bg: $tree-file-checkbox-indeterminate-bg;
$tree-file-checkbox-indeterminate-checked-color: $check-radio-accent-color-primary;
$tree-file-checkbox-indeterminate-check-size: $check-icon-size;
// End of Tree

// Steps
$steps-item-padding-top: 14px;
$steps-item-index-size: 40px;
$steps-item-index-font-size: 24px;
$steps-item-index-font-weight: $font-weight-semibold;
$steps-item-index-bg: $gray-200;
$steps-item-index-color: $gray-900;
$steps-item-active-index-bg: $steps-item-index-bg;
$steps-item-active-index-color: $steps-item-index-color;
$steps-item-success-index-bg: $success;
$steps-item-success-index-color: $white;
$steps-item-success-index-ico-size: 16px;
$steps-content-margin-top: 7px;
$steps-content-padding-left: calc(#{$steps-item-index-size} + #{$grid-gutter-width});
$steps-content-line-width: 1px;
$steps-content-line-color: $border-color;
$steps-content-line-position-x: calc((#{$steps-item-index-size} / 2) - (#{$steps-content-line-width} / 2));
// End of Steps

// Tags
$tag-height: 24px;
$tag-height-sm: 18px;
$tag-height-xs: 16px;
$tag-padding-y: 0;
$tag-padding-x: 9px;
$tag-padding-x-sm: 6px;
$tag-padding-right: 6px;
$tag-padding-right-sm: 4px;
$tag-padding-right-xs: 2px;
$tag-font-family: $font-family-sans-serif;
$tag-font-size: $font-size-xs;
$tag-font-size-sm: $font-size-xxs;
$tag-line-height: 1.5;
$tag-line-height-xs: 1.2;
$tag-border-width: 1px;
$tag-font-weight: $font-weight-normal;
$tag-ico-font-size: 7px;
$tag-ico-ml: 4px;
$tag-border-radius: 14px;
$tag-disabled-color: #fafafa;
$tag-gap: 6px;
$tag-gap-sm: 4px;
// End of Tags

// Accordion
$accordion-border-width: $border-width;
$accordion-border-color: $border-color;
$accordion-border-radius: $border-radius;
$accordion-header-bg: $white;
$accordion-header-padding-inside-y: 18px;
$accordion-header-padding-inside-y-sm: 17px;
$accordion-header-padding-inside-x: 18px;
$accordion-title-font-size: $font-size-base;
$accordion-title-font-weight: $font-weight-semibold;
$accordion-title-line-height: 1.79;
$accordion-title-font-size-sm: $font-size-md;
$accordion-title-line-height-sm: 1.55;
$accordion-title-color: $black;
$accordion-title-color-primary: $primary;
$accordion-title-color-hover: $primary;
$accordion-content-padding-y: 21px;
$accordion-content-padding-y-sm: 5px;
$accordion-content-padding-x: 24px;
$accordion-content-font-size: $font-size-sm;
$accordion-content-font-size-sm: $font-size-xs;
$accordion-content-list-font-size: $font-size-xs;
$accordion-content-list-line-height: 1.75;
$accordion-toggler-width: 50px;
$accordion-toggler-arrow-width-half: 4px;
$accordion-toggler-arrow-height: 4px;
$accordion-toggler-color: $dark;
$accordion-toggler-color-hover: darken($accordion-toggler-color, 15%); // Copied value to simulate .ctrl behavior;
$accordion-toggler-color-primary: $primary;
$accordion-toggler-color-primary-hover: darken($primary, 15%); // Copied value to simulate .ctrl behavior;
$accordion-toggler-position-top: calc(
  ((#{$accordion-title-font-size} * #{$accordion-title-line-height}) / 2) - (#{$accordion-toggler-arrow-height} / 2)
);
$accordion-toggler-position-top-sm: calc(
  ((#{$accordion-title-font-size-sm} * #{$accordion-title-line-height-sm}) / 2) - (#{$accordion-toggler-arrow-height} / 2)
);
$accordion-ico-width: 58px;
$accordion-ico-width-sm: 24px;
$accordion-ico-size: 21px;
$accordion-ico-img-width: $accordion-ico-size;
$accordion-ico-position-top: calc(((#{$accordion-title-font-size} * #{$accordion-title-line-height}) / 2) - (#{$accordion-ico-size} / 2));
$accordion-ico-position-top-sm: calc(
  ((#{$accordion-title-font-size-sm} * #{$accordion-title-line-height-sm}) / 2) - (#{$accordion-ico-size} / 2)
);
$accordion-title-ico-size: $accordion-ico-size;
$accordion-title-ico-width: 36px;
$accordion-header-checkbox-toggler-width: 44px;
$accordion-header-checkbox-toggler-width-sm: 40px;
$accordion-header-checkbox-width: calc(100% - #{$accordion-header-checkbox-toggler-width});
$accordion-header-checkbox-width-sm: calc(100% - #{$accordion-header-checkbox-toggler-width-sm});
$accordion-header-checkbox-position-top: calc(
  ((#{$accordion-title-font-size} * #{$accordion-title-line-height}) / 2) - (#{$check-radio-size} / 2)
);
$accordion-header-checkbox-position-top-sm: calc(
  ((#{$accordion-title-font-size-sm} * #{$accordion-title-line-height-sm}) / 2) - (#{$check-radio-size} / 2)
);
$accordion-header-checkbox-title-padding-left: $check-radio-gap;
$accordion-controls-padding-y: 8.5px;
$accordion-controls-padding-left: $accordion-header-checkbox-toggler-width;
$accordion-controls-padding-right: $accordion-header-padding-inside-x;
$accordion-controls-padding-left-sm: $accordion-header-checkbox-toggler-width-sm;
$accordion-controls-checkbox-label-font-size: $font-size-sm;
$accordion-controls-checkbox-position-top: calc(
  ((#{$accordion-controls-checkbox-label-font-size} * #{$label-line-height}) / 2) - (#{$check-radio-size} / 2)
);
$accordion-controls-bg: $gray-100;
$accordion-menu-position-top: calc(((#{$accordion-title-font-size} * #{$accordion-title-line-height}) / 2) - (#{$btn-font-size} / 2));
$accordion-menu-position-top-sm: calc(
  ((#{$accordion-title-font-size-sm} * #{$accordion-title-line-height-sm}) / 2) - (#{$btn-font-size} / 2)
);
$accordion-checkboxes-row-padding-y: 10px;
$accordion-checkboxes-row-padding-right: $accordion-header-padding-inside-y;
$accordion-checkboxes-row-padding-left: $accordion-header-checkbox-toggler-width;
$accordion-checkboxes-row-padding-left-sm: $accordion-header-checkbox-toggler-width-sm;
$accordion-checkboxes-row-label-padding-left: $accordion-header-checkbox-title-padding-left;
$accordion-checkboxes-row-selected-border-color: $table-grid-row-selected-border-color;
$accordion-checkboxes-row-selected-bg-color: $table-grid-row-selected-bg;
// End of Accordion

// List select (styles are associated with Accordion component)
$list-select-table-border-width: $accordion-border-width;
$list-select-table-border-color: $accordion-border-color;
$list-select-table-invalid-border-color: $table-invalid-border-color;
$list-select-table-border-radius: 0;
$list-select-table-row-padding-x: 7px;
$list-select-table-row-selected-border-color: $table-grid-row-selected-border-color;
$list-select-table-row-selected-bg-color: $table-grid-row-selected-bg;
$list-select-table-cell-padding-y: 9px;
$list-select-table-cell-padding-x: 7px;
$list-select-list-border-width: $list-select-table-border-width;
$list-select-list-border-color: $list-select-table-border-color;
$list-select-list-invalid-border-color: $list-select-table-invalid-border-color;
$list-select-list-border-radius: $accordion-border-radius;
$list-select-list-row-padding-x: $list-select-table-row-padding-x;
$list-select-list-row-selected-border-color: $list-select-table-row-selected-border-color;
$list-select-list-row-selected-bg-color: $list-select-table-row-selected-bg-color;
$list-select-list-cell-padding-y: $list-select-table-cell-padding-y;
$list-select-list-cell-padding-x: $list-select-table-cell-padding-x;
$list-select-controls-bg: $gray-100;
$list-select-controls-border-width: $list-select-table-border-width;
$list-select-controls-border-color: $list-select-table-border-color;
$list-select-controls-padding-y: $accordion-controls-padding-y;
$list-select-controls-padding-x: calc(#{$list-select-table-row-padding-x} + #{$list-select-table-cell-padding-x});
$list-select-controls-checkbox-label-gap: 2 * $list-select-table-cell-padding-x;
$list-select-controls-checkbox-label-font-size: $accordion-controls-checkbox-label-font-size;
// End of List select

// MBS card
$card-border-width: $border-width;
$card-border-color: $border-color;
$card-border-radius: $border-radius;
$card-bg: $white;
$card-ico-size: 20px;
$card-ico-margin-right: 20px;
$card-header-margin-bottom: 16px;
$card-content-padding-y: 29px;
$card-content-padding-x: 35px;
$card-group-padding-y: 30px;
$card-group-header-margin-bottom: 7px;
$card-header-margin-bottom-sm: 12px;
$card-content-padding-y-sm: 14px;
$card-content-padding-x-sm: 21px;
$card-header-margin-bottom-xs: 12px;
$card-content-padding-y-xs: 12px;
$card-content-padding-x-xs: 14px;
// End of MBS card

// Infoblock
$infoblock-padding-y: 21px;
$infoblock-padding-x: 28px;
$infoblock-border-radius: $border-radius;
$infoblock-title-margin-top: 0;
$infoblock-title-margin-bottom: 14px;
$infoblock-list-line-height: 1.79;
// End of Infoblock

// Preview
$preview-img-border-width: 2px;
$preview-img-border-color: $success;
// End of Preview

// Status
$status-ico-size: 16px;
$status-gap: 7px;
$status-ico-size-sm: 10px;
$status-gap-sm: 5px;
$status-ico-size-md: 12px;
$status-gap-md: 6px;
$status-ico-size-lmd: 14px;
$status-gap-lmd: 7px;
$status-ico-size-lg: 19px;
$status-gap-lg: 9px;
$status-disabled-color: $disabled-color;
$status-disabled-precentage: $disabled-percentage;
$status-darken-hover: $btn-bg-darken-hover;

// status size master list: 'sm' | 'md' | 'lmd' | 'lg'
$status-sizes: 'sm' $status-ico-size-sm $status-gap-sm, 'md' $status-ico-size-md $status-gap-md, 'lmd' $status-ico-size-lmd $status-gap-lmd,
  'lg' $status-ico-size-lg $status-gap-lg;
// End of Status

// Widgets
$widget-bg-default: $gray-200;
$widget-controls-control-size: 14px;
$widget-controls-margin-bottom: 7px;
$widget-padding-y: 21px;
$widget-padding-x: 21px;
$widget-header-margin-bottom: 1px;
$widget-text-font-size: $font-size-base;
$widget-text-line-height: 1.36;
// End of Widgets

// Sidepanel
$sidepanel-bg: $white;
$sidepanel-width: 480px;
$sidepanel-width-large: 680px;
$sidepanel-border-width: $border-width;
$sidepanel-border-color: $gray-300;
$sidepanel-header-padding-y: 14px;
$sidepanel-header-padding-x: 22px;
$sidepanel-header-bg: $gray-100;
$sidepanel-header-bg-light: $white;
$sidepanel-header-color: $gray-900;
$sidepanel-header-description-font-size: $font-size-sm;
$sidepanel-title-font-size: 18px;
$sidepanel-title-font-weight: $font-weight-semibold;
$sidepanel-title-letter-spacing: 0.03em;
$sidepanel-title-color: $gray-900;
$sidepanel-title-ico-size: 18px;
$sidepanel-title-ico-default-color: inherit !default;
$sidepanel-title-padding-right: 18px;
$sidepanel-footer-padding-y: 14px;
$sidepanel-footer-padding-x: 21px;
$sidepanel-footer-bg: $white;
$sidepanel-footer-col-padding-x: 4px;
$sidepanel-zindex: $zindex-fixed + 1;
$sidepanel-user-ico-color: $gray-600;
$sidepanel-user-ico-active-color: $purple;
$sidepanel-user-ico-font-size: 28px;
$sidepanel-user-name-color: $sidepanel-header-color;
$sidepanel-user-name-size: 14px;
$sidepanel-user-name-weight: $font-weight-bold;
$sidepanel-user-name-line-height: 1.5;
$sidepanel-zindex: $zindex-fixed;
$sidepanel-content-header-pdy: 1.5 * $gutter;
$sidepanel-content-header-pdx: $form-content-pdx;
$sidepanel-content-header-border-width: 1px;
$sidepanel-content-header-border-color: $border-color;
$sidepanel-content-title-font-size: 14px;
$sidepanel-content-title-font-weight: $font-weight-bold;
$sidepanel-content-inner-padding-y: $form-content-pdy;
$sidepanel-content-inner-padding-x: $form-content-pdx;
$sidepanel-vertical-tabs-width: 200px;
$sidepanel-secton-margin-top: 28px;
$sidepanel-secton-bordered-padding-top: 30px;
$sidepanel-secton-header-margin-bottom: 8px;
$sidepanel-section-header-ico-size: 24px;
$sidepanel-section-title-margin-y: 0;
$sidepanel-section-block-margin-top: 16px;
$sidepanel-section-block-header-margin-bottom: 5px;
$sidepanel-section-block-title-margin-y: 0;
$sidepanel-section-block-title-font-weight: $font-weight-normal;
$sidepanel-section-block-title-line-height: 1.6;
$sidepanel-section-list-line-height: 1.5;
$sidepanel-section-list-item-margin-top: 5px;
$sidepanel-section-list-ico-width: 22px;
$sidepanel-section-list-ico-size: inherit;
$sidepanel-section-block-p-margin-bottom: 14px;
$sidepanel-section-header-ico-padding-top: calc((#{$h5-font-size} * #{$h5-line-height} / 2) - (#{$sidepanel-section-header-ico-size} / 2));
$sidepanel-section-header-loader-position-top: calc((#{$sidepanel-section-header-ico-size} - #{$loader-size-lg}) / -2);
$sidepanel-box-shadow-offset-x: 2px;
$sidepanel-box-shadow-offset-x-right: -$sidepanel-box-shadow-offset-x;
$sidepanel-box-shadow-offset-y: 0;
$sidepanel-box-shadow-blur-radius: 2px;
$sidepanel-box-shadow-color: rgba($black, 0.06);
$sidepanel-position-offset: calc(
  -1 * (#{$sidepanel-width} + #{$sidepanel-border-width} + #{$sidepanel-box-shadow-offset-x} + #{$sidepanel-box-shadow-blur-radius})
);
$sidepanel-position-offset-lg: calc(
  -1 * (#{$sidepanel-width-large} + #{$sidepanel-border-width} + #{$sidepanel-box-shadow-offset-x} + #{$sidepanel-box-shadow-blur-radius})
);
$sidepanel-position-animation-duration: 0.5s;

// Sidepanel tabset
$sidepanel-vertical-tabset-width: 200px;
$sidepanel-vertical-tabset-content-width: $sidepanel-width-large - $sidepanel-vertical-tabset-width;
// End of Sidepanel

//
// ***
// End of Components
// ***
//
