@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.mbs-table {
  &_filter {
    .ctrl {
      align-items: center;
    }
  }
  &_pagination {
    .page-link {
      display: inherit;
    }
  }
}

.gapps-table {
  &-cell {
    &_ico {
      width: cv.$gapps-table-ico-size;
    }
  }
}

mbs-table {
  .table-simple {
    .mbs-table-grid_row {
      &:first-child {
        border-top: none;
      }
      &:first-child:last-child,
      &:last-child {
        border-bottom: none;
      }
    }
    .mbs-table-grid_cell {
      border-top: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
