@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use 'variables' as cv;

$componentSelector: mbs-stepper;

.#{$componentSelector} {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &_step {
    display: flex;
    position: relative;
    flex-basis: 0;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    max-width: 100%;
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    box-shadow: none;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: cv.$stepper-border-width;
      background: cv.$stepper-border-color;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    &-indicator {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border: cv.$stepper-border-width solid cv.$stepper-border-color;
      border-radius: 50%;
    }

    &:focus,
    &:hover {
      border: 0;
      outline: 0;
      box-shadow: none;
    }

    // Conditions
    @each $condition, $border-color, $bg in cv.$step-conditions {
      &.-#{$condition} {
        .#{$componentSelector}_step {
          &-indicator {
            border-color: $border-color;
            background: $bg;
          }
        }
      }
    }

    &.-completed {
      .#{$componentSelector}_step-indicator {
        &:before {
          @include m.icomoon();
          content: cv.$stepper-indicator-completed-icon;
          color: cv.$stepper-indicator-completed-icon-color;
        }
      }
    }

    &.-active {
      cursor: default;
      .#{$componentSelector}_step-title {
        font-weight: cv.$stepper-title-active-font-weight;
      }
    }

    &.-disabled {
      @extend %disabled-element;

      .#{$componentSelector}_step-title {
        color: cv.$stepper-title-disabled-color;
      }
    }
    // End Conditions

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  // Sizes
  @each $size, $gap, $space, $indicator-size, $indicator-completed-icon-size, $title-size in cv.$stepper-sizes {
    &.-#{$size} {
      .#{$componentSelector} {
        &_step {
          gap: $gap;

          &-indicator {
            width: $indicator-size;
            height: $indicator-size;
          }

          &-title {
            @include m.padding-x($space);
            font-size: $title-size;
          }

          &:before,
          &:after {
            top: calc((#{$indicator-size} / 2) - (cv.$stepper-border-width / 2));
            width: calc(50% - (#{$indicator-size} / 2) - #{$space});
          }

          &.-completed {
            .#{$componentSelector}_step-indicator {
              &:before {
                font-size: $indicator-completed-icon-size;
              }
            }
          }
        }
      }
    }
  }
  // End sizes
}
