@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

.mbs-widget {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: v.$widget-bg-default;
  text-decoration: none;

  &_inner {
    display: flex;
    z-index: 10;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: v.$widget-padding-y v.$widget-padding-x;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &_header {
    margin-bottom: v.$widget-header-margin-bottom;
  }

  &_title {
    @include m.textOverflow();
    margin-bottom: 0;
  }

  &_text {
    font-size: v.$widget-text-font-size;
    line-height: v.$widget-text-line-height;
  }

  &_controls {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: v.$widget-controls-margin-bottom;
  }

  &_button {
    display: block;
    width: 100%;

    &-img {
      width: 100%;
    }
  }

  &:hover {
    text-decoration: none;
  }
}
