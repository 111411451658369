// Element that stays visible if parent element is hovered.
// Target should alyways be inside parent.
._hover-parent {
  ._hover-target {
    visibility: hidden;
  }

  &:hover {
    ._hover-target {
      visibility: visible;
    }
  }
}
