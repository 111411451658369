@use '../../basic/variables' as v;
@use '../../basic/functions' as f;
@use 'hover' as h;
@use 'gradients' as gr;
@use 'box-shadow' as bsh;
@use 'border-radius' as br;

@import 'bootstrap/scss/functions';

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 12.5%)
) {
  border-color: $border;
  color: f.color-contrast($background);
  @include gr.gradient-bg($background);
  @include bsh.box-shadow(v.$btn-box-shadow);

  @include h.hover() {
    border-color: $hover-border;
    color: f.color-contrast($hover-background);
    @include gr.gradient-bg($hover-background);
  }

  &:focus,
  &.focus {
    border-color: $hover-border;
    color: f.color-contrast($hover-background);
    @include gr.gradient-bg($hover-background);
    @if v.$enable-shadows {
      @include bsh.box-shadow(v.$btn-box-shadow, 0 0 0 v.$btn-focus-width rgba(mix(f.color-contrast($background), $border, 15%), 0.5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 v.$btn-focus-width rgba(mix(f.color-contrast($background), $border, 15%), 0.5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    border-color: $border;
    background-color: $background;
    color: f.color-contrast($background);
    // Remove CSS gradients if they're enabled
    @if v.$enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background-color: $active-background;
    color: f.color-contrast($active-background);
    @if v.$enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }

    &:focus {
      @if v.$enable-shadows and v.$btn-active-box-shadow != none {
        @include bsh.box-shadow(
          v.$btn-active-box-shadow,
          0 0 0 v.$btn-focus-width rgba(mix(f.color-contrast($background), $border, 15%), 0.5)
        );
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 v.$btn-focus-width rgba(mix(f.color-contrast($background), $border, 15%), 0.5);
      }
    }
  }
}

@mixin button-outline-variant($color, $color-hover: f.color-contrast($color), $active-background: $color, $active-border: $color) {
  border-color: $color;
  color: $color;

  @include h.hover() {
    border-color: $active-border;
    background-color: $active-background;
    color: $color-hover;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 v.$btn-focus-width rgba($color, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background-color: $active-background;
    color: f.color-contrast($active-background);

    &:focus {
      @if v.$enable-shadows and v.$btn-active-box-shadow != none {
        @include box-shadow(v.$btn-active-box-shadow, 0 0 0 v.$btn-focus-width rgba($color, 0.5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 v.$btn-focus-width rgba($color, 0.5);
      }
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include br.border-radius($border-radius, 0);
}
