@use '../../basic/variables' as v;

.mbs-form_content {
  padding: v.$form-content-pdy v.$form-content-pdx;

  hr {
    margin-right: -1 * v.$form-content-pdx;
    margin-left: -1 * v.$form-content-pdx;
  }
}
