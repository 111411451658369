@import 'variables';

@mixin font-variant($lang, $style, $wName, $weight) {
  font-family: 'Open Sans';
  font-style: $style;
  font-weight: $weight;
  font-display: swap;
  src: local(#{'Open Sans #{$wName}'}), local(#{'OpenSans-#{$wName}'}), url('#{$main-font-path}/#{$main-font-family}-#{$lang}-#{$style}-#{$weight}.woff2') format('woff2');
}

@each $lang, $range in $languages {
  @each $style in $styles {
    @each $wName, $weight in $weights {
      @font-face {
        @include font-variant($lang, $style, $wName, $weight);

        unicode-range: $range;
      }
    }
  }
}
