@use '../../../basic/variables' as v;
@use '../../../basic/mixins' as m;
@use '../../../basic/mixins/border-radius' as br;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

@use 'variables' as cv;

@import 'node_modules/@ng-select/ng-select/scss/ng-select.component';

.mbs-select {
  .ng-select {
    position: relative;
    &.ng-select-opened {
      > .ng-select-container {
        border-color: cv.$select-border-width solid cv.$select-border-color;
        background: cv.$select-bg;
        &:hover {
          box-shadow: none;
        }

        .ng-arrow-wrapper {
          .ng-arrow {
            border-width: 0 cv.$select-arrow-size cv.$select-arrow-size;
            border-color: transparent transparent cv.$select-arrow-color;
          }

          &:hover {
            .ng-arrow {
              border-color: transparent transparent cv.$select-arrow-hover-color;
            }
          }
        }
      }

      &.ng-select-top {
        > .ng-select-container {
          @include br.border-top-radius(0);
        }
      }

      &.ng-select-right {
        > .ng-select-container {
          @include br.border-right-radius(0);
        }
      }

      &.ng-select-bottom {
        > .ng-select-container {
          @include br.border-bottom-radius(0);
        }
      }

      &.ng-select-left {
        > .ng-select-container {
          @include br.border-left-radius(0);
        }
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container,
      &.ng-select-searchable > .ng-select-container {
        border-color: cv.$select-focus-border-color;
        background-color: cv.$select-focus-bg;
        box-shadow: cv.$select-foxus-box-shadow;
      }
    }

    &.ng-select-disabled {
      @extend %disabled-element;

      * {
        @extend %disabled-element;
      }

      > .ng-select-container {
        background-color: cv.$select-disabled-bg;
        > .ng-value-container {
          color: cv.$select-disabled-color;
        }
      }

      .ng-arrow-wrapper,
      .ng-arrow {
        @extend %disabled-element;
      }
    }

    .ng-has-value .ng-placeholder {
      display: none;
    }

    &.ng-invalid.ng-touched {
      .ng-select-container {
        border-color: v.$danger;
      }
    }

    .ng-select-container {
      align-items: center;
      height: cv.$select-height;
      @include m.padding-x(cv.$select-padding-x);
      border: cv.$select-border-width solid cv.$select-border-color;
      @include br.border-radius(cv.$select-border-radius);
      background-color: cv.$select-bg;
      box-shadow: cv.$select-box-shadow;
      color: cv.$select-color;
      font-size: cv.$select-font-size;
      font-weight: cv.$select-font-weight;
      line-height: cv.$select-line-height;
      gap: 10px;

      .ng-value-container {
        align-items: center;

        .ng-placeholder {
          height: 100%;
          color: cv.$select-placeholder-color;
          line-height: cv.$select-height - (2 * cv.$select-border-width);
        }
      }
    }

    &.ng-select-single {
      .ng-select-container {
        height: cv.$select-height;
        .ng-value-container {
          .ng-placeholder {
            @include m.textOverflow();
          }
          .ng-input {
            top: 0;
            bottom: 0;
            padding-right: cv.$select-placeholder-padding-right;
            padding-left: cv.$select-padding-x;
            @include m.padding-y(cv.$select-padding-y);
          }
        }
      }

      &.ng-select-clearable {
        .ng-value-container {
          .ng-input {
            padding-right: 2 * cv.$select-placeholder-padding-right;
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          align-self: stretch;
          overflow: hidden;
          gap: cv.$select-multiple-values-gap;

          .ng-value {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: cv.$select-multiple-value-height;
            background: cv.$select-multiple-value-bg;
            color: cv.$select-multiple-value-color;
            gap: cv.$select-multiple-value-gap;
            @include m.padding-x(cv.$select-multiple-value-padding-x);
            @include br.border-radius(cv.$select-multiple-value-border-radius);

            .ng-value-label {
              font-size: cv.$select-multiple-value-font-size;
            }

            .ng-value-icon {
              display: flex;
              position: relative;
              align-items: center;
              align-self: stretch;
              justify-content: center;
              width: 9px;
              overflow: hidden;
              color: transparent;
              font-size: 0;

              &:before {
                @extend .ctrl;
                @extend .ctrl-xxs;
                @extend .ctrl-dark;
                @include m.icomoon();
                content: i.$ico-failed;
              }
            }
          }

          .ng-input {
            top: 0;
            bottom: 0;
            @include m.padding-y(cv.$select-padding-y);
            > input {
              color: cv.$select-color;
            }
          }

          .ng-placeholder {
            @include m.textOverflow(calc(100% - (3 * #{cv.$select-padding-x})));
            top: 0;
            bottom: 0;
            line-height: cv.$select-height - (2 * cv.$select-border-width);
          }

          .counter-label{
            @include m.textOverflow(calc(100% - (3 * #{cv.$select-padding-x})));
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.ng-select-disabled {
        > .ng-select-container {
          .ng-value-container {
            .ng-value {
              background-color: cv.$select-disabled-bg;
              color: cv.$select-disabled-color;
              .ng-value-label {
                padding: 0 cv.$select-padding-y;
              }
            }
          }
        }

        &.ng-select-multiple {
          .ng-select-container {
            .ng-value-container {
              .ng-value {
                background-color: cv.$select-multiple-value-bg-disabled;
                color: cv.$select-multiple-value-color-disabled;
              }
            }
          }
        }
      }
    }

    .ng-clear-wrapper {
      @extend .ctrl;
      @extend .ctrl-dark;
      @extend .ctrl-sm;
      display: flex;
      align-items: center;
      align-self: stretch;
      justify-content: center;
      width: auto;

      &:before {
        @include m.icomoon();
        content: i.$ico-Delete-text;
      }

      .ng-clear {
        display: none;
      }
    }

    .ng-arrow-wrapper {
      @extend .ctrl;
      display: flex;
      align-items: center;
      align-self: stretch;
      justify-content: center;

      .ng-arrow {
        border-width: cv.$select-arrow-size cv.$select-arrow-size 0;
        border-style: solid;
        border-color: cv.$select-arrow-color transparent transparent;
      }

      &:hover {
        .ng-arrow {
          border-color: cv.$select-arrow-hover-color transparent transparent;
        }
      }
    }

    .ng-spinner-loader {
      @extend %loader;
      margin-right: 0;
      border-width: v.$loader-border-width;
      border-style: solid;
      border-color: v.$loader-border-color;
      border-left-color: v.$loader-border-accent-color;
    }
  }

  @import 'sizes';
}

@import 'dropdown';
