@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;

@use 'variables' as cv;

.gapps-column-layout {
  &-container {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      left: 0;
      height: cv.$gapps-column-layout-border-width;
      background: cv.$gapps-column-layout-border-color;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 10;
      right: 0;
      bottom: 0;
      left: 0;
      height: cv.$gapps-column-layout-border-width;
      background: cv.$gapps-column-layout-border-color;
    }

    .mbs-loader {
      border: 0;
    }

    > [class*='col-'], > .col {
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        width: cv.$gapps-column-layout-border-width;
        background: cv.$gapps-column-layout-border-color;
      }

      &:after {
        @include media.media-breakpoint-down(sm) {
          content: '';
          display: block;
          position: absolute;
          z-index: 10;
          top: 0;
          right: 0;
          bottom: 0;
          width: cv.$gapps-column-layout-border-width;
          background: cv.$gapps-column-layout-border-color;
        }
      }

      &:last-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 10;
          top: 0;
          right: 0;
          bottom: 0;
          width: cv.$gapps-column-layout-border-width;
          background: cv.$gapps-column-layout-border-color;
        }
      }
    }
  }

  &-main {
    position: relative;
    border: 0;

    .gapps-table {
      &.-tiles {
        .mbs-tiles {
          padding: cv.$gapps-column-layout-tiles-padding;
        }
      }
    }

    &.-showInfoPanel {
      .gapps-table {
        position: relative;
        z-index: 20;

        @include media.media-breakpoint-up(md) {
          width: calc(100% + #{cv.$gapps-column-layout-border-width});
        }
      }
    }
  }

  &-info {
    min-height: cv.$gapps-column-layout-info-min-height;
  }

  &.resizing {
    user-select: none;
    cursor: col-resize;
  }
}

.mbs-resize-holder {
  cursor: col-resize;
  width: 10px;
  height: 100%;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 99;

  &.--reverse {
    left: -5px;
  }
}

.mbs-files-grid-item {
  height: cv.$files-grid-item-height;
  padding: cv.$files-grid-gap 1rem;
  border: 0;
  background: none;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    background: cv.$files-grid-item-active-background;
  }
  &_icon {
    width: cv.$files-grid-item-icon-width;
    max-width: 100%;
  }
}
