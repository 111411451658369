@use '../../basic/variables' as v;
@use 'mixins' as btn-m;

@each $size, $height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius in v.$btn-sizes {
  .btn-#{$size} {
    @include btn-m.button-size($height, $padding-y, $padding-x, $gap, $font-size, $line-height, $border-radius);
  }
}

// Loading state for .btn-xxs labelSize
.btn-xxs {
  &.-loading {
    &:after {
      @extend %loader-sm;
      top: calc(50% - (#{v.$loader-size-sm} / 2));
      left: calc(50% - (#{v.$loader-size-sm} / 2));
    }
  }
}
