@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

$calendar-full-size: 100%;
$calendar-border: v.$table-border-width solid v.$table-border-color;
$calendar-today-bg: mix(v.$primary, v.$white, 5%);
$calendar-header-bg: v.$gray-200;
$calendar-header-color: v.$gray-700;
$calendar-other-day-color: v.$gray-600;
$calendar-font-size-sm: v.$font-size-sm;
$calendar-font-size-xs: v.$font-size-xs;
$calendar-header-line-height: 1.37;
$calendar-tree-icon-margin-left: 0.07rem;
$calendar-event-margin-top: 0.07rem;
$calendar-event-margin: 0.14rem;
$calendar-daygrid-margin-x: 0.36rem;
$calendar-header-padding-x: 0.92rem;
$calendar-header-padding-y: 0.42rem;
$calendar-daygrid-event-padding-x: 0.5rem;
$calendar-timegrid-cushion-padding-x: 0.78rem;
$calendar-more-link-padding-x: 0.57rem;
$calendar-more-link-padding-y: 0.36rem;
$calendar-daygrid-padding-y: 0.57rem;
$calendar-daygrid-padding-x: 0.85rem;
$calendar-header-toolbar-padding-x: $calendar-daygrid-padding-x;
$calendar-header-toolbar-padding-y: 0.82rem;
$calendar-popover-padding-x: 1rem;
$calendar-more-popover-padding-top: 0.36rem;
$calendar-more-popover-padding-bottom: 1.85rem;
$calendar-more-popover-padding-x: 10px;
$calendar-more-popover-body-max-height: 250px;
$calendar-timegrid-label-padding-left: 2.93rem;
$calendar-day-height: 160px;
$calendar-event-height: 26px;
$calendar-timegrid-event-border-color: v.$white;
$calendar-timegrid-event-padding: 0.5rem;
$calendar-timegrid-30min-height: 36px;
$calendar-timegrid-position-top: 2px;
$calendar-ctrl-hover-color: v.$primary;
$calendar-popover-padding-y: 0.14rem;
$calendar-popover-padding-x: 0.36rem;
$calendar-popover-bg: v.$white;
$calendar-popover-header-color: v.$gray-700;
$calendar-popover-max-width: 350px;
$calendar-popover-arrow-border: rgba(0, 0, 0, 0.1);
$calendar-popover-positions: top, right, bottom, left;
$calendar-event-popover-padding: 0.75rem;
$calendar-event-border-radius: v.$border-radius;
$calendar-list-bg: v.$white;
$calendar-list-time-width: 155px;
$calendar-list-dot-min-width: 38px;
$calendar-list-hover-bg: $calendar-today-bg;
$calendar-tree-icon-size: 14px;
$calendar-tree-icon-border-radius: 2px;
$calendar-overlay-position-left: -1px;
$calendar-overlay-size: calc(100% + 1px);
$calendar-attachment-ico-color: v.$gray-900;
$calendar-attachment-ico-line-height: 1.2;
$calendar-attachment-padding-bottom: 0.14rem;
$calendar-attachment-link-line-height: 1.65;
