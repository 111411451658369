// Cursors
$cursor-types: (
  auto,
  default,
  none,
  context-menu,
  help,
  pointer,
  progress,
  wait,
  cell,
  crosshair,
  text,
  vertical-text,
  alias,
  copy,
  move,
  no-drop,
  not-allowed,
  all-scroll,
  col-resize,
  row-resize,
  n-resize,
  e-resize,
  s-resize,
  w-resize,
  ne-resize,
  nw-resize,
  se-resize,
  sw-resize,
  ew-resize,
  ns-resize,
  nesw-resize,
  nwse-resize,
  zoom-in,
  zoom-out,
  grab,
  grabbing
) !default;

@each $type in $cursor-types {
  .cursor-#{$type} {
    cursor: $type !important;
  }
}
