@use '../../../basic/variables' as v;
@use '../../../basic/functions' as f;

$datepicker-dropdown-padding-y: 4px;
$datepicker-dropdown-padding-x: 5px;

$datepicker-border-width: v.$border-width;
$datepicker-border-color: v.$border-color;

$datepicker-header-padding-y: 3px;
$datepicker-header-padding-x: 0;

$datepicker-navigation-gap: 4px;
$datepicker-navigation-arrow-height: v.$input-btn-height-xs;
$datepicker-navigation-arrow-width: $datepicker-navigation-arrow-height;
$datepicker-navigation-select-gap: 6px;
$datepicker-navigation-select-width: 130px;

$datepicker-footer-padding-y: 4px;
$datepicker-footer-padding-x: 6px;

// Datepicker items
$datepicker-item-color: v.$gray-900;
$datepicker-item-font-size: v.$font-size-xs;
$datepicker-item-hover-bg: v.$gray-400;
$datepicker-item-hover-color: f.color-contrast($datepicker-item-hover-bg);

$datepicker-item-outside-bg: null;
$datepicker-item-outside-color: v.$disabled-color;

$datepicker-item-current-bg: mix(v.$primary, v.$white, 20%);
$datepicker-item-current-color: f.color-contrast($datepicker-item-current-bg);

$datepicker-item-selected-bg: v.$primary;
$datepicker-item-selected-color: f.color-contrast($datepicker-item-selected-bg);

$datepicker-item-faded-bg: mix(v.$primary, v.$white, 50%);

$datepicker-item-focus-border-width: $datepicker-border-width;
$datepicker-item-focus-border-color: v.$input-focus-border-color;

$datepicker-item-disabled-bg: null;
$datepicker-item-disabled-color: v.$disabled-color;

// Month view datepicker
$datepicker-cell-box-size: 28px;

$datepicker-weekdays-padding-y: 0;
$datepicker-weekdays-padding-x: 0;
$datepicker-weekdays-bg: v.$gray-100;

$datepicker-weekday-color: v.$gray-600;
$datepicker-weekday-font-size: v.$font-size-xs;

$datepicker-day-weekend-bg: null;
$datepicker-day-weekend-color: $datepicker-item-color;

// Year view datepicker
$datepicker-months-grid-colums: 4;

$datepicker-month-width: 51px;
$datepicker-month-height: 28px;

// Plain
$datepicker-plain-padding-y: $datepicker-dropdown-padding-y;
$datepicker-plain-padding-x: $datepicker-dropdown-padding-x;
$datepicker-plain-border-radius: v.$border-radius;
