@use '../../basic/mixins' as m;
@use '../../basic/variables' as v;

.nav-submenu {
  z-index: v.$navbar-submenu-zindex;
  list-style: none;
  @include m.margin-y(0);

  .nav {
    &-submenu-link {
      display: block;
      width: 100%;
      border: 0;
      background: none;
      color: v.$navbar-light-link-color;
      font-size: v.$navbar-font-size;
      text-align: left;
      @include m.padding-y($dropdown-item-padding-y);
      @include m.padding-x($dropdown-item-padding-x);

      &_ico {
        display: inline-block;
        width: v.$navbar-submenu-ico-width;
        margin-right: v.$navbar-submenu-ico-mg-r;
        color: inherit;
        font-size: v.$navbar-link-ico-size;
        text-align: center;
      }

      &:hover,
      &:focus {
        outline: none;
      }

      &:hover {
        color: v.$navbar-light-link-active-color;
      }

      @include media-breakpoint-up(xl) {
        padding: {
          right: v.$navbar-link-pd-x;
          left: v.$navbar-link-pd-x;
        }
      }
    }

    &-submenu-item {
      @include m.padding(0);
      font-size: v.$navbar-font-size;
      text-align: left;

      > .nav-link {
        @include media-breakpoint-only(lg) {
          @include m.padding-x($navbar-link-pd-x-xs);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          @include m.padding-x($navbar-pd-x);
        }
      }

      &:active {
        background: v.$navbar-light-link-active-bg;
        color: v.$navbar-light-active-color;
      }

      &.active {
        background: v.$navbar-light-link-active-bg;
        box-shadow: v.$navbar-light-link-active-shadow;
        color: v.$navbar-light-link-active-color;

        > .nav-link {
          &:active,
          &:focus {
            outline: none;
            background: v.$navbar-light-link-active-bg;
            box-shadow: v.$navbar-light-link-active-shadow;
            color: v.$navbar-light-link-active-color;
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: left;
        @include m.padding-x(0);
      }

      @include media-breakpoint-only(lg) {
        .nav-link_ico {
          display: none;
        }
      }
    }
  }

  .mbs-navbar {
    &_userinfo {
      line-height: 1.5;

      &-item {
        display: block;
        white-space: nowrap;
        @include m.padding-y($dropdown-item-padding-y);
        @include m.padding-x($navbar-link-pd-x);
      }
    }
  }
}
