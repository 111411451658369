@use '../../basic/variables' as v;

.mbs-infoblock {
  padding: v.$infoblock-padding-y v.$infoblock-padding-x;
  border-radius: v.$infoblock-border-radius;

  &_title {
    margin-top: v.$infoblock-title-margin-top;
    margin-bottom: v.$infoblock-title-margin-bottom;
  }

  &_list {
    @extend .list-unstyled;
    line-height: v.$infoblock-list-line-height;
  }
}
