@use '../../../basic/variables' as v;
@use '../../../basic/mixins' as m;
@use 'variables' as cv;
@use 'node_modules/mbs-fonts/MBS_font/variables' as i;

$componentSelector: mbs-timepicker;
$innerSelector: ngb-tp;

.#{$componentSelector} {
  // Dropdown
  &_dropdown {
    padding: cv.$timepicker-dropdown-padding-y cv.$timepicker-dropdown-padding-x;

    .#{$innerSelector} {
      &-input {
        height: cv.$timepicker-input-height;
        @include m.padding-x(0);
        border: 0;
        font-size: cv.$timepicker-input-font-size;
        font-weight: cv.$timepicker-input-font-weight;
        text-align: center;

        &-container {
          align-items: stretch;
          width: cv.$timepicker-input-container-width;

          .btn.btn-link {
            @extend .ctrl;
            @extend .ctrl-primary;
            align-items: center;
            justify-content: center;
            height: cv.$timepicker-input-btn-height;

            .chevron {
              &:before {
                display: none;
              }

              &:after {
                @include m.icomoon();
                content: i.$ico-arrUp;
              }

              &.bottom {
                &:after {
                  content: i.$ico-arrDown;
                }
              }
            }
          }
        }
      }

      &-spacer {
        width: cv.$timepicker-spacer-widht;
        font-size: cv.$timepicker-spacer-font-size;
        font-weight: cv.$timepicker-spacer-font-weight;
        text-align: center;
      }

      &-meridian {
        .btn {
          height: cv.$timepicker-meridian-btn-height;
        }
      }
    }
  }
}
