@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

$gapps-column-layout-border-width: v.$table-border-width;
$gapps-column-layout-border-color: v.$table-border-color;
$gapps-column-layout-border: $gapps-column-layout-border-width solid $gapps-column-layout-border-color;
$gapps-column-layout-info-min-height: calc(#{v.$mbs-loader-size} * 1.42); // 1.42 is the diagonal coefficient
$gapps-column-layout-tiles-padding: 1.5rem;

$files-grid-gap: v.$grid-gutter-width;
$files-grid-item-active-background: v.$gray-200;
$files-grid-item-height: 160px;
$files-grid-item-icon-width: 70px;
