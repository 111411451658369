@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

.mbs-table_pagination {
  .pagination {
    display: flex;
    align-items: center;
    min-height: v.$table-nav-inner-height;
    margin-bottom: 0;

    .page-item {
      .page-link {
        min-width: v.$table-pagination-link-height;
        height: v.$table-pagination-link-height;
        margin-left: 0;
        @include m.padding-y(0);
        @include m.padding-x(v.$table-pagination-link-padding-x);
        border: 0;
        color: v.$table-pagination-link-color;
        font-size: v.$table-pagination-link-font-size;
        line-height: v.$table-pagination-link-height;

        &:hover {
          background: none;
          color: darken(v.$table-pagination-link-color, 15%);
        }
        .arrow {
          color: v.$table-pagination-link-active-color;
          font-size: v.$table-pagination-arrow-font-size;
        }
      }

      &.active {
        .page-link {
          background: v.$table-pagination-link-active-bg;
          color: v.$table-pagination-link-active-color;
        }
      }

      &.disabled {
        .page-link {
          opacity: v.$table-pagination-link-disabled-opacity;
          cursor: v.$disabled-cursor;
          pointer-events: none;
        }
      }
    }
  }
}

.mbs-pagination-btn_refresh {
  .btn {
    @include m.padding-x(0);
    padding-top: 5px;
    display: block;
    width: v.$table-pagination-button_size;
    height: v.$table-pagination-button_size;
    font-size: v.$table-pagination-link-font-size;
    text-align: center;

    .ico {
      font-size: v.$table-pagination-link-font-size;
    }
  }
}

.mbs-pagination-btn_perpage {
  position: relative;
  z-index: v.$zindex-dropdown-container;

  .btn {
    padding-top: 3px;
    display: block;
    height: v.$table-pagination-button_size;
    font-size: v.$table-pagination-link-font-size;
    text-align: center;
  }
  .dropdown-toggle::after {
    vertical-align: middle;
  }
}
