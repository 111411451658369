@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?vmvsgd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?vmvsgd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?vmvsgd##{$icomoon-font-family}') format('svg');
  // url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?vmvsgd') format('woff2'),
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-warning-shield {
  &:before {
    content: $ico-warning-shield;
  }
}
.ico-success-shield {
  &:before {
    content: $ico-success-shield;
  }
}
.ico-wallet {
  &:before {
    content: $ico-wallet;
  }
}
.ico-powerShell {
  &:before {
    content: $ico-powerShell;
  }
}
.ico-bash {
  &:before {
    content: $ico-bash;
  }
}
.ico-triangle-o {
  &:before {
    content: $ico-triangle-o;
  }
}
.ico-snmp {
  &:before {
    content: $ico-snmp;
  }
}
.ico-calendar-alt {
  &:before {
    content: $ico-calendar-alt;
  }
}
.ico-hdd-solid {
  &:before {
    content: $ico-hdd-solid;
  }
}
.ico-space-bar {
  &:before {
    content: $ico-space-bar;
  }
}
.ico-exclamation-square {
  &:before {
    content: $ico-exclamation-square;
  }
}
.ico-SquareT {
  &:before {
    content: $ico-SquareT;
  }
}
.ico-SquareS {
  &:before {
    content: $ico-SquareS;
  }
}
.ico-GoogleDrive {
  &:before {
    content: $ico-GoogleDrive;
  }
}
.ico-Envelope {
  &:before {
    content: $ico-Envelope;
  }
}
.ico-external-link-square {
  &:before {
    content: $ico-external-link-square;
  }
}
.ico-circle {
  &:before {
    content: $ico-circle;
  }
}
.ico-exclamation-circle {
  &:before {
    content: $ico-exclamation-circle;
  }
}
.ico-plus-circle {
  &:before {
    content: $ico-plus-circle;
  }
}
.ico-minus-circle {
  &:before {
    content: $ico-minus-circle;
  }
}
.ico-question-circle {
  &:before {
    content: $ico-question-circle;
  }
}
.ico-info-circle {
  &:before {
    content: $ico-info-circle;
  }
}
.ico-chevron-circle-left {
  &:before {
    content: $ico-chevron-circle-left;
  }
}
.ico-chevron-circle-right {
  &:before {
    content: $ico-chevron-circle-right;
  }
}
.ico-chevron-circle-up {
  &:before {
    content: $ico-chevron-circle-up;
  }
}
.ico-chevron-circle-down {
  &:before {
    content: $ico-chevron-circle-down;
  }
}
.ico-play-circle {
  &:before {
    content: $ico-play-circle;
  }
}
.ico-pause-circle {
  &:before {
    content: $ico-pause-circle;
  }
}
.ico-circleArrowUp {
  &:before {
    content: $ico-circleArrowUp;
  }
}
.ico-arrow-circle-up {
  &:before {
    content: $ico-arrow-circle-up;
  }
}
.ico-circleArrowRight {
  &:before {
    content: $ico-circleArrowRight;
  }
}
.ico-arrow-circle-right {
  &:before {
    content: $ico-arrow-circle-right;
  }
}
.ico-circleArrowDown {
  &:before {
    content: $ico-circleArrowDown;
  }
}
.ico-arrow-circle-down {
  &:before {
    content: $ico-arrow-circle-down;
  }
}
.ico-circleArrowLeft {
  &:before {
    content: $ico-circleArrowLeft;
  }
}
.ico-arrow-circle-left {
  &:before {
    content: $ico-arrow-circle-left;
  }
}
.ico-stop-circle {
  &:before {
    content: $ico-stop-circle;
  }
}
.ico-failed {
  &:before {
    content: $ico-failed;
  }
}
.ico-times-circle {
  &:before {
    content: $ico-times-circle;
  }
}
.ico-success {
  &:before {
    content: $ico-success;
  }
}
.ico-check-circle {
  &:before {
    content: $ico-check-circle;
  }
}
.ico-error {
  &:before {
    content: $ico-error;
  }
}
.ico-error-circle {
  &:before {
    content: $ico-error-circle;
  }
}
.ico-Restart {
  &:before {
    content: $ico-Restart;
  }
}
.ico-listClock {
  &:before {
    content: $ico-listClock;
  }
}
.ico-ListClock {
  &:before {
    content: $ico-ListClock;
  }
}
.ico-listStar {
  &:before {
    content: $ico-listStar;
  }
}
.ico-ListStar {
  &:before {
    content: $ico-ListStar;
  }
}
.ico-creditDollar {
  &:before {
    content: $ico-creditDollar;
  }
}
.ico-CreditDollar {
  &:before {
    content: $ico-CreditDollar;
  }
}
.ico-Target {
  &:before {
    content: $ico-Target;
  }
}
.ico-RD {
  &:before {
    content: $ico-RD;
  }
}
.ico-RMM {
  &:before {
    content: $ico-RMM;
  }
}
.ico-Terminal {
  &:before {
    content: $ico-Terminal;
  }
}
.ico-boxClock {
  &:before {
    content: $ico-boxClock;
  }
}
.ico-BoxClock {
  &:before {
    content: $ico-BoxClock;
  }
}
.ico-Chat {
  &:before {
    content: $ico-Chat;
  }
}
.ico-Chart {
  &:before {
    content: $ico-Chart;
  }
}
.ico-EllipsisH {
  &:before {
    content: $ico-EllipsisH;
  }
}
.ico-EllipsisV {
  &:before {
    content: $ico-EllipsisV;
  }
}
.ico-EnvelopePaperclip {
  &:before {
    content: $ico-EnvelopePaperclip;
  }
}
.ico-ComputerUpload {
  &:before {
    content: $ico-ComputerUpload;
  }
}
.ico-ComputerDownload {
  &:before {
    content: $ico-ComputerDownload;
  }
}
.ico-InfoCloud {
  &:before {
    content: $ico-InfoCloud;
  }
}
.ico-InfoCircle {
  &:before {
    content: $ico-InfoCircle;
  }
}
.ico-MinusThin {
  &:before {
    content: $ico-MinusThin;
  }
}
.ico-PlusThin {
  &:before {
    content: $ico-PlusThin;
  }
}
.ico-GridView {
  &:before {
    content: $ico-GridView;
  }
}
.ico-ListView {
  &:before {
    content: $ico-ListView;
  }
}
.ico-i {
  &:before {
    content: $ico-i;
  }
}
.ico-BarChart {
  &:before {
    content: $ico-BarChart;
  }
}
.ico-Briefcase {
  &:before {
    content: $ico-Briefcase;
  }
}
.ico-Camera {
  &:before {
    content: $ico-Camera;
  }
}
.ico-Certificate {
  &:before {
    content: $ico-Certificate;
  }
}
.ico-ClockO {
  &:before {
    content: $ico-ClockO;
  }
}
.ico-Cloud {
  &:before {
    content: $ico-Cloud;
  }
}
.ico-Download {
  &:before {
    content: $ico-Download;
  }
}
.ico-Edit {
  &:before {
    content: $ico-Edit;
  }
}
.ico-EnvelopeSquare {
  &:before {
    content: $ico-EnvelopeSquare;
  }
}
.ico-Globe {
  &:before {
    content: $ico-Globe;
  }
}
.ico-Home {
  &:before {
    content: $ico-Home;
  }
}
.ico-Key {
  &:before {
    content: $ico-Key;
  }
}
.ico-Laptop {
  &:before {
    content: $ico-Laptop;
  }
}
.ico-Magic {
  &:before {
    content: $ico-Magic;
  }
}
.ico-Money {
  &:before {
    content: $ico-Money;
  }
}
.ico-PieChart {
  &:before {
    content: $ico-PieChart;
  }
}
.ico-Plug {
  &:before {
    content: $ico-Plug;
  }
}
.ico-Random {
  &:before {
    content: $ico-Random;
  }
}
.ico-rss {
  &:before {
    content: $ico-rss;
  }
}
.ico-Tasks {
  &:before {
    content: $ico-Tasks;
  }
}
.ico-Wrench {
  &:before {
    content: $ico-Wrench;
  }
}
.ico-Info {
  &:before {
    content: $ico-Info;
  }
}
.ico-Basket {
  &:before {
    content: $ico-Basket;
  }
}
.ico-Lock2 {
  &:before {
    content: $ico-Lock2;
  }
}
.ico-Gear {
  &:before {
    content: $ico-Gear;
  }
}
.ico-Office365 {
  &:before {
    content: $ico-Office365;
  }
}
.ico-GoogleApps {
  &:before {
    content: $ico-GoogleApps;
  }
}
.ico-Pencil {
  &:before {
    content: $ico-Pencil;
  }
}
.ico-Calendar {
  &:before {
    content: $ico-Calendar;
  }
}
.ico-Contact {
  &:before {
    content: $ico-Contact;
  }
}
.ico-Drive {
  &:before {
    content: $ico-Drive;
  }
}
.ico-MailBox {
  &:before {
    content: $ico-MailBox;
  }
}
.ico-OverdueSettings {
  &:before {
    content: $ico-OverdueSettings;
  }
}
.ico-Clock {
  &:before {
    content: $ico-Clock;
  }
}
.ico-Compare {
  &:before {
    content: $ico-Compare;
  }
}
.ico-Eye {
  &:before {
    content: $ico-Eye;
  }
}
.ico-Mail {
  &:before {
    content: $ico-Mail;
  }
}
.ico-Users {
  &:before {
    content: $ico-Users;
  }
}
.ico-User {
  &:before {
    content: $ico-User;
  }
}
.ico-Lock {
  &:before {
    content: $ico-Lock;
  }
}
.ico-arrLeft {
  &:before {
    content: $ico-arrLeft;
  }
}
.ico-arrRight {
  &:before {
    content: $ico-arrRight;
  }
}
.ico-arrUp {
  &:before {
    content: $ico-arrUp;
  }
}
.ico-arrDown {
  &:before {
    content: $ico-arrDown;
  }
}
.ico-Minus {
  &:before {
    content: $ico-Minus;
  }
}
.ico-Plus {
  &:before {
    content: $ico-Plus;
  }
}
.ico-Excel {
  &:before {
    content: $ico-Excel;
  }
}
.ico-Refresh {
  &:before {
    content: $ico-Refresh;
  }
}
.ico-FilesView {
  &:before {
    content: $ico-FilesView;
  }
}
.ico-PlanView {
  &:before {
    content: $ico-PlanView;
  }
}
.ico-Purge {
  &:before {
    content: $ico-Purge;
  }
}
.ico-Close {
  &:before {
    content: $ico-Close;
  }
}
.ico-Backup {
  &:before {
    content: $ico-Backup;
  }
}
.ico-OpenHistory {
  &:before {
    content: $ico-OpenHistory;
  }
}
.ico-Restore {
  &:before {
    content: $ico-Restore;
  }
}
.ico-tick {
  &:before {
    content: $ico-tick;
  }
}
.ico-Cloud-backup {
  &:before {
    content: $ico-Cloud-backup;
  }
}
.ico-local-storage {
  &:before {
    content: $ico-local-storage;
  }
}
.ico-ec2linux {
  &:before {
    content: $ico-ec2linux;
  }
}
.ico-hyperv {
  &:before {
    content: $ico-hyperv;
  }
}
.ico-windows {
  &:before {
    content: $ico-windows;
  }
}
.ico-databased {
  &:before {
    content: $ico-databased;
  }
}
.ico-vmware {
  &:before {
    content: $ico-vmware;
  }
}
.ico-exchange {
  &:before {
    content: $ico-exchange;
  }
}
.ico-ec2mac {
  &:before {
    content: $ico-ec2mac;
  }
}
.ico-ec2gear {
  &:before {
    content: $ico-ec2gear;
  }
}
.ico-ec2box {
  &:before {
    content: $ico-ec2box;
  }
}
.ico-ec2drive {
  &:before {
    content: $ico-ec2drive;
  }
}
.ico-ec2win {
  &:before {
    content: $ico-ec2win;
  }
}
.ico-ec2android {
  &:before {
    content: $ico-ec2android;
  }
}
.ico-ec2server {
  &:before {
    content: $ico-ec2server;
  }
}
.ico-glacier {
  &:before {
    content: $ico-glacier;
  }
}
.ico-azurewin {
  &:before {
    content: $ico-azurewin;
  }
}
.ico-ec2 {
  &:before {
    content: $ico-ec2;
  }
}
.ico-consistencycheck {
  &:before {
    content: $ico-consistencycheck;
  }
}
.ico-file {
  &:before {
    content: $ico-file;
  }
}
.ico-imagebased {
  &:before {
    content: $ico-imagebased;
  }
}
.ico-arrowLeftSm {
  &:before {
    content: $ico-arrowLeftSm;
  }
}
.ico-arrowRightSm {
  &:before {
    content: $ico-arrowRightSm;
  }
}
.ico-arrowUpSm {
  &:before {
    content: $ico-arrowUpSm;
  }
}
.ico-arrowDownSm {
  &:before {
    content: $ico-arrowDownSm;
  }
}
.ico-cross {
  &:before {
    content: $ico-cross;
  }
}
.ico-ok {
  &:before {
    content: $ico-ok;
  }
}
.ico-arrowDown {
  &:before {
    content: $ico-arrowDown;
  }
}
.ico-Delete-text {
  &:before {
    content: $ico-Delete-text;
  }
}
.ico-Search {
  &:before {
    content: $ico-Search;
  }
}
.ico-SearchNext {
  &:before {
    content: $ico-SearchNext;
  }
}
