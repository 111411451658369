@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

mbs-accordion {
  &.sm {
    .mbs-accordion_content {
      padding-top: cv.$gapps-accordion-content-padding-y-sm;
      padding-bottom: cv.$gapps-accordion-content-padding-y-sm;
    }
  }
}
