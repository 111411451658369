@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'variables' as cv;

.gapps-tree {
  height: 100%;

  .mbs-tree-item_label {
    > * {
      padding-top: cv.$gapps-tree-label-padding-y;
      padding-bottom: cv.$gapps-tree-label-padding-y;
      line-height: v.$line-height-base;
    }
  }

  &-item {
    &_ico {
      width: cv.$gapps-tree-ico-size;
    }

    &_text {
      max-width: cv.$gapps-tree-text-max-width !important;
      line-height: v.$line-height-base;
    }
  }

  .cdk-virtual-scroll-content-wrapper {
    padding: inherit;
  }
}
