@use '../../basic/variables' as v;

.mbs-tags-input {
  &_box {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    min-width: 100%;
    padding: 5px;
    border: 1px solid v.$gray-400;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 0.25rem;

    &.-focused {
      border-color: #abc4da;
      color: #252525;
      box-shadow: 0 0 7px rgba(45, 108, 162, 0.3);
    }

    &.-error {
      border-color: v.$danger;
    }
  }

  &_extend-button {
    background-color: v.$white;
    padding: 0 6px;
    position: absolute;
    bottom: 0.75em;
    right: 0;
  }

  &_input {
    margin: 5px 0;
    flex: 1 0 150px;
    border: none;

    &:focus, &:focus-visible {
      outline: none;
    }
  }

  & mbs-tag {
    margin: 5px 5px 5px 0;

    &.-error {
      color: v.$danger;

      & .ico {
        color: v.$danger;
      }
    }
  }

  &_dropdown {
    max-height: 290px;
    overflow-y: auto;
  }

  &_dropdown-empty {
    display: none;
  }

  .dropdown-toggle::after {
    display:none;
  }
}
