@use '../../../basic/variables' as v;

textarea.form-control {
  min-height: v.$input-height;
}

@mixin textarea-sizes(
  $size,
  $input-height,
  $input-padding-y,
  $input-padding-x,
  $input-font-size,
  $input-line-height,
  $input-border-radius
) {
  min-height: $input-height;
}
@each $size, $input-height, $input-padding-y, $input-padding-x, $input-font-size, $input-line-height, $input-border-radius in v.$input-sizes
{
  textarea.form-control-#{$size} {
    @include textarea-sizes(
      $size,
      $input-height,
      $input-padding-y,
      $input-padding-x,
      $input-font-size,
      $input-line-height,
      $input-border-radius
    );
  }
}
