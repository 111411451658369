@use '../variables' as v;

@mixin alert-variant($color) {
  border-color: mix($color, v.$black, v.$alert-darken-level);
  background: mix($color, v.$white, v.$alert-lighten-level);
  color: v.$alert-color;

  hr {
    border-top-color: mix($color, v.$black, v.$alert-darken-level);
  }
}
