@use '../../basic/variables' as v;

$componentSelector: mbs-form-group;

.#{$componentSelector} {
  display: block;
  margin-bottom: v.$form-group-margin-bottom;

  .mbs-form-subgroup {
    padding-top: v.$form-subgroup-padding-top;
  }

  &_title {
    margin-bottom: v.$form-group-title-margin-bottom;
  }

  &.row {
    display: flex;
  }
}
