@use '../../basic/variables' as v;
@use '../../basic/functions' as f;

@mixin button-variant(
  $background,
  $border: darken($background, v.$btn-border-darken),
  $hover-background: darken($background, v.$btn-bg-darken-hover),
  $hover-border: darken($background, v.$btn-border-darken-hover),
  $focus-background: darken($hover-background, v.$btn-bg-darken-focus),
  $focus-border: darken($hover-border, v.$btn-border-darken-focus),
  $active-background: darken($focus-background, v.$btn-bg-darken-active),
  $active-border: darken($focus-background, v.$btn-border-darken-active),
  $disabled-background: mix($background, v.$white, v.$btn-disabled-percentage),
  $disabled-border: mix($border, v.$white, v.$btn-disabled-percentage),
  $disabled-color: mix(f.color-contrast($background), v.$white, v.$btn-disabled-percentage)
) {
  border-color: $border;
  background: $background;
  color: f.color-contrast($background);

  @include hover-focus() {
    box-shadow: none;
  }

  &:hover,
  &.hover {
    border-color: $hover-border;
    background: $hover-background;
    color: f.color-contrast($background);
  }

  &:focus,
  &.focus {
    border-color: $focus-border;
    background: $focus-background;
    color: f.color-contrast($background);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    border-color: $disabled-border;
    background: $disabled-background;
    color: $disabled-color;
    $color: f.color-contrast($background);

    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    border-color: $active-border;
    background: $active-background;
    color: f.color-contrast($active-background);

    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.-loading {
    color: transparent !important;
    &:after {
      border-color: transparentize(f.color-contrast($background), 0.4);
      border-left-color: f.color-contrast($background);
    }

    * {
      color: transparent !important;
    }
  }
}

@each $color, $value in v.$theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}
