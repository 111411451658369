@use '../../basic/variables' as v;

.mbs-stub {
  padding: v.$stub-pd-y v.$stub-pd-x;
  text-align: center;

  &_icon {
    padding-bottom: v.$stub-icon-pd-b;
    text-align: center;

    &-img {
      max-width: v.$stub-icon-max-width;
      height: auto;
    }
  }

  &_title {
    color: v.$stub-title-color;
    font-size: v.$stub-title-font-size;
    font-weight: v.$stub-title-font-weight;
  }

  &_content {
    color: v.$stub-content-color;
    font-size: v.$stub-content-font-size;
  }

  &_images {
    padding-top: v.$stub-images-pdt;
  }

  &_img {
    display: block;
    width: 100%;

    &-block {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-caption {
      display: flex;
      align-items: center;
      padding: v.$stub-image-block-pd-y v.$stub-image-block-pd-x;
      font-size: v.$stub-image-caption-font-size;
      text-align: left;
    }

    &-counter {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: v.$stub-counter-size;
      height: v.$stub-counter-size;
      margin-right: v.$stub-counter-mg-r;
      border: v.$stub-counter-border-width solid v.$stub-counter-border-color;
      border-radius: 50%;
      color: v.$stub-counter-color;
      font-size: v.$stub-counter-font-size;
      font-weight: v.$stub-counter-font-weight;
    }
  }
}
