@use '../variables' as v;
@use '../functions' as f;
@use 'box-shadow' as bsh;
@use 'border-radius' as br;

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus($ignore-warning: false, $background-color: v.$input-focus-bg) {
  &:focus,
  &.focus,
  &.-focus {
    border-color: v.$input-focus-border-color;
    outline: 0;
    background-color: $background-color;
    color: v.$input-focus-color;
    @if v.$enable-shadows {
      @include bsh.box-shadow(v.$input-box-shadow, v.$input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: v.$input-focus-box-shadow;
    }
  }
}

@mixin form-validation($color) {
  border-color: $color;

  &:focus {
    border-color: $color;
    box-shadow: 0 0 v.$input-focus-width rgba($color, v.$input-btn-focus-persentage);
  }
}

// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details
@mixin form-validation-state-selector($state) {
  @if ($state == 'valid' or $state == 'invalid') {
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state},
    .ng-touched.ng-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@mixin form-validation-state($state, $color, $icon) {
  .#{$state}-feedback {
    display: block;
    width: 100%;
    margin-top: v.$form-feedback-margin-top;
    color: $color;
    font-size: v.$form-feedback-font-size;
  }

  .#{$state}-tooltip {
    display: none;
    position: absolute;
    z-index: 5;
    top: 100%;
    left: 0;
    max-width: 100%; // Contain to parent when possible
    margin-top: 0.1rem;
    padding: v.$form-feedback-tooltip-padding-y v.$form-feedback-tooltip-padding-x;
    background-color: rgba($color, v.$form-feedback-tooltip-opacity);
    color: f.color-contrast($color);
    font-size: v.$form-feedback-tooltip-font-size;
    line-height: v.$form-feedback-tooltip-line-height;
    @include br.border-radius(v.$form-feedback-tooltip-border-radius);

    // See https://github.com/twbs/bootstrap/pull/31557
    // Align tooltip to form elements
    .form-row > .col > &,
    .form-row > [class*='col-'] > & {
      left: v.$form-grid-gutter-width * 0.5;
    }
  }

  @include form-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ .#{$state}-tooltip {
      display: block;
    }
  }

  .ng-state-#{$state} {
    @include form-validation($color);
  }

  .form-control,
  .mbs-select_ng-select {
    &.ng-dirty.ng-#{$state} {
      @include form-validation($color);

      @if v.$enable-validation-icons {
        padding-right: v.$input-height-inner !important; // stylelint-disable-line declaration-no-important
        background-image: escape-svg($icon);
        background-repeat: no-repeat;
        background-position: right v.$input-height-inner-quarter center;
        background-size: v.$input-height-inner-half v.$input-height-inner-half;
      }

      .ng-select-container.ng-has-value {
        @include form-validation($color);
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  select.form-control {
    @include form-validation-state-selector($state) {
      @if v.$enable-validation-icons {
        padding-right: v.$input-padding-x * 4 !important; // stylelint-disable-line declaration-no-important
        background-position: right v.$input-padding-x * 2 center;
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.form-control {
    @include form-validation-state-selector($state) {
      @if v.$enable-validation-icons {
        padding-right: v.$input-height-inner;
        background-position: top v.$input-height-inner-quarter right v.$input-height-inner-quarter;
      }
    }
  }
}
