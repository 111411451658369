.btn-link {
  color: $link-color;
  font-weight: $font-weight-normal;
  text-decoration: $link-decoration;
  user-select: auto;

  @include hover-focus {
    outline: none;
    box-shadow: none;
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    @extend %disabled-element;
    color: $btn-link-disabled-color;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      box-shadow: none;

      &:hover,
      &:focus,
      &.focus {
        box-shadow: none;
      }
    }
  }

  &.-loading {
    color: transparent !important;
    &:after {
      border-color: $loader-border-color;
      border-left-color: $loader-border-accent-color;
    }

    * {
      color: transparent !important;
    }
  }

  // No need for an active state here
}
