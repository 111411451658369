﻿@use 'variables' as v;

.mbs-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;

  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh;
    &:not(.mbs-inner-streight-content) {
      min-width: v.$min-width;
    }
  }

  &-content {
    height: 100%;
    padding: v.$container-content-pd-t v.$container-content-pd-x v.$container-content-pd-b;
  }
}
