@use '../../basic/variables' as v;

$componentSelector: mbs-status;

.#{$componentSelector} {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: v.$status-gap;

  &-ico {
    font-size: v.$status-ico-size;

    @each $size, $ico-size, $gap in v.$status-sizes {
      &.-#{$size} {
        font-size: $ico-size;
      }
    }
  }

  // Sizes
  @each $size, $ico-size, $gap in v.$status-sizes {
    &.-#{$size} {
      gap: $gap;

      .#{$componentSelector}_ico {
        &.-#{$size} {
          font-size: $ico-size;
        }
      }
    }
  }

  &.-disabled {
    @extend %disabled-element;
    color: v.$status-disabled-color;

    @each $color, $value in v.$theme-colors {
      .text-#{$color} {
        color: mix($value, v.$white, v.$status-disabled-precentage) !important;
      }
    }

    @each $color, $value in v.$grays {
      .text-#{$color} {
        color: mix($value, v.$white, v.$status-disabled-precentage) !important;
      }
    }
  }

  &.-clickable:not(.-disabled) {
    cursor: pointer;

    &:hover {
      @each $color, $value in v.$theme-colors {
        .text-#{$color} {
          color: darken($value, v.$status-darken-hover) !important;
        }
      }

      @each $color, $value in v.$grays {
        .text-#{$color} {
          color: darken($value, v.$status-darken-hover) !important;
        }
      }
    }
  }

  &.-reverse {
    flex-direction: row-reverse;
  }
}
