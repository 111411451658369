@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

$componentSelector: mbs-list-select;

.#{$componentSelector} {
  &_controls {
    @include m.padding-x(v.$list-select-controls-padding-x);
    @include m.padding-y(v.$list-select-controls-padding-y);
    border: v.$list-select-controls-border-width solid v.$list-select-controls-border-color;
    background: v.$list-select-controls-bg;

    &-checkbox {
      .mbs-checkbox {
        &_input {
          + .mbs-checkbox_label {
            gap: v.$list-select-controls-checkbox-label-gap;
            font-size: v.$list-select-controls-checkbox-label-font-size;
            line-height: v.$check-radio-min-height;
          }
        }
      }
    }
  }
}

@import 'table-table';
@import 'table-list';
