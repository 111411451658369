@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

.nav.nav-tabs {
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-right: v.$nav-tabs-padding-x;
  padding-left: v.$nav-tabs-padding-x;
  border-bottom: v.$nav-tabs-border-width solid v.$nav-tabs-link-active-border-color;
  background: v.$nav-tabs-bg-color;

  .nav {
    &-item {
      margin-bottom: -1 * v.$nav-tabs-border-width;

      &_index {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: v.$nav-tabs-item-index-size;
        height: v.$nav-tabs-item-index-size;
        margin-right: v.$nav-tabs-item-index-margin-r;
        border-radius: 50%;
        background: v.$nav-tabs-item-index-bg;
        color: v.$nav-tabs-item-index-color;
        font-size: v.$nav-tabs-item-index-font-size;
        font-weight: v.$nav-tabs-item-index-font-weight;
      }
    }

    &-link {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      border: v.$nav-tabs-border-width solid v.$nav-tabs-border-color;
      background: transparent;
      color: v.$nav-tabs-link-color;
      font-size: v.$nav-tabs-link-font-size;
      font-weight: v.$nav-tabs-link-font-weight;
      line-height: v.$nav-tabs-link-line-height;
      @include br.border-top-radius(v.$nav-tabs-border-radius);

      &:hover {
        color: v.$nav-tabs-link-hover-color;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &.active {
        border: v.$nav-tabs-border-width solid v.$nav-tabs-link-active-border-color;
        border-bottom-color: v.$nav-tabs-link-active-bg;
        background: v.$nav-tabs-link-active-bg;
        color: v.$nav-tabs-link-active-color;

        .nav-item_index {
          background-color: v.$nav-tabs-item-active-index-bg;
          color: v.$nav-tabs-item-active-index-color;
        }

        &:hover {
          color: v.$nav-tabs-link-active-hover-color;
        }
      }

      &.success {
        .nav-item_index {
          background-color: v.$nav-tabs-item-success-index-bg;
          color: v.$nav-tabs-item-success-index-color;
        }
      }

      &.disabled {
        color: v.$nav-tabs-link-disabled-color;
        cursor: default;
      }
    }
  }

  // Vertical tabs (Left position is default)
  &.flex-column {
    align-items: stretch;
    padding-top: v.$nav-tabs-padding-y;
    padding-right: 0;
    padding-bottom: v.$nav-tabs-padding-y;
    border-right: v.$nav-tabs-border-width solid v.$nav-tabs-link-active-border-color;
    border-bottom: 0;

    .nav {
      &-item {
        margin-right: -1 * v.$nav-tabs-border-width;
        margin-bottom: 0;

        &_text {
          text-align: left;
        }

        &_alert {
          margin-left: auto;
        }
      }

      &-link {
        @include br.border-left-radius(v.$nav-tabs-border-radius);
        @include br.border-right-radius(0);

        &.active {
          border-right-color: v.$nav-tabs-link-active-bg;
          border-bottom-color: v.$nav-tabs-link-active-border-color;
        }
      }
    }

    // Right position for future (not implemented yet)
    &.-right {
      padding-right: v.$nav-tabs-padding-y;
      padding-left: 0;
      border-right: 0;
      border-left: v.$nav-tabs-border-width solid v.$nav-tabs-link-active-border-color;

      .nav {
        &-item {
          margin-right: 0;
          margin-left: -1 * v.$nav-tabs-border-width;

          &_text {
            text-align: right;
          }
        }

        &-link {
          @include br.border-left-radius(0);
          @include br.border-right-radius(v.$nav-tabs-border-radius);

          &.active {
            border-right-color: v.$nav-tabs-link-active-border-color;
            border-left-color: v.$nav-tabs-link-active-bg;
          }
        }
      }
    }
  }
}
