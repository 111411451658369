@use '../../basic/variables' as v;
@use '../../basic/mixins/transition' as transition;

.ctrl {
  display: inline-flex;
  align-items: baseline;
  padding: v.$ctrl-padding-y v.$ctrl-padding-x;
  border: v.$ctrl-border-width solid transparent;
  background-color: transparent;
  color: v.$body-color;
  font-family: v.$ctrl-font-family;
  font-size: v.$ctrl-font-size;
  font-weight: v.$ctrl-font-weight;
  line-height: v.$ctrl-line-height;
  text-align: center;
  vertical-align: middle;
  pointer-events: auto;
  gap: v.$ctrl-gap;
  @include transition.transition(v.$ctrl-transition);

  @include hover {
    color: v.$primary;
    text-decoration: none;
  }

  &:before,
  &:after {
    pointer-events: inherit;
  }

  * {
    pointer-events: inherit;

    &:before,
    &:after {
      pointer-events: inherit;
    }
  }

  &.tooltip-always {
    pointer-events: auto;
  }

  &-ico {
    color: inherit;
    font-size: inherit;
    line-height: 1;
    vertical-align: baseline;
  }

  &.active {
    cursor: default;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @extend %disabled-element;
    opacity: v.$ctrl-disabled-opacity;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
    &:active,
    &.active {
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.-loading {
    position: relative;
    color: transparent !important;
    text-shadow: none;

    &:after {
      @extend %loader-sm;
      content: '';
      position: absolute;
      top: calc(50% - (#{v.$loader-size-sm} / 2));
      left: calc(50% - (#{v.$loader-size-sm} / 2));
    }

    * {
      color: transparent !important;
    }
  }
}

@import 'sizes';
@import 'variants';
@import 'link';

mbs-button {
  &.-isCtrl {
    display: inline-flex;
  }
}
