@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;

// Footer
$footer-height: 100px;

// Page
$page-content-padding-top: 14px;
$page-content-padding-bottom: 21px;
$page-min-height: calc(100vh - #{v.$header-height} - #{$footer-height});

// Toasts
$toasts-position-bottom: 60px;
$toasts-position-bottom-sm: 35px;
