@use 'sass:math';
@use '../../../basic/variables' as v;
@use '../../../basic/mixins' as m;
@import '../placeholders';

%check-radio {
  display: block;

  & + & {
    margin-top: v.$check-radio-margin-top;
  }
}

%check-radio-container {
  display: flex;
  position: relative;
  min-height: v.$check-radio-min-height;

  > *,
  .ctrl {
    line-height: v.$check-radio-label-line-height;
  }
}

%check-radio-input-hidden {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

%check-radio-label {
  display: flex;
  gap: v.$check-radio-gap;
  position: relative;
  margin-bottom: 0;
  color: v.$check-radio-label-color;

  &.-disabled {
    color: v.$check-radio-label-disabled-color;
  }

  // Label Positioning
  &.-top {
    flex-direction: column-reverse;
  }

  &.-bottom {
    flex-direction: column;
  }

  &.-left {
    flex-direction: row-reverse;
  }

  &.-center {
    align-items: center;
  }
}

%check-radio-label-content {
  position: relative;

  @each $font-size, $font-value in v.$font-sizes {
    $v1: -1 * v.$check-radio-input-offset-top; // 2px
    $v2: math.div(($font-value * v.$check-radio-label-line-height), 2); // 9px
    $v3: math.div(v.$check-radio-size, 2); // 8.5px
    &.text-#{$font-size} {
      top: -1 * ($v2 - $v3 + $v1);
    }
  }
}

%check-radio-label-content-sizes {
  @each $font-size, $font-value in v.$font-sizes {
    @each $size, $input-size, $min-height, $gap, $offset-top in v.$check-radio-sizes {
      $v1: -1 * $offset-top;
      $v2: math.div(($font-value * v.$check-radio-label-line-height), 2);
      $v3: math.div($input-size, 2);
      &.text-#{$font-size} {
        top: -1 * ($v2 - $v3 + $v1);
      }
    }
  }
}

%check-radio-input {
  content: '';
  display: flex;
  position: relative;
  top: v.$check-radio-input-offset-top;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: v.$check-radio-size;
  height: v.$check-radio-size;
}

@mixin check-radio-sizes($size, $input-size, $min-height, $gap, $offset-top) {
  &:before {
    top: $offset-top;
    width: $input-size;
    height: $input-size;
    line-height: $input-size;
  }
}

%check-radio-subtitle-description {
  @extend %subtitle-description;

  &.-inside {
    margin-left: v.$check-radio-subtitle-description-inside-margin-left;

    @each $size, $margin-left in v.$check-radio-subtitle-description-sizes {
      &.-#{$size} {
        margin-left: $margin-left;
      }
    }
  }
}

%check-radio-subtitle {
  @extend %subtitle;
}

// Checkbox
%check-input {
  @include m.icomoon();
  border: v.$check-input-border-width solid v.$check-radio-color-secondary;
  border-radius: v.$check-input-border-radius;
  background: v.$check-radio-color-primary;
  line-height: v.$check-radio-size;
}

%check-input-disabled {
  border-color: v.$check-radio-disabled-border-color;
  background: v.$check-radio-disabled-color-primary;
}

%check-input-accented {
  border-color: v.$check-radio-accent-color-primary;
  background: v.$check-radio-accent-color-primary;
  color: v.$check-radio-accent-color-secondary;
  font-size: v.$check-icon-size;
}

%check-input-accented-disabled {
  border-color: v.$check-radio-accent-disabled-color-primary;
  background: v.$check-radio-accent-disabled-color-primary;
}

@import 'checkbox';

// Radio
%radio-input {
  border: v.$radio-input-border-width solid v.$check-radio-color-secondary;
  border-radius: v.$radio-input-border-radius;
  background: v.$check-radio-color-primary;
}

%radio-input-disabled {
  border-color: v.$check-radio-disabled-border-color;
  background: v.$check-radio-disabled-color-primary;
}

%radio-input-accented {
  border-width: v.$radio-input-accented-border-width;
  border-color: v.$check-radio-accent-color-primary;
  background: v.$check-radio-accent-color-secondary;
}

%radio-input-accented-disabled {
  border-color: v.$check-radio-accent-disabled-color-primary;
  background: v.$check-radio-accent-disabled-color-secondary;
}

@import 'radio';
