// CSS image replacement
@mixin text-hide($ignore-warning: false) {
  border: 0;
  background-color: transparent;
  color: transparent;
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font: 0/0 a;
  text-shadow: none;

  @include deprecate('`text-hide()`', 'v4.1.0', 'v5', $ignore-warning);
}
