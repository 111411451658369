@use 'node_modules/mbs-ui-kit/styles/basic/mixins/breakpoints' as media;
@use 'node_modules/mbs-ui-kit/styles/basic/variables' as v;
@use 'node_modules/mbs-ui-kit/styles/basic/mixins' as m;
@use 'variables' as cv;
@import 'attachments';

.gapps-calendar {
  &-table {
    border-right: cv.$calendar-border;
    border-left: cv.$calendar-border;

    .fc {
      .fc-scrollgrid {
        border-spacing: 0;
        border-collapse: collapse;
        border-left: 0;

        td {
          &:first-of-type {
            border-left: 0;
          }

          &:last-of-type {
            border-right: 0;
          }
        }
      }

      .fc-header-toolbar {
        margin: 0 !important;
        padding: cv.$calendar-header-toolbar-padding-y cv.$calendar-header-toolbar-padding-x;
        background: cv.$calendar-header-bg;
        color: cv.$calendar-header-color;

        .fc-toolbar-title {
          font-size: cv.$calendar-font-size-xs;
          font-weight: v.$font-weight-bold;
          letter-spacing: normal;
          line-height: cv.$calendar-header-line-height;
        }
      }

      .fc-col-header-cell {
        font-size: cv.$calendar-font-size-sm;
        text-align: left;

        .fc-col-header-cell-cushion {
          padding: cv.$calendar-header-padding-y cv.$calendar-header-padding-x;

          &:focus {
            outline: 0;
          }

          &[data-navlink] {
            &:hover {
              color: cv.$calendar-ctrl-hover-color;
              text-decoration: none;
            }
          }
        }
      }

      .fc-scroller-harness {
        overflow: visible;
      }

      .fc-daygrid,
      .fc-timegrid {
        .fc-daygrid-day {
          &.fc-day-today {
            background-clip: padding-box;
            background-color: cv.$calendar-today-bg;
          }
        }
      }

      .fc-daygrid-event {
        cursor: pointer;
      }

      .fc-daygrid-event,
      .fc-timegrid-event {
        .fc-event-main {
          width: 100%;
          max-width: cv.$calendar-full-size;
          font-weight: v.$font-weight-normal;

          .fc-event-time {
            @include m.textOverflow();
            @include m.padding-y(0);
            flex-shrink: 0;
            font-size: cv.$calendar-font-size-xs;
            font-weight: v.$font-weight-semibold;
          }

          .fc-event-title {
            @include m.textOverflow();
            @include m.padding-y(0);
            font-size: cv.$calendar-font-size-xs;
          }
        }
      }

      .fc-daygrid-day-number {
        @extend .ctrl, .ctrl-sm;
        padding: cv.$calendar-daygrid-padding-y cv.$calendar-daygrid-padding-x cv.$calendar-daygrid-padding-y 0;
      }

      .fc-daygrid {
        .fc-daygrid-body {
          .fc-daygrid-day {
            &.fc-day-other {
              .fc-daygrid-day-top {
                opacity: 1;

                .fc-daygrid-day-number {
                  color: cv.$calendar-other-day-color;

                  &:hover {
                    color: cv.$calendar-ctrl-hover-color;
                  }
                }
              }
            }

            .fc-daygrid-day-frame {
              height: cv.$calendar-day-height;
            }

            .fc-daygrid-day-events {
              margin-bottom: 0;
            }
          }
        }

        .fc-daygrid-event {
          display: flex;
          align-items: center;
          height: cv.$calendar-event-height;
          margin: cv.$calendar-event-margin-top cv.$calendar-daygrid-margin-x cv.$calendar-event-margin cv.$calendar-daygrid-margin-x;
          padding: 0 cv.$calendar-daygrid-event-padding-x;
          overflow: hidden;
          border: 0;
          cursor: pointer;
        }

        .fc-daygrid-more-link {
          @extend .ctrl-link, .ctrl-xs;
          display: block;
          //height: cv.$calendar-event-height;
          margin-left: cv.$calendar-event-margin;
          padding: cv.$calendar-more-link-padding-y cv.$calendar-more-link-padding-x;
        }
      }

      .fc-timegrid {
        .fc-daygrid-body {
          z-index: 1;
        }

        .fc-timegrid-axis-cushion {
          padding-right: cv.$calendar-timegrid-cushion-padding-x;
        }

        .fc-timegrid-col {
          &.fc-day-today {
            background-clip: padding-box;
            background-color: cv.$calendar-today-bg;
          }

          .fc-timegrid-col-events {
            margin-right: cv.$calendar-event-margin;
          }
        }

        .fc-timegrid-event {
          top: cv.$calendar-timegrid-position-top;
          padding: cv.$calendar-timegrid-event-padding cv.$calendar-timegrid-event-padding 0 cv.$calendar-timegrid-event-padding;
          border-color: cv.$calendar-timegrid-event-border-color;
          box-shadow: none;
          cursor: pointer;
        }

        .fc-scrollgrid-section {
          height: 0;

          .fc-timegrid-divider {
            //display: none;
            padding: 0;
            border-top: cv.$calendar-border;
            border-bottom: 0;
          }
        }
      }

      .fc-popover {
        z-index: 1047;

        .fc-popover-header {
          padding: cv.$calendar-header-padding-y cv.$calendar-popover-padding-x 0;
          background: cv.$calendar-popover-bg;
          color: cv.$calendar-popover-header-color;
          font-size: cv.$calendar-font-size-sm;

          .fc-popover-close {
            @extend .ctrl, .ctrl-dark, .ctrl-lg;
            opacity: 1;
          }
        }

        .fc-daygrid-event-harness:not(:last-child) {
          padding-bottom: cv.$calendar-popover-padding-y;
        }

        .fc-event-main-frame {
          padding: cv.$calendar-popover-padding-y cv.$calendar-popover-padding-x;
        }

        .fc-daygrid-block-event {
          border-radius: cv.$calendar-event-border-radius;
          margin-left: 0;
          margin-right: 0;
        }

        &.fc-more-popover {
          visibility: hidden; //hide popover before calculating its position
          top: 0 !important;
          left: 0 !important;
          box-shadow: none;

          .fc-popover-header {
            padding-left: cv.$calendar-more-popover-padding-x;
            padding-right: cv.$calendar-more-popover-padding-x;
          }

          .fc-popover-body {
            min-width: 0;
            padding: cv.$calendar-more-popover-padding-top cv.$calendar-more-popover-padding-x cv.$calendar-more-popover-padding-bottom;
            max-height: cv.$calendar-more-popover-body-max-height;
            overflow-y: auto;
          }
        }
      }

      .fc-scrollgrid-section-body {
        .fc-timegrid-axis,
        .fc-timegrid-slot {
          height: cv.$calendar-timegrid-30min-height;
          font-size: cv.$calendar-font-size-sm;

          &.fc-timegrid-slot-label {
            vertical-align: top;
          }

          .fc-timegrid-slot-label-cushion {
            padding: cv.$calendar-header-padding-y cv.$calendar-timegrid-cushion-padding-x 0 cv.$calendar-timegrid-label-padding-left;
          }
        }
      }

      .fc-timegrid-slots {
        .fc-timegrid-slot-lane,
        .fc-timegrid-slot-label {
          &.fc-timegrid-slot-minor {
            border-top: 0;
          }
        }
      }

      .fc-list {
        &.fc-list-view {
          height: cv.$calendar-full-size;
          border: cv.$calendar-border;
          border-right: 0;
          border-left: 0;
        }

        .fc-list-empty {
          background-color: cv.$calendar-list-bg;
        }
      }

      .fc-list-table {
        .fc-list-day {
          display: none;
        }

        .gapps-calendar-events {
          display: flex;

          .fc-list-event-time {
            width: cv.$calendar-list-time-width;
            border-top: 0;
            border-left: 0;
          }

          .fc-list-event-graphic {
            min-width: cv.$calendar-list-dot-min-width;
            border-top: 0;
            border-left: 0;
          }

          .fc-list-event-title {
            flex-grow: 1;
            border-top: 0;
            border-right: 0;
            border-left: 0;
          }
        }

        .fc-list-event {
          &:hover {
            .fc-list-event-time,
            .fc-list-event-graphic,
            .fc-list-event-title {
              background-color: cv.$calendar-list-hover-bg;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  &-tree-item {
    &_icon {
      display: block;
      width: cv.$calendar-tree-icon-size;
      height: cv.$calendar-tree-icon-size;
      border-radius: cv.$calendar-tree-icon-border-radius;
    }
  }

  @include media.media-breakpoint-down(md) {
    .gapps-calendar-tree {
      border-right: cv.$calendar-border;
    }
  }

  &-popover {
    &.popover {
      max-width: cv.$calendar-popover-max-width;
      border: 0;
      box-shadow: v.$dropdown-box-shadow;
      font-size: cv.$calendar-font-size-xs;
      z-index: 1048;

      @each $popover-position in cv.$calendar-popover-positions {
        &.bs-popover-#{$popover-position} {
          .arrow {
            &:before {
              border-#{$popover-position}-color: cv.$calendar-popover-arrow-border;
            }
          }
        }
      }

      .popover-body {
        padding: cv.$calendar-event-popover-padding;
      }
    }
  }

  &-loading-overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: cv.$calendar-overlay-position-left;
    width: cv.$calendar-overlay-size;
    height: cv.$calendar-overlay-size;
  }
}
