@use '../../basic/variables' as v;

.mbs-preview {
  position: relative;
  z-index: 10;
  width: 100%;

  &_bg {
    width: 100%;
  }

  &_img {
    display: block;
    position: absolute;
    z-index: 20;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      z-index: 40;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: v.$preview-img-border-width solid v.$preview-img-border-color;
    }

    &-brand {
      display: block;
      position: relative;
      z-index: 30;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
