@use '../../basic/variables' as v;

$componentSelector: mbs-file;

.#{$componentSelector} {
  position: relative;
  flex-grow: 1;
  overflow: hidden;

  &-label {
    margin-bottom: 0;
  }

  &-input {
    position: absolute;
    left: -99999px;
  }
}
