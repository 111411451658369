@use '../../basic/variables' as v;

%subtitle-description {
  margin-top: v.$subtitle-description-margin-top;
}

%subtitle {
  font-size: v.$subtitle-font-size;

  &.-disabled {
    color: v.$subtitle-disabled-color;

    @each $color, $value in v.$theme-colors {
      .text-#{$color} {
        color: mix($value, v.$white, v.$subtitle-disabled-percentage) !important;
      }
    }
  }
}
