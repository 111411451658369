@use '../../basic/variables' as v;

@import 'tile';

.mbs-tiles {
  @extend .row;
  position: relative;
  z-index: 1;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;

  &.-loading {
    min-height: v.$tiles-loading-height;
  }
}
