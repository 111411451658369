@use 'variables' as v;

.mbs-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{v.$header-height});

  &_content {
    flex-grow: 2;
    padding: v.$page-content-padding-top v.$page-content-padding-x v.$page-content-padding-bottom;
  }

  &_header {
    &-info {
      align-self: flex-start;
      margin-left: v.$page-header-info-ico-margin-left;
      color: v.$page-header-info-ico-color;
      font-size: v.$page-header-info-ico-size;
      cursor: pointer;

      &:hover {
        color: v.$page-header-info-ico-color-active;
      }
    }
  }

  &_title {
    display: flex;
    margin-bottom: v.$page-title-margin-bottom;
  }

  &_footer {
    width: 100%;
    padding: v.$page-footer-padding-y v.$page-footer-padding-x;
    border-top: v.$page-footer-border-width solid v.$page-footer-border-color;
    background: v.$page-footer-bg;
  }
}

mbs-page {
  &-footer {
    position: sticky;
    z-index: v.$zindex-fixed;
    bottom: 0;
  }
}
