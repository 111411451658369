// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix} {

  &-border {
    padding: .2em .25em .15em;
    border: solid .08em $fa-border-color;
    border-radius: .1em;
  }

  &.pull-left,
  &.#{$fa-css-prefix}-pull-left {
    float: left;
    margin-right: .3em;
  }

  &.pull-right,
  &.#{$fa-css-prefix}-pull-right {
    float: right;
    margin-left: .3em;
  }

}
