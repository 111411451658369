$overflow-types: (auto, hidden, scroll, visible, unset) !default;

@each $type in $overflow-types {
  .overflow-#{$type} {
    overflow: $type !important;
  }

  .overflow-x-#{$type} {
    overflow-x: $type !important;
  }

  .overflow-y-#{$type} {
    overflow-y: $type !important;
  }
}
