@use '../../basic/variables' as v;

.mbs-swiper {
  .swiper-pagination-bullet-active {
    background: v.$primary;
  }

  .swiper-button-next, .swiper-button-prev {
    color: v.$primary;
  }
}
