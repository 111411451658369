@use '../../basic/variables' as v;

.mbs-form-filter {
  padding-right: v.$form-filter-pdx;
  padding-left: v.$form-filter-pdx;
  border: v.$form-filter-border-width solid v.$form-filter-border-color;
  background: v.$form-filter-bg;

  .form-group {
    margin-bottom: 0;
    padding-top: v.$form-filter-pdy;
    padding-bottom: v.$form-filter-pdy;
  }

  .row {
    align-items: center;

    [class|='col'] {
      > .form-control,
      > .mbs-select {
        width: 100%;
      }
    }
  }

  &_delimiter {
    &:after {
      content: '';
      display: block;
      width: v.$form-filter-border-width;
      height: 100%;
      background: v.$form-filter-border-color;
    }
  }
}
