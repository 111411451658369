$icomoon-font-family: 'MBS_font' !default;
$icomoon-font-path: '~mbs-fonts/MBS_font/font/' !default;

$ico-warning-shield: unquote('"\\e96f"');
$ico-success-shield: unquote('"\\e970"');
$ico-wallet: unquote('"\\e96e"');
$ico-powerShell: unquote('"\\e96c"');
$ico-bash: unquote('"\\e96d"');
$ico-triangle-o: unquote('"\\e96b"');
$ico-snmp: unquote('"\\e96a"');
$ico-calendar-alt: unquote('"\\e968"');
$ico-hdd-solid: unquote('"\\e969"');
$ico-space-bar: unquote('"\\e966"');
$ico-exclamation-square: unquote('"\\e967"');
$ico-SquareT: unquote('"\\e962"');
$ico-SquareS: unquote('"\\e963"');
$ico-GoogleDrive: unquote('"\\e964"');
$ico-Envelope: unquote('"\\e960"');
$ico-external-link-square: unquote('"\\f14c"');
$ico-circle: unquote('"\\f111"');
$ico-exclamation-circle: unquote('"\\f06a"');
$ico-plus-circle: unquote('"\\f055"');
$ico-minus-circle: unquote('"\\f056"');
$ico-question-circle: unquote('"\\f059"');
$ico-info-circle: unquote('"\\f05a"');
$ico-chevron-circle-left: unquote('"\\f137"');
$ico-chevron-circle-right: unquote('"\\f138"');
$ico-chevron-circle-up: unquote('"\\f139"');
$ico-chevron-circle-down: unquote('"\\f13a"');
$ico-play-circle: unquote('"\\f144"');
$ico-pause-circle: unquote('"\\f28b"');
$ico-circleArrowUp: unquote('"\\e954"');
$ico-arrow-circle-up: unquote('"\\e954"');
$ico-circleArrowRight: unquote('"\\e955"');
$ico-arrow-circle-right: unquote('"\\e955"');
$ico-circleArrowDown: unquote('"\\e953"');
$ico-arrow-circle-down: unquote('"\\e953"');
$ico-circleArrowLeft: unquote('"\\e956"');
$ico-arrow-circle-left: unquote('"\\e956"');
$ico-stop-circle: unquote('"\\f28d"');
$ico-failed: unquote('"\\e911"');
$ico-times-circle: unquote('"\\e911"');
$ico-success: unquote('"\\e912"');
$ico-check-circle: unquote('"\\e912"');
$ico-error: unquote('"\\e915"');
$ico-error-circle: unquote('"\\e915"');
$ico-Restart: unquote('"\\e95e"');
$ico-listClock: unquote('"\\e95c"');
$ico-ListClock: unquote('"\\e95c"');
$ico-listStar: unquote('"\\e95d"');
$ico-ListStar: unquote('"\\e95d"');
$ico-creditDollar: unquote('"\\e95b"');
$ico-CreditDollar: unquote('"\\e95b"');
$ico-Target: unquote('"\\e95a"');
$ico-RD: unquote('"\\e959"');
$ico-RMM: unquote('"\\e965"');
$ico-Terminal: unquote('"\\e958"');
$ico-boxClock: unquote('"\\e957"');
$ico-BoxClock: unquote('"\\e957"');
$ico-Chat: unquote('"\\e952"');
$ico-Chart: unquote('"\\e951"');
$ico-EllipsisH: unquote('"\\e950"');
$ico-EllipsisV: unquote('"\\e95f"');
$ico-EnvelopePaperclip: unquote('"\\e94f"');
$ico-ComputerUpload: unquote('"\\e94d"');
$ico-ComputerDownload: unquote('"\\e94e"');
$ico-InfoCloud: unquote('"\\e94c"');
$ico-InfoCircle: unquote('"\\e94b"');
$ico-MinusThin: unquote('"\\e900"');
$ico-PlusThin: unquote('"\\e901"');
$ico-GridView: unquote('"\\e947"');
$ico-ListView: unquote('"\\e948"');
$ico-i: unquote('"\\e946"');
$ico-BarChart: unquote('"\\e931"');
$ico-Briefcase: unquote('"\\e932"');
$ico-Camera: unquote('"\\e933"');
$ico-Certificate: unquote('"\\e934"');
$ico-ClockO: unquote('"\\e935"');
$ico-Cloud: unquote('"\\e936"');
$ico-Download: unquote('"\\e937"');
$ico-Edit: unquote('"\\e938"');
$ico-EnvelopeSquare: unquote('"\\e939"');
$ico-Globe: unquote('"\\e93a"');
$ico-Home: unquote('"\\e93b"');
$ico-Key: unquote('"\\e93c"');
$ico-Laptop: unquote('"\\e93d"');
$ico-Magic: unquote('"\\e93e"');
$ico-Money: unquote('"\\e93f"');
$ico-PieChart: unquote('"\\e940"');
$ico-Plug: unquote('"\\e941"');
$ico-Random: unquote('"\\e942"');
$ico-rss: unquote('"\\e943"');
$ico-Tasks: unquote('"\\e944"');
$ico-Wrench: unquote('"\\e945"');
$ico-Info: unquote('"\\e92b"');
$ico-Basket: unquote('"\\e92c"');
$ico-Lock2: unquote('"\\e92d"');
$ico-Gear: unquote('"\\e92e"');
$ico-Office365: unquote('"\\e92f"');
$ico-GoogleApps: unquote('"\\e930"');
$ico-Pencil: unquote('"\\e92a"');
$ico-Calendar: unquote('"\\e926"');
$ico-Contact: unquote('"\\e927"');
$ico-Drive: unquote('"\\e928"');
$ico-MailBox: unquote('"\\e929"');
$ico-OverdueSettings: unquote('"\\e925"');
$ico-Clock: unquote('"\\e924"');
$ico-Compare: unquote('"\\e923"');
$ico-Eye: unquote('"\\e920"');
$ico-Mail: unquote('"\\e921"');
$ico-Users: unquote('"\\e922"');
$ico-User: unquote('"\\e91f"');
$ico-Lock: unquote('"\\e918"');
$ico-arrLeft: unquote('"\\e919"');
$ico-arrRight: unquote('"\\e91e"');
$ico-arrUp: unquote('"\\e91c"');
$ico-arrDown: unquote('"\\e91d"');
$ico-Minus: unquote('"\\e909"');
$ico-Plus: unquote('"\\e917"');
$ico-Excel: unquote('"\\e907"');
$ico-Refresh: unquote('"\\e908"');
$ico-FilesView: unquote('"\\e905"');
$ico-PlanView: unquote('"\\e906"');
$ico-Purge: unquote('"\\e904"');
$ico-Close: unquote('"\\e903"');
$ico-Backup: unquote('"\\e902"');
$ico-OpenHistory: unquote('"\\e90a"');
$ico-Restore: unquote('"\\e90b"');
$ico-tick: unquote('"\\e90c"');
$ico-Cloud-backup: unquote('"\\e90d"');
$ico-local-storage: unquote('"\\e90e"');
$ico-ec2linux: unquote('"\\5b"');
$ico-hyperv: unquote('"\\5e"');
$ico-windows: unquote('"\\60"');
$ico-databased: unquote('"\\5f"');
$ico-vmware: unquote('"\\5d"');
$ico-exchange: unquote('"\\5c"');
$ico-ec2mac: unquote('"\\54"');
$ico-ec2gear: unquote('"\\58"');
$ico-ec2box: unquote('"\\52"');
$ico-ec2drive: unquote('"\\59"');
$ico-ec2win: unquote('"\\55"');
$ico-ec2android: unquote('"\\56"');
$ico-ec2server: unquote('"\\5a"');
$ico-glacier: unquote('"\\64"');
$ico-azurewin: unquote('"\\67"');
$ico-ec2: unquote('"\\53"');
$ico-consistencycheck: unquote('"\\65"');
$ico-file: unquote('"\\63"');
$ico-imagebased: unquote('"\\61"');
$ico-arrowLeftSm: unquote('"\\e90f"');
$ico-arrowRightSm: unquote('"\\e910"');
$ico-arrowUpSm: unquote('"\\e91a"');
$ico-arrowDownSm: unquote('"\\e91b"');
$ico-cross: unquote('"\\e913"');
$ico-ok: unquote('"\\e914"');
$ico-arrowDown: unquote('"\\e916"');
$ico-Delete-text: unquote('"\\e949"');
$ico-Search: unquote('"\\e94a"');
$ico-SearchNext: unquote('"\\e961"');
