@use '../../basic/variables' as v;
@use '../../basic/mixins/border-radius' as br;

mbs-input,
.mbs-input {
  .input-group {
    display: flex;
    flex-wrap: nowrap;

    &_control {
      display: flex;
      position: relative;
      flex-basis: 0;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;

      .form-control {
        position: relative;
        border-radius: 0;

        &:focus,
        &.ng-touched.ng-dirty {
          z-index: 10;
        }
      }

      &:first-child {
        .form-control {
          &:first-of-type {
            @include br.border-left-radius(v.$input-border-radius);

            @each $size, $height, $padding-y, $padding-x, $font-size, $line-height, $border-radius in v.$input-sizes {
              &-#{$size} {
                @include br.border-left-radius(v.$border-radius);
              }
            }
          }
        }
      }

      &:last-child {
        .form-control {
          &:last-of-type {
            @include br.border-right-radius(v.$input-border-radius);

            @each $size, $height, $padding-y, $padding-x, $font-size, $line-height, $border-radius in v.$input-sizes {
              &-#{$size} {
                @include br.border-right-radius($border-radius);
              }
            }
          }
        }
      }

      &:not(:first-child) {
        margin-left: -1 * v.$input-border-width;

        .form-control {
          &:first-child {
            @include br.border-left-radius(0);
          }
        }
      }

      &:not(:last-child) {
        .form-control {
          &:last-child {
            @include br.border-right-radius(0);
          }
        }
      }
    }

    &-prepend,
    &-append {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
  }
}
