@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;

$componentSelector: mbs-table-grid_accordion;

.mbs-table-grid_cell {
  .#{$componentSelector} {
    &-title {
      @include m.textOverflow();
      font-size: v.$table-grid-accordion-title-font-size;
      font-weight: v.$table-grid-accordion-title-font-weight;

      &_description {
        font-size: v.$table-grid-accordion-title-description-font-size;
        line-height: v.$table-grid-accordion-title-description-line-height;
      }
    }
  }
}

.#{$componentSelector} {
  &_controls {
    @include m.padding-x(v.$table-cell-padding-x);
    @include m.padding-y(v.$list-select-controls-padding-y);
    border: v.$list-select-controls-border-width solid v.$list-select-controls-border-color;
    background: v.$list-select-controls-bg;
    border-bottom: none;

    &-checkbox {
      .mbs-checkbox {
        &_input {
          + .mbs-checkbox_label {
            gap: v.$list-select-controls-checkbox-label-gap;
            font-size: v.$list-select-controls-checkbox-label-font-size;
            line-height: v.$check-radio-min-height;
          }
        }
      }
    }
  }
}
