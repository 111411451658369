@use '../../basic/variables' as v;

.mbs-table_switcher {
  display: flex;
  height: 100%;
  margin-right: -1 * v.$table-switcher-pdx;
  margin-left: -1 * v.$table-switcher-pdx;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: v.$table-switcher-pdx;
    padding-left: v.$table-switcher-pdx;
  }

  &-ctrl {
    color: v.$table-switcher-ctrl-color;
    font-size: v.$table-switcher-ctrl-size;
    line-height: 0;

    &.active,
    &:active,
    &:hover {
      color: v.$table-switcher-ctrl-active-color;
    }
  }
}
