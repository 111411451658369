@use '../../basic/variables' as v;

@mixin control-variant(
  $color,
  $hover-color: darken($color, v.$btn-bg-darken-hover),
  $focus-color: darken($color, v.$btn-bg-darken-focus),
  $active-color: darken($color, v.$btn-bg-darken-active),
  $disabled-color: mix($color, v.$white, v.$btn-disabled-percentage)
) {
  color: $color;

  @include hover-focus() {
    box-shadow: none;
  }

  &:hover,
  &.hover {
    color: $hover-color;
  }

  &:focus,
  &.focus {
    color: $focus-color;
  }

  &.disabled,
  &:disabled {
    color: $disabled-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $active-color;
  }

  &.-loading {
    color: transparent;
    &:after {
      border-color: transparentize($color, 0.4);
      border-left-color: $color;
    }
  }
}

@each $color, $value in v.$theme-colors {
  .ctrl-#{$color} {
    @include control-variant($value);
  }
}
