@use '../../basic/variables' as v;

@import 'table-nav';
@import 'table-filter';
@import 'table-switcher';
@import 'table-pagination';

// Table types
@import 'simple';

.table {
  th,
  td {
    line-height: v.$table-line-height;
  }

  th {
    font-weight: v.$table-head-font-weight;
  }

  .thead-light {
    tr {
      td,
      th {
        background: v.$table-head-bg;
        color: v.$table-head-color;
      }
    }
  }

  &.border-bottom {
    border-bottom: v.$table-border-width solid v.$table-border-color;
  }
}
