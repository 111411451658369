@use '../../basic/variables' as v;
@use '../../basic/mixins' as m;
@use '../../basic/mixins/border-radius' as br;

.#{$componentSelector} {
  &-checkboxes {
    &_content {
      padding: 0;
    }

    &_row {
      display: flex;
      position: relative;
      z-index: 10;
      align-items: center;
      @include m.padding-y(v.$accordion-checkboxes-row-padding-y);
      padding-right: v.$accordion-checkboxes-row-padding-right;
      padding-left: v.$accordion-checkboxes-row-padding-left;
      border: v.$accordion-border-width solid v.$accordion-border-color;

      &-label {
        padding-left: v.$accordion-checkboxes-row-label-padding-left;
      }

      & + & {
        margin-top: -1 * v.$accordion-border-width;
      }

      &.-selected {
        z-index: 20;
        border-color: v.$accordion-checkboxes-row-selected-border-color;
        background: v.$accordion-checkboxes-row-selected-bg-color;
      }

      &:last-of-type {
        @include br.border-bottom-radius(v.$accordion-border-radius);
      }
    }

    .#{$componentSelector} {
      &_header {
        border-right: v.$accordion-border-width solid v.$accordion-border-color;
        border-left: v.$accordion-border-width solid v.$accordion-border-color;
      }
    }

    .card {
      margin-top: -1 * v.$accordion-border-width;
      border-width: 0;
      border-top-width: v.$accordion-border-width;

      &-header {
        border-bottom: 0;
      }

      &:last-of-type {
        border-bottom-width: v.$accordion-border-width;
      }

      &:not(:last-of-type) {
        .#{$componentSelector}-checkboxes_row {
          &:last-of-type {
            @include br.border-bottom-radius(0);
          }
        }
      }
    }
  }

  &-sm {
    .#{$componentSelector} {
      &-checkboxes {
        &_row {
          padding-left: v.$accordion-checkboxes-row-padding-left-sm;
        }
      }
    }
  }
}
